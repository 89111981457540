var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "sidebar" },
    [
      _c("div", { staticClass: "sidebar__toggled" }, [
        _c(
          "button",
          {
            staticClass: "btn-icon btn-round",
            attrs: {
              title: _vm.sidebarOpen
                ? "Mostrar barra lateral"
                : "Ocultar barra lateral",
            },
            on: {
              click: function ($event) {
                return _vm.toggleSidebar()
              },
            },
          },
          [
            !_vm.sidebarOpen
              ? _c(
                  "span",
                  [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
                  1
                )
              : _c(
                  "span",
                  [_c("b-icon", { attrs: { icon: "x", scale: "1.5" } })],
                  1
                ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        {
          staticClass: "logo",
          attrs: { id: "popover-brand", "b-link": "", to: "/" },
        },
        [
          _c("img", {
            staticClass: "logo-img",
            attrs: {
              src: "content/images/isotipo_conacyt.svg",
              alt: "Conahcyt",
            },
          }),
          _vm._v(" "),
          _c("b-popover", {
            attrs: {
              disabled: _vm.popOverDisabled,
              target: "popover-brand",
              triggers: "hover",
              placement: "right",
              boundaryPadding: "50",
              boundary: "window",
            },
            on: {
              "update:disabled": function ($event) {
                _vm.popOverDisabled = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _c("span", { staticClass: "navbar-title" }, [
                      _vm._v("Conahcyt"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "navbar-version" }, [
                      _vm._v(_vm._s(_vm.version)),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "navbar-title",
              class: _vm.sidebarOpen ? "hide-toggle" : "",
            },
            [_vm._v("Conahcyt")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "navbar-version",
              class: _vm.sidebarOpen ? "hide-toggle" : "",
            },
            [_vm._v(_vm._s(_vm.version))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.path !== "/" && _vm.account
        ? _c("div", [
            _c("div", { staticClass: "sidebar_grouper" }, [
              _c("span", [_vm._v("Persona usuaria")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "user-info" },
              [
                _c(
                  "b-dropdown",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.sidebarOpen,
                        expression: "!sidebarOpen",
                      },
                    ],
                    staticClass: "custom-dropdown",
                    attrs: {
                      variant: "link",
                      "toggle-class": "text-decoration-none",
                      boundary: "window",
                      "no-caret": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("b-avatar", {
                                attrs: {
                                  variant: "dark",
                                  text: _vm.account.firstName.charAt(0),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v(
                                  _vm._s(_vm.account.firstName) +
                                    " " +
                                    _vm._s(_vm.account.lastName)
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      554993757
                    ),
                  },
                  [_vm._v(" "), _c("data-user-sidebar")],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(
                    _vm._s(_vm.account.firstName) +
                      " " +
                      _vm._s(_vm.account.lastName)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "user-title-avatar",
                    class: _vm.sidebarOpen ? "hide-toggle" : "",
                  },
                  [
                    _vm._v("\n        " + _vm._s(_vm.account.firstName) + " "),
                    _c("small", { staticClass: "d-block" }, [
                      _vm._v(_vm._s(_vm.account.lastName)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("b-avatar", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sidebarOpen,
                      expression: "sidebarOpen",
                    },
                  ],
                  attrs: {
                    id: "idAvatar",
                    variant: "dark",
                    size: "sm",
                    text: _vm.account.firstName.charAt(0),
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      disabled: _vm.popOverDisabled,
                      target: "idAvatar",
                      triggers: "hover",
                      placement: "right",
                      boundaryPadding: "50",
                      boundary: "window",
                    },
                    on: {
                      "update:disabled": function ($event) {
                        _vm.popOverDisabled = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.account.firstName) +
                                    " " +
                                    _vm._s(_vm.account.lastName) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3308225654
                    ),
                  },
                  [_vm._v(" "), _c("data-user-sidebar")],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar_grouper" }, [
        _c("span", [_vm._v("Principal")]),
      ]),
      _vm._v(" "),
      !_vm.authenticated
        ? _c(
            "b-nav-item",
            { attrs: { id: "popover-home", to: "/", exact: "" } },
            [
              _c("b-icon", { attrs: { icon: "house" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "item-text",
                  class: _vm.sidebarOpen ? "hide-toggle" : "",
                  domProps: { textContent: _vm._s(_vm.$t("global.menu.home")) },
                },
                [_vm._v("Home")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-popover", {
        attrs: {
          disabled: _vm.popOverDisabled,
          target: "popover-home",
          triggers: "hover",
          placement: "right",
          boundaryPadding: "50",
          boundary: "window",
        },
        on: {
          "update:disabled": function ($event) {
            _vm.popOverDisabled = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(_vm.$t("global.menu.home")),
                    },
                  },
                  [_vm._v("Home")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar_grouper" }, [
        _c("span", [_vm._v("Admin")]),
      ]),
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseProyecto",
                      arg: "collapseProyecto",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "idProyecto",
                    to: "/proyecto",
                    "active-class": "active",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "collection" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      class: _vm.sidebarOpen ? "hide-toggle" : "",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.entities.proyectosMsProyecto")
                        ),
                      },
                    },
                    [_vm._v("\n        Institucion\n      ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-popover", {
                attrs: {
                  disabled: _vm.popOverDisabled,
                  target: "idProyecto",
                  triggers: "hover",
                  placement: "right",
                  boundaryPadding: "50",
                  boundary: "window",
                },
                on: {
                  "update:disabled": function ($event) {
                    _vm.popOverDisabled = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.entities.proyectosMsProyecto"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Proyectos")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3108511446
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  attrs: {
                    to: "/asociaciones/autorizar",
                    "active-class": "active",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "bookmark-check" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      class: _vm.sidebarOpen ? "hide-toggle" : "",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "global.menu.entities.proyectosMsAutorizarAsociacion"
                          )
                        ),
                      },
                    },
                    [_vm._v("\n        Autorizar asociaciones\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseAdmin",
                      arg: "collapseAdmin",
                    },
                  ],
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/admin") },
                  attrs: {
                    id: "admin-menu",
                    "active-class": "active",
                    "data-cy": "adminMenu",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "nut" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      class: _vm.sidebarOpen ? "hide-toggle" : "",
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.menu.admin.main")),
                      },
                    },
                    [_vm._v("Administration")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-auto when-items",
                      class: _vm.sidebarOpen ? "hide-toggle" : "",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "dash", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "plus", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  class: _vm.sidebarOpen ? "hide-toggle" : "",
                  attrs: { id: "collapseAdmin" },
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass: "list-unstyled subitems",
                      class: _vm.sidebarOpen ? "hide-toggle" : "",
                    },
                    [_c("admin-menu")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-popover",
                {
                  attrs: {
                    disabled: _vm.popOverDisabled,
                    target: "admin-menu",
                    triggers: "hover",
                    placement: "right",
                    boundaryPadding: "50",
                    boundary: "window",
                  },
                  on: {
                    "update:disabled": function ($event) {
                      _vm.popOverDisabled = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.admin.main")
                                  ),
                                },
                              },
                              [_vm._v("Administration")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1719467062
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems subitemsPopover" },
                    [_c("admin-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
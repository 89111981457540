export interface IMinistracion {
  fechaEnvioPartidaPresp?: Date | null;
  estadoCuenta?: string | null;
  numCfdi?: string | null;
  fechaEmisionFactura?: Date | null;
  fechaEnvioOficios?: Date | null;
  fechaMinistracion?: Date | null;
  montoMinistrado?: number | null;
  fechaNotificacion?: Date | null;
}

export class Ministracion implements IMinistracion {
  constructor(
    public fechaEnvioPartidaPresp?: Date | null,
    public estadoCuenta?: string | null,
    public numCfdi?: string | null,
    public fechaEmisionFactura?: Date | null,
    public fechaEnvioOficios?: Date | null,
    public fechaMinistracion?: Date | null,
    public montoMinistrado?: number | null,
    public fechaNotificacion?: Date | null
  ) {}
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.account
      ? _c("div", { staticClass: "container-fluid" }, [
          _c("h1", { staticClass: "pb-5" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.account.firstName) +
                " " +
                _vm._s(_vm.account.lastName) +
                "\n      "
            ),
            _c("small", [_vm._v("Panel de seguimiento")]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.path === "/" && !_vm.account
      ? _c("div", { staticClass: "container-fluid" }, [
          _c("h1", [
            _vm._v("Plataforma operativa del CONAHCYT "),
            _c(
              "small",
              { domProps: { textContent: _vm._s(_vm.$t("global.pleaselog")) } },
              [_vm._v("Please log in to continue.")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "jumbotron count" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col-12 count__box" }, [
                  _c(
                    "h2",
                    {
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.welcome")),
                      },
                    },
                    [_vm._v("Welcome back! to CONAHCYT")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }































































































































































































































































































































import { Prop, Vue } from 'vue-property-decorator';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import Component from 'vue-class-component';

@Component
export default class SeleccionPropuestasRevisionComponent extends Vue {
  @Prop({ required: true })
  public value!: Proyecto;

  get proyecto(): Proyecto {
    return this.value;
  }

  set proyecto(proyecto: Proyecto) {
    this.$emit('input', proyecto);
  }
}

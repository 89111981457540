var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.list, function (contexto, index) {
      return _c(
        "b-badge",
        {
          key: index,
          staticClass: "m-1 text-wrap",
          attrs: { pill: "", variant: "primary" },
        },
        [_vm._v(_vm._s(contexto))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
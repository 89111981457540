import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { Ministracion } from '@/shared/model/proyectosMs/ministracion.model';
import { mapGetters } from 'vuex';
import AccountService from '@/account/account.service';
import dayjs from 'dayjs';
import { DATE_TIME_LONG_FORMAT } from '@/shared/date/filters';

@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class MinistracionesComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  @Prop({ required: true })
  public value!: Ministracion[];

  public showDeleteDialog = false;

  //for edit
  public editMode = false;
  public createMode = false;
  public currentIndex = -1;
  public fields = [
    'fechaEnvioPartidaPresp',
    'estadoCuenta',
    'numCfdi',
    'fechaEmisionFactura',
    'fechaEnvioOficios',
    'fechaMinistracion',
    'montoMinistrado',
    'fechaNotificacion',
    'acciones',
  ];
  public ministracion = new Ministracion();

  set items(items: Ministracion[]) {
    this.$emit('input', items);
  }

  get items(): Ministracion[] {
    return this.value;
  }

  public clean() {
    this.ministracion = new Ministracion();
    this.editMode = false;
    this.createMode = false;
  }

  public cancel() {
    this.clean();
  }

  public add(): void {
    this.items.push(this.ministracion);
    this.clean();
  }

  public update(): void {
    this.items.splice(this.currentIndex, 1, this.ministracion);
    this.clean();
  }

  public prepareEdit(index): void {
    this.editMode = true;
    this.ministracion = { ...this.items[index] };
    this.currentIndex = index;
  }

  public prepareAdd(): void {
    this.editMode = false;
    this.createMode = true;
    this.ministracion = new Ministracion();
    this.currentIndex = -1;
  }

  public deleteItem(index): void {
    this.items.splice(index, 1);
  }
}

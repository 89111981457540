var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-reniecyt-solicitud-id",
                label: "No. de Reniecyt",
                "label-for": "input-reniecyt-solicitud-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-reniecyt-solicitud-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.reniecytSolicitud != null &&
                    _vm.proyecto.reniecytSolicitud.length > 0,
                  placeholder: "Reniecyt registrado en la solicitud",
                },
                model: {
                  value: _vm.proyecto.reniecytSolicitud,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "reniecytSolicitud", $$v)
                  },
                  expression: "proyecto.reniecytSolicitud",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-cvu-solicitante-id",
                label: "CVU del solicitante",
                "label-for": "input-cvu-solicitante-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title:
                                  "CVU del Responsable técnico quién llena la solicitud",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-cvu-solicitante-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.cvuSolicitante != null &&
                        _vm.proyecto.cvuSolicitante.length > 0,
                      placeholder: "CVU del solicitante",
                    },
                    model: {
                      value: _vm.proyecto.cvuSolicitante,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "cvuSolicitante", $$v)
                      },
                      expression: "proyecto.cvuSolicitante",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-solicitante-id",
                label: "Nombre de solicitante",
                "label-for": "input-nombre-solicitante-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title:
                                  "Nombre del Responsable técnico quién llena la solicitud",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-nombre-solicitante-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.nombreSolicitante != null &&
                        _vm.proyecto.nombreSolicitante.length > 0,
                      placeholder: "Nombre de solicitante",
                    },
                    model: {
                      value: _vm.proyecto.nombreSolicitante,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "nombreSolicitante", $$v)
                      },
                      expression: "proyecto.nombreSolicitante",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-carta-autorizacion-id",
                label: "Fecha de carta de autorización o postulación",
                "label-for": "input-fecha-carta-autorizacion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    title:
                                      "Relacionado con requisitos de la convocatoria, en caso de que aplique",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-carta-autorizacion-id",
                          placeholder:
                            "Fecha de carta de autorización o postulación",
                          state: _vm.proyecto.fechaCartaAutorizacion != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaCartaAutorizacion,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proyecto,
                              "fechaCartaAutorizacion",
                              $$v
                            )
                          },
                          expression: "proyecto.fechaCartaAutorizacion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-entrega-solicitud-id",
                label: "Fecha de entrega de solicitud",
                "label-for": "input-fecha-entrega-solicitud-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-entrega-solicitud-id",
                      placeholder: "Fecha de entrega de solicitud",
                      state: _vm.proyecto.fechaEntregaSolicitud != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaEntregaSolicitud,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaEntregaSolicitud", $$v)
                      },
                      expression: "proyecto.fechaEntregaSolicitud",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-revision-id",
                label: "Fecha en que se revisó la solicitud",
                "label-for": "input-fecha-revision-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-revision-id",
                      placeholder: "Fecha en que se revisó la solicitud",
                      state: _vm.proyecto.fechaRevision != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaRevision,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaRevision", $$v)
                      },
                      expression: "proyecto.fechaRevision",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Programa = () => import('@/entities/distribucionMs/programa/programa.vue');
// prettier-ignore
const ProgramaUpdate = () => import('@/entities/distribucionMs/programa/programa-update.vue');
// prettier-ignore
const ProgramaDetails = () => import('@/entities/distribucionMs/programa/programa-details.vue');
// prettier-ignore
const Criterio = () => import('@/entities/distribucionMs/criterio/criterio.vue');
// prettier-ignore
const CriterioUpdate = () => import('@/entities/distribucionMs/criterio/criterio-update.vue');
// prettier-ignore
const CriterioDetails = () => import('@/entities/distribucionMs/criterio/criterio-details.vue');
// prettier-ignore
const Tarea = () => import('@/entities/distribucionMs/tarea/tarea.vue');
// prettier-ignore
const Graficas = () => import('@/entities/distribucionMs/graficas/graficas.vue');
// prettier-ignore
// prettier-ignore
const Institucion = () => import('@/entities/distribucionMs/institucion/institucion.vue');
// prettier-ignore
const InstitucionUpdate = () => import('@/entities/distribucionMs/institucion/institucion-update.vue');
// prettier-ignore
const InstitucionDetails = () => import('@/entities/distribucionMs/institucion/institucion-details.vue');
// prettier-ignore
const Proyecto = () => import('@/entities/proyectosMs/proyecto/proyecto.vue');
// prettier-ignore
const ProyectoUpdate = () => import('@/entities/proyectosMs/proyecto/proyecto-update.vue');
// prettier-ignore
const ProyectoDetails = () => import('@/entities/proyectosMs/proyecto/proyecto-details.vue');
const ExpedienteView = () => import('@/entities/proyectosMs/documentos/visor.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const ProyectoNew = () => import('@/entities/proyectosMs/proyecto/proyecto-new.vue');

const AutorizacionAsociaciones = () => import('@/entities/proyectosMs/autorizacion-asociaciones/autorizacion-asociaciones.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'programa',
      name: 'Programa',
      component: Programa,
    },
    {
      path: 'programa/new',
      name: 'ProgramaCreate',
      component: ProgramaUpdate,
    },
    {
      path: 'programa/:programaId/edit',
      name: 'ProgramaEdit',
      component: ProgramaUpdate,
    },
    {
      path: 'programa/:programaId/view',
      name: 'ProgramaView',
      component: ProgramaDetails,
    },
    {
      path: 'criterio',
      name: 'Criterio',
      component: Criterio,
    },
    {
      path: 'criterio/new',
      name: 'CriterioCreate',
      component: CriterioUpdate,
    },
    {
      path: 'criterio/:criterioId/edit',
      name: 'CriterioEdit',
      component: CriterioUpdate,
    },
    {
      path: 'criterio/:criterioId/view',
      name: 'CriterioView',
      component: CriterioDetails,
    },
    {
      path: 'tarea',
      name: 'Tarea',
      component: Tarea,
    },
    {
      path: 'graficas',
      name: 'Graficas',
      component: Graficas,
    },
    {
      path: 'institucion',
      name: 'Institucion',
      component: Institucion,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'institucion/new',
      name: 'InstitucionCreate',
      component: InstitucionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'institucion/:institucionId/edit',
      name: 'InstitucionEdit',
      component: InstitucionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'institucion/:institucionId/view',
      name: 'InstitucionView',
      component: InstitucionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'proyecto',
      name: 'Proyecto',
      component: Proyecto,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'proyecto/new',
      name: 'ProyectoCreate',
      component: ProyectoNew,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'proyecto/:proyectoId/edit',
      name: 'ProyectoEdit',
      component: ProyectoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'proyecto/:proyectoId/view',
      name: 'ProyectoView',
      component: ProyectoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'proyecto/:cveProyecto/:expediente',
      name: 'ExpedienteView',
      component: ExpedienteView,
    },
    {
      path: 'asociaciones/autorizar',
      name: 'AutorizacionAsociaciones',
      component: AutorizacionAsociaciones,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};

import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { Evento, IEvento } from '@/shared/model/proyectosMs/evento.model';
import { mapGetters } from 'vuex';
import AccountService from '@/account/account.service';
import { Constants } from '@/shared/util/constants';
import AlertService from '@/shared/alert/alert.service';
import { CargaManualDocumento, ICargaManualDocumento } from '@/shared/model/proyectosMs/documento-carga-manual.model';
import { IEtapa } from '@/shared/model/proyectosMs/etapa.model';
import DocumentosService from '@/entities/proyectosMs/documentos/documentos.service';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import { required } from 'vuelidate/lib/validators';
const validations: any = {
  cargaManualDocumento: {
    tipoDocumento: {
      required,
    },
    etapa: {
      required,
    },
    archivo: {
      required,
    },
  },
};
@Component({
  computed: {
    ...mapGetters(['account']),
  },
  validations,
})
export default class EventoComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('documentosService') private documentosService: () => DocumentosService;
  public currentLanguage = 'es';

  @Prop({ required: true })
  public value!: Evento[];
  @Prop({ required: true })
  public proyecto!: Proyecto;

  public showDeleteDialog = false;
  public tipoEventosOptions = Constants.TIPO_EVENTOS;

  public cargaManualDocumento: ICargaManualDocumento = new CargaManualDocumento();
  public etapas: IEtapa[] = [];
  public isSavingFile = false;

  //for edit
  public editMode = false;
  public createMode = false;
  public currentIndex = -1;
  public fields = ['numeroEvento', 'tipoEvento', 'fechaRegistro', 'detalleEvento', 'acciones'];
  public evento = new Evento();
  public seguimientoId = '6525774600c0f319f72c811a';
  public tipoDocumentoId = '65258bda00c0f319f72c8158';

  public mounted(): void {
    this.retrieveAllEtapas();
  }

  set items(items: Evento[]) {
    this.$emit('input', items);
  }

  get items(): Evento[] {
    return this.value;
  }

  public clean() {
    this.evento = new Evento();
    this.editMode = false;
    this.createMode = false;
  }

  public cancel() {
    this.clean();
  }

  public add(): void {
    this.items.push(this.evento);
    this.clean();
  }

  public update(): void {
    this.items.splice(this.currentIndex, 1, this.evento);
    this.clean();
  }

  public prepareEdit(index): void {
    this.editMode = true;
    this.evento = { ...this.items[index] };
    this.currentIndex = index;
  }

  public prepareAdd(): void {
    this.editMode = false;
    this.createMode = true;
    this.evento = new Evento();
    this.currentIndex = -1;
  }

  public deleteItem(index): void {
    this.items.splice(index, 1);
  }

  public modalLoaded(): void {
    if (<any>this.$refs.componentCargaManualDocumento) {
      (<any>this.$refs.componentCargaManualDocumento).seleccionEtapa(this.seguimientoId);
      this.cargaManualDocumento.etapa = this.seguimientoId;
      this.cargaManualDocumento.tipoDocumento = this.tipoDocumentoId;
    }
  }

  public showCargaManualDocumento(): void {
    if (<any>this.$refs.cargaManualDocumento) {
      (<any>this.$refs.cargaManualDocumento).show();
      this.cargaManualDocumento = new CargaManualDocumento();
    }
  }

  public hideCargaManualDocumento(): void {
    if (<any>this.$refs.cargaManualDocumento) {
      (<any>this.$refs.cargaManualDocumento).hide();
    }
  }

  public uploadDocumento(): void {
    this.isSavingFile = true;
    const errors = [];

    if (this.cargaManualDocumento.archivo.size <= 0) {
      errors.push(this.$t('cargaManualDocumento.errors.vacio').toString());
    }

    if (errors.length === 0) {
      this.documentosService()
        .uploadFile(this.cargaManualDocumento, this.proyecto)
        .then(param => {
          this.isSavingFile = false;
          const message = this.$t('cargaManualDocumento.exito').toString();
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Éxito!',
            variant: 'success',
            solid: true,
            noAutoHide: true,
          });

          this.cargaManualDocumento = new CargaManualDocumento();
          (<any>this.$refs.componentCargaManualDocumento).resetFile();
        })
        .catch(error => {
          this.isSavingFile = false;
          this.clearUploadDocumento();
          if (error.response) {
            if (error.response.status >= 500 && error.response.status <= 599) {
              this.alertService().showError(this, 'Error interno del servidor.');
            } else {
              const message = this.$t(error.response.data.message).toString();
              this.alertService().showError(this, message);
            }
          }
        });
    } else {
      this.isSavingFile = false;
      this.alertService().showError(this, errors.join(', '));
      this.clearUploadDocumento();
    }
  }

  public clearUploadDocumento(): void {
    (<any>this.$refs.componentCargaManualDocumento).clearFile();
  }
  get uploadingState(): string {
    if (this.isSavingFile) {
      return 'outline-primary';
    } else {
      return 'primary';
    }
  }

  public retrieveAllEtapas(): void {
    this.documentosService()
      .getAssociations()
      .then(
        res => {
          if (res && res.length > 0) {
            for (const item of res) {
              this.etapas.push(item);
            }
          }
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }
}

import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import { mapGetters } from 'vuex';
import AccountService from '@/account/account.service';
import dayjs from 'dayjs';
import { DATE_TIME_LONG_FORMAT } from '@/shared/date/filters';
import { Constants } from '@/shared/util/constants';
import { default as DatosIdentificacion } from '@/components/proyecto-edit/datos-identificacion.vue';
import { default as SeleccionPropuestasConvocatoria } from '@/components/proyecto-edit/seleccion-propuestas/convocatoria.vue';
import ProyectoService from '@/entities/proyectosMs/proyecto/proyecto.service';
import AlertService from '@/shared/alert/alert.service';
import { IProyectoFiltro } from '@/shared/model/proyectosMs/proyecto-filtro.model';
import { default as SeleccionPropuestasRecepcion } from '@/components/proyecto-edit/seleccion-propuestas/recepcion.vue';
import { default as SeleccionPropuestasRevision } from '@/components/proyecto-edit/seleccion-propuestas/revision.vue';
import { default as SeleccionPropuestasAprobacion } from '@/components/proyecto-edit/seleccion-propuestas/aprobacion.vue';
import { default as SeleccionPropuestasCancelacion } from '@/components/proyecto-edit/seleccion-propuestas/cancelacion.vue';
import { default as SeleccionPropuestasFormalizacion } from '@/components/proyecto-edit/seleccion-propuestas/formalizacion.vue';

@Component({
  components: {
    SeleccionPropuestasRevision,
    SeleccionPropuestasRecepcion,
    SeleccionPropuestasConvocatoria,
    SeleccionPropuestasAprobacion,
    SeleccionPropuestasCancelacion,
    SeleccionPropuestasFormalizacion,
    DatosIdentificacion,
  },
  computed: {
    ...mapGetters(['account']),
  },
})
export default class ProyectoEditComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('proyectoService') private proyectoService: () => ProyectoService;
  @Inject('alertService') private alertService: () => AlertService;

  public modalidadesApoyoOptions = Constants.MODALIDES_APOYO;
  public direccionesAdjuntasOptions = Constants.DIRECCIONES_ADJUNTAS;
  public areasConocimientoOptions = Constants.AREAS_CONOCIMIENTO;
  public tiposBeneficiarioOptions = Constants.TIPOS_BENEFICIARIOS;
  public entidadesFederativasOptions = Constants.ENTIDADES_FEDERATIVAS;
  public ejesOptions = Constants.EJES;
  public direccionesOptions = Constants.DIRECCIONES;
  public tipoCarOptions = Constants.TIPOCAR;

  @Prop({ required: true })
  public value!: Proyecto;
  public isSaving = false;
  public comentario = '';
  public filtro: IProyectoFiltro = this.$store.getters.currentProyectoFiltro;

  public etapasOptions = [
    { value: null, text: 'seleccione una etapa', disabled: true },
    { value: 'Selección de propuestas', text: 'Selección de propuestas' },
    { value: 'Seguimiento técnico y administrativo', text: 'Seguimiento técnico y administrativo' },
    { value: 'Conclusión', text: 'Conclusión' },
  ];

  public estatusOptions = [
    { value: null, text: 'seleccione un estatus', disabled: true },
    { value: 'Convocatoria', text: 'Convocatoria' },
    { value: 'Revisión', text: 'Revisión' },
    { value: 'Aprobación', text: 'Aprobación' },
    { value: 'Cancelación', text: 'Cancelación' },
    { value: 'Formalización', text: 'Formalización' },
    { value: 'Ministración', text: 'Ministración' },
    { value: 'Seguimiento', text: 'Seguimiento' },
    { value: 'Evaluación de resultados', text: 'Evaluación de resultados' },
    { value: 'Concluido', text: 'Concluido' },
    { value: 'Concluido con constancia', text: 'Concluido con constancia' },
    { value: 'Concluido por terminación anticipada', text: 'Concluido por terminación anticipada' },
    { value: 'Concluido por rescisión', text: 'Concluido por rescisión' },
  ];

  get proyecto(): Proyecto {
    return this.value;
  }

  set proyecto(proyecto: Proyecto) {
    this.$emit('input', proyecto);
  }

  public saveProyecto(login): void {
    this.isSaving = true;
    if (this.comentario && this.comentario.length > 0) {
      this.proyecto.comentarios.unshift({
        usuario: login,
        bitacoraMovimiento: this.comentario,
        fechaMovimientoBitacora: new Date(),
      });
    }

    if (!this.proyecto.id) {
      this.proyectoService()
        .create(this.proyecto)
        .then(param => {
          this.isSaving = false;
          const message = this.$t('proyectosMsApp.proyectosMsProyecto.updated', { param: param.id });
          this.$router.push('/proyecto');
          return this.$root.$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else {
      this.proyectoService()
        .update(this.proyecto)
        .then(param => {
          this.isSaving = false;
          const message = this.$t('proyectosMsApp.proyectosMsProyecto.updated', { param: param.id });
          this.$router.push('/proyecto');
          return this.$root.$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'info',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public convertDateTimeFromServer(date: Date): string {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format(DATE_TIME_LONG_FORMAT);
    }
    return null;
  }

  public updateInstantField(field, event) {
    if (event.target.value) {
      this.proyecto[field] = dayjs(event.target.value, DATE_TIME_LONG_FORMAT);
    } else {
      this.proyecto[field] = null;
    }
  }

  public addMinistracion(ministracion) {
    this.proyecto.ministraciones.push(ministracion);
  }
}

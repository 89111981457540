var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("b-row", [
                _c("header", { staticClass: "bx-header-title" }, [
                  _c("p", [_c("strong", [_vm._v(_vm._s(_vm.title))])]),
                  _vm._v(" "),
                  _c("small", { staticClass: "form-text text-muted" }, [
                    _vm._v(_vm._s(_vm.description)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "header-title-line" }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _vm.dataType === "string"
                ? _c("b-form-input", {
                    attrs: {
                      type: "text",
                      placeholder: "Ingresar nuevo registro",
                    },
                    model: {
                      value: _vm.newValue,
                      callback: function ($$v) {
                        _vm.newValue = $$v
                      },
                      expression: "newValue",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dataType === "number"
                ? _c("b-form-input", {
                    attrs: {
                      type: "number",
                      placeholder: "Ingresar nuevo registro",
                    },
                    model: {
                      value: _vm.newValue,
                      callback: function ($$v) {
                        _vm.newValue = $$v
                      },
                      expression: "newValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.dataType === "Date"
            ? _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _vm.tooltip
                    ? _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-input-group-text",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.leftbottom",
                                            modifiers: {
                                              hover: true,
                                              leftbottom: true,
                                            },
                                          },
                                        ],
                                        attrs: { title: _vm.titleTooltip },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: {
                                            icon: "info-circle",
                                            variant: "primary",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2979801096
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("b-form-datepicker", {
                            attrs: {
                              "value-as-date": "",
                              placeholder: _vm.placeholder,
                              state: _vm.newValue != null,
                              locale: "es",
                            },
                            model: {
                              value: _vm.newValue,
                              callback: function ($$v) {
                                _vm.newValue = $$v
                              },
                              expression: "newValue",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          placeholder: _vm.placeholder,
                          state: _vm.newValue != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.newValue,
                          callback: function ($$v) {
                            _vm.newValue = $$v
                          },
                          expression: "newValue",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.newValue == "", variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.agregar()
                    },
                  },
                },
                [_vm._v("Agregar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-list-group",
        [
          _c(
            "draggable",
            {
              model: {
                value: _vm.list,
                callback: function ($$v) {
                  _vm.list = $$v
                },
                expression: "list",
              },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "list", tag: "p" } },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: item,
                      staticClass:
                        "list-item d-flex justify-content-between align-items-center effect",
                    },
                    [
                      item && _vm.dataType === "Date"
                        ? _c("p", [_vm._v(_vm._s(_vm._f("formatDate")(item)))])
                        : _vm._e(),
                      _vm._v(" "),
                      item && _vm.dataType === "string"
                        ? _c("p", [_vm._v(_vm._s(item))])
                        : _vm._e(),
                      _vm._v(" "),
                      item && _vm.dataType === "number"
                        ? _c("p", [_vm._v(_vm._s(_vm._f("toCurrency")(item)))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-danger" },
                          on: {
                            click: function ($event) {
                              return _vm.eliminar(item, index)
                            },
                          },
                        },
                        [_vm._v("Eliminar")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import dayjs from 'dayjs';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const BECAS_DATE_FORMAT = 'DD/MM/YY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';
import { formatRelative } from 'date-fns';
import { es } from 'date-fns/locale';

export function initFilters() {
  Vue.filter('formatDateTime', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });
  Vue.filter('formatMillis', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });

  Vue.filter('timeElapsed', function (value) {
    if (value) {
      return formatRelative(new Date(value), new Date(), { locale: es });
    }
    return '';
  });

  Vue.filter('toCurrency', function (value) {
    if (typeof value !== 'number') {
      if (value) {
        value = parseFloat(value);
      } else {
        return value;
      }
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  });

  Vue.filter('formatBecasDate', value => {
    if (value) {
      return dayjs(value, 'DD/MM/YYYY').format(BECAS_DATE_FORMAT);
    }
    return '';
  });
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-dictamen-final-id",
                label: "Dictamen final",
                "label-for": "input-dictamen-final-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: { title: "Aprobado / No aprobado" },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-dictamen-final-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.dictamenFinal != null &&
                        _vm.proyecto.dictamenFinal.length > 0,
                      placeholder: "Dictamen final (aprobado/no aprobado)",
                    },
                    model: {
                      value: _vm.proyecto.dictamenFinal,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "dictamenFinal", $$v)
                      },
                      expression: "proyecto.dictamenFinal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-emision-dictamen-id",
                label: "Fecha en que se emite el dictamen",
                "label-for": "input-fecha-emision-dictamen-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaDicatamenAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-emision-dictamen-id",
                      placeholder: "Fecha en que se emite el dictamen",
                      state: _vm.proyecto.fechaEmisionDictamen != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaEmisionDictamen,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaEmisionDictamen", $$v)
                      },
                      expression: "proyecto.fechaEmisionDictamen",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-liberacion-acta-id",
                label: "Fecha de liberación del acta",
                "label-for": "input-fecha-liberacion-acta-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-liberacion-acta-id",
                      placeholder: "Fecha de liberación del acta",
                      state: _vm.proyecto.fechaLiberacionActa != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaLiberacionActa,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaLiberacionActa", $$v)
                      },
                      expression: "proyecto.fechaLiberacionActa",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-envio-documentos-id",
                label:
                  "Fecha de envío de documentos y propuesta de acuerdo con prelación",
                "label-for": "input-fecha-envio-documentos-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-envio-documentos-id",
                      placeholder:
                        "Fecha de envío de documentos y propuesta de acuerdo con prelación",
                      state: _vm.proyecto.fechaEnvioDocumentos != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaEnvioDocumentos,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaEnvioDocumentos", $$v)
                      },
                      expression: "proyecto.fechaEnvioDocumentos",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-monto-total-solicitado-id",
                label: "Monto total solicitado",
                "label-for": "input-monto-total-solicitado-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-monto-total-solicitado-id",
                  type: "number",
                  state: _vm.proyecto.montoTotalSolicitado != null,
                  placeholder: "Monto total solicitado por beneficiario",
                },
                model: {
                  value: _vm.proyecto.montoTotalSolicitado,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "montoTotalSolicitado", $$v)
                  },
                  expression: "proyecto.montoTotalSolicitado",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-monto-total-presentado-id",
                label: "Monto total presentado ante CTA",
                "label-for": "input-monto-total-presentado-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-monto-total-presentado-id",
                  type: "number",
                  state: _vm.proyecto.montoTotalPresentado != null,
                  placeholder: "Monto total presentado ante CTA",
                },
                model: {
                  value: _vm.proyecto.montoTotalPresentado,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "montoTotalPresentado", $$v)
                  },
                  expression: "proyecto.montoTotalPresentado",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-monto-total-aprueba-id",
                label: "Monto total que se aprueba para el proyecto por CTA",
                "label-for": "input-monto-total-aprueba-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-monto-total-aprueba-id",
                  type: "number",
                  state: _vm.proyecto.montoTotalAprueba != null,
                  placeholder:
                    "Monto total de recursos autorizados al proyecto por CTA",
                },
                model: {
                  value: _vm.proyecto.montoTotalAprueba,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "montoTotalAprueba", $$v)
                  },
                  expression: "proyecto.montoTotalAprueba",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-monto-apoyo-desglosado-id",
                label:
                  "Monto del apoyo desglosado por ejercicio Fiscal (Colocar cada año fiscal)",
                "label-for": "input-monto-apoyo-desglosado-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-monto-apoyo-desglosado-id",
                  type: "number",
                  state: _vm.proyecto.montoApoyoDesglosado != null,
                  placeholder:
                    "Monto del apoyo desglosado por ejercicio Fiscal (Colocar cada año fiscal)",
                },
                model: {
                  value: _vm.proyecto.montoApoyoDesglosado,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "montoApoyoDesglosado", $$v)
                  },
                  expression: "proyecto.montoApoyoDesglosado",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-ano-fiscal-reportado-id",
                label: "Año Fiscal reportado",
                "label-for": "input-ano-fiscal-reportado-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-ano-fiscal-reportado-id",
                  type: "number",
                  state: _vm.proyecto.anoFiscalReportado != null,
                  placeholder: "Año Fiscal reportado",
                },
                model: {
                  value: _vm.proyecto.anoFiscalReportado,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "anoFiscalReportado", $$v)
                  },
                  expression: "proyecto.anoFiscalReportado",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-monto-autorizado-ano-fiscal-id",
                label: "Monto autorizado del año Fiscal",
                "label-for": "input-monto-autorizado-ano-fiscal-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-monto-autorizado-ano-fiscal-id",
                  type: "number",
                  state: _vm.proyecto.montoAutorizadoAnoFiscal != null,
                  placeholder:
                    "Indique el Monto autorizado del año Fiscal que reporta",
                },
                model: {
                  value: _vm.proyecto.montoAutorizadoAnoFiscal,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "montoAutorizadoAnoFiscal", $$v)
                  },
                  expression: "proyecto.montoAutorizadoAnoFiscal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-duracion-proyecto-id",
                label: "Total años planeados proyecto",
                "label-for": "input-duracion-proyecto-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title: "Duración del proyecto en meses",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-duracion-proyecto-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.duracionProyecto != null &&
                        _vm.proyecto.duracionProyecto.length > 0,
                      placeholder: "Total de años planeados para el proyecto",
                    },
                    model: {
                      value: _vm.proyecto.duracionProyecto,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "duracionProyecto", $$v)
                      },
                      expression: "proyecto.duracionProyecto",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-tipo-proyecto-id",
                label: "Tipo de proyecto (Anual o Multianual)",
                "label-for": "input-tipo-proyecto-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-tipo-proyecto-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.tipoProyecto != null &&
                    _vm.proyecto.tipoProyecto.length > 0,
                  placeholder: "Tipo de proyecto (Anual o Multianual)",
                },
                model: {
                  value: _vm.proyecto.tipoProyecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "tipoProyecto", $$v)
                  },
                  expression: "proyecto.tipoProyecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-etapas-id",
                label: "Total etapas planeadas proyecto",
                "label-for": "input-num-etapas-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-num-etapas-id",
                  type: "number",
                  state: _vm.proyecto.numEtapas != null,
                  placeholder: "Total de etapas planeadas para el proyecto",
                },
                model: {
                  value: _vm.proyecto.numEtapas,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "numEtapas", $$v)
                  },
                  expression: "proyecto.numEtapas",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-acuerdo-aprobacion-id",
                label: "Número acuerdo aprobación",
                "label-for": "input-num-acuerdo-aprobacion-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title: _vm.$t(
                                  "distribucionGwApp.proyectosMsProyecto.adjuntarDocumentoAcuerdo"
                                ),
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-num-acuerdo-aprobacion-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.numAcuerdoAprobacion != null &&
                        _vm.proyecto.numAcuerdoAprobacion.length > 0,
                      placeholder:
                        "Número de acuerdo de aprobación del proyecto",
                    },
                    model: {
                      value: _vm.proyecto.numAcuerdoAprobacion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "numAcuerdoAprobacion", $$v)
                      },
                      expression: "proyecto.numAcuerdoAprobacion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-sesion-cta-aprobacion-id",
                label: "Número de sesión del CTA",
                "label-for": "input-num-sesion-cta-aprobacion-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-num-sesion-cta-aprobacion-id",
                  state: _vm.proyecto.numSesionCtaAprobacion != null,
                  placeholder: "Número de sesión del CTA",
                },
                model: {
                  value: _vm.proyecto.numSesionCtaAprobacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "numSesionCtaAprobacion", $$v)
                  },
                  expression: "proyecto.numSesionCtaAprobacion",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-acuerdo-aprobacion-id",
                label: "Fecha acuerdo aprobación proyecto",
                "label-for": "input-fecha-acuerdo-aprobacion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-acuerdo-aprobacion-id",
                      placeholder: "Fecha acuerdo aprobación proyecto",
                      state: _vm.proyecto.fechaAcuerdoAprobacion != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaAcuerdoAprobacion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaAcuerdoAprobacion", $$v)
                      },
                      expression: "proyecto.fechaAcuerdoAprobacion",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-publicacion-resultados-id",
                label: "Fecha publicación resultados",
                "label-for": "input-fecha-publicacion-resultados-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-publicacion-resultados-id",
                      placeholder: "Fecha publicación resultados",
                      state: _vm.proyecto.fechaPublicacionResultados != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaPublicacionResultados,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "fechaPublicacionResultados",
                          $$v
                        )
                      },
                      expression: "proyecto.fechaPublicacionResultados",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-sancion-car-id",
                label: "Fecha sanción CAR de UAJ",
                "label-for": "input-fecha-sancion-car-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-sancion-car-id",
                      placeholder: "Fecha sanción CAR de UAJ",
                      state: _vm.proyecto.fechaSancionCar != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaSancionCar,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaSancionCar", $$v)
                      },
                      expression: "proyecto.fechaSancionCar",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export interface IProyectoFiltro {
  id?: string;
  convocatoria?: string | null;
  clave?: string | null;
  fondos?: [] | null;
  sujetoApoyo?: string | null;
  dirAreaRespConvocatoria?: string[] | null;
  montoAutorizado?: number | null;
  estatus?: [] | null;
  etapas?: [] | null;
  entFederativaBeneficiario?: [] | null;
  anno?: number | null;
  fondo?: string;
}

export class ProyectoFiltro implements IProyectoFiltro {
  constructor(
    public id?: string,
    public titulo?: string | null,
    public convocatoria?: string | null,
    public clave?: string | null,
    public fondos?: [] | null,
    public sujetoApoyo?: string | null,
    public dirAreaRespConvocatoria?: [] | null,
    public estatus?: [] | null,
    public etapas?: [] | null,
    public anno?: number | null,
    public fondo?: string,
    public dirAdjuntaUnidad?: string | null,
    public areaRespConvocatoria?: string | null,
    public entFederativaBeneficiario?: [] | null,
    public ejeRector?: string | null
  ) {
    this.dirAreaRespConvocatoria = this.dirAreaRespConvocatoria ? this.dirAreaRespConvocatoria : [];
    this.estatus = this.estatus ? this.estatus : [];
    this.etapas = this.etapas ? this.etapas : [];
    this.fondos = this.fondos ? this.fondos : [];
    this.entFederativaBeneficiario = this.entFederativaBeneficiario ? this.entFederativaBeneficiario : [];
  }
}

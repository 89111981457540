var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/admin/gateway", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.admin.gateway")),
              },
            },
            [_vm._v("Gateway")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/admin/metrics", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.admin.metrics")),
              },
            },
            [_vm._v("Metrics")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/admin/health", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.admin.health")),
              },
            },
            [_vm._v("Health")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/admin/configuration", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.admin.configuration")),
              },
            },
            [_vm._v("Configuration")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/admin/logs", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.admin.logs")),
              },
            },
            [_vm._v("Logs")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.openAPIEnabled
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/admin/docs", "active-class": "active" } },
            [
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.admin.apidocs")),
                  },
                },
                [_vm._v("API")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
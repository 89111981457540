var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.editMode || _vm.createMode
            ? _c(
                "b-card",
                { attrs: { "border-variant": "danger" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "12" } }, [
                        _vm.createMode
                          ? _c("header", { staticClass: "bx-header-title" }, [
                              _c("h3", [
                                _c(
                                  "span",
                                  { attrs: { id: "proyecto-create-heading" } },
                                  [_vm._v("Registrar ministración")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "header-title-line" }),
                            ])
                          : _c("header", { staticClass: "bx-header-title" }, [
                              _c("h3", [
                                _c(
                                  "span",
                                  { attrs: { id: "proyecto-update-heading" } },
                                  [_vm._v("Actualizar ministración")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "header-title-line" }),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "component-ministracion-estado-cuenta",
                                label: "Estado de cuenta",
                                "label-for": "input-estado-cuenta-id",
                                description: _vm.$t(
                                  "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function () {
                                          return [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.leftbottom",
                                                    modifiers: {
                                                      hover: true,
                                                      leftbottom: true,
                                                    },
                                                  },
                                                ],
                                                attrs: {
                                                  title:
                                                    "Se requiere el estado de cuenta del beneficiario",
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: {
                                                    icon: "info-circle",
                                                    variant: "primary",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3632479258
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    attrs: {
                                      size: "sm",
                                      id: "input-estado-cuenta-id",
                                      state:
                                        _vm.ministracion.estadoCuenta != null,
                                      placeholder: "Estado de cuenta",
                                    },
                                    model: {
                                      value: _vm.ministracion.estadoCuenta,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "estadoCuenta",
                                          $$v
                                        )
                                      },
                                      expression: "ministracion.estadoCuenta",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fechaEnvioPartidaPresp-id",
                                label: "Fecha de envío de partida presupuestal",
                                "label-for": "input-fechaEnvioPartidaPresp-id",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fechaEnvioPartidaPresp-id",
                                      placeholder: "Ingrese fecha",
                                      state:
                                        _vm.ministracion
                                          .fechaEnvioPartidaPresp != null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.ministracion.fechaEnvioPartidaPresp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "fechaEnvioPartidaPresp",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ministracion.fechaEnvioPartidaPresp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "component-ministracion-numCfdi",
                                size: "sm",
                                label: "Numero de CFDI",
                                "label-for": "input-estado-numCfdi",
                                description: "Adjuntar documento xml",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.ministracion.numCfdi != null,
                                  size: "sm",
                                  id: "input-numCfdi-id",
                                  placeholder: "Número de CFDI",
                                },
                                model: {
                                  value: _vm.ministracion.numCfdi,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ministracion, "numCfdi", $$v)
                                  },
                                  expression: "ministracion.numCfdi",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fechaEmisionFactura-id",
                                label: "Fecha de emisión del CFDI",
                                size: "sm",
                                "label-for": "input-fechaEmisionFactura-id",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fechaEmisionFactura-id",
                                      placeholder: "Ingrese fecha",
                                      state:
                                        _vm.ministracion.fechaEmisionFactura !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.ministracion.fechaEmisionFactura,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "fechaEmisionFactura",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ministracion.fechaEmisionFactura",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fechaEnvioOficios-id",
                                label:
                                  "Fecha de envío de oficio de solicitud para ministración",
                                size: "sm",
                                "label-for": "input-fechaEnvioOficios-id",
                                description: _vm.$t(
                                  "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fechaEnvioOficios-id",
                                      placeholder: "Ingrese fecha",
                                      state:
                                        _vm.ministracion.fechaEnvioOficios !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value: _vm.ministracion.fechaEnvioOficios,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "fechaEnvioOficios",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ministracion.fechaEnvioOficios",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fechaMinistracion-id",
                                label: "Fecha de ministracion",
                                size: "sm",
                                "label-for": "input-fechaMinistracion-id",
                                description: _vm.$t(
                                  "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fechaMinistracion-id",
                                      placeholder: "Ingrese fecha",
                                      state:
                                        _vm.ministracion.fechaMinistracion !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value: _vm.ministracion.fechaMinistracion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "fechaMinistracion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ministracion.fechaMinistracion",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-montoMinistrado-id",
                                size: "sm",
                                label: "Monto ministrado",
                                "label-for": "input-montoMinistrado-id",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-montoMinistrado-id",
                                  type: "number",
                                  size: "sm",
                                  state:
                                    _vm.ministracion.montoMinistrado != null,
                                  placeholder: "Monto ministrado",
                                },
                                model: {
                                  value: _vm.ministracion.montoMinistrado,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ministracion,
                                      "montoMinistrado",
                                      $$v
                                    )
                                  },
                                  expression: "ministracion.montoMinistrado",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fechaNotificacion-id",
                                label: "Fecha de notificación",
                                "label-for": "input-fechaNotificacion-id",
                                description: _vm.$t(
                                  "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                                ),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fechaNotificacion-id",
                                      placeholder: "Ingrese fecha",
                                      state:
                                        _vm.ministracion.fechaNotificacion !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value: _vm.ministracion.fechaNotificacion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ministracion,
                                          "fechaNotificacion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ministracion.fechaNotificacion",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancel()
                                },
                              },
                            },
                            [_c("span", [_vm._v("Cancelar")])]
                          ),
                          _vm._v(" "),
                          _vm.createMode
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.add },
                                },
                                [_c("span", [_vm._v("Guardar")])]
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.update },
                                },
                                [_c("span", [_vm._v("Actualizar")])]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          !_vm.editMode && !_vm.createMode
            ? _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right ml-2 text-right",
                              attrs: { variant: "primary" },
                              on: { click: _vm.prepareAdd },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "plus" } }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("Registrar nueva ministración"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-table", {
                        staticClass: "text-center",
                        attrs: {
                          id: "table-ministraciones-id",
                          "head-variant": "",
                          "sticky-header": "",
                          striped: "",
                          emptyText: "No tienes registros",
                          items: _vm.items,
                          fields: _vm.fields,
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "head(estadoCuenta)",
                              fn: function () {
                                return [_c("div", [_vm._v("Estado de cuenta")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaEnvioPartidaPresp)",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "Fecha de envío de partida presupuestal"
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(numCfdi)",
                              fn: function () {
                                return [_c("div", [_vm._v("Número de CFDI")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaEmisionFactura)",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _vm._v("Fecha de emisión de factura"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaEnvioOficios)",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "Fecha de envío de oficio de solicitud para ministración"
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaMinistracion)",
                              fn: function () {
                                return [
                                  _c("div", [_vm._v("Fecha de ministración")]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(montoMinistrado)",
                              fn: function () {
                                return [_c("div", [_vm._v("Monto ministrado")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaNotificacion)",
                              fn: function () {
                                return [
                                  _c("div", [_vm._v("Fecha de notificación")]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(fechaEnvioPartidaPresp)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaEnvioPartidaPresp
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(numCfdi)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(_vm._s(row.item.numCfdi)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(fechaEmisionFactura)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaEmisionFactura
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(fechaEnvioOficios)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaEnvioOficios
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(fechaMinistracion)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaMinistracion
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(montoMinistrado)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              row.item.montoMinistrado
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(fechaNotificacion)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaNotificacion
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(acciones)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.prepareEdit(row.index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "pencil",
                                          "aria-hidden": "true",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(row.index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "trash-fill",
                                          "aria-hidden": "true",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          29835411
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
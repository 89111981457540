var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: {
            "content-class": "mt-3",
            "nav-wrapper-class": "sticky-top",
            fill: "",
          },
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "Dato de identificación", active: "" } },
            [
              _c("datos-identificacion", {
                attrs: {
                  direccionesOptions: _vm.direccionesOptions,
                  modalidadesApoyoOptions: _vm.modalidadesApoyoOptions,
                  direccionesAdjuntasOptions: _vm.direccionesAdjuntasOptions,
                  entidadesFederativasOptions: _vm.entidadesFederativasOptions,
                  tiposBeneficiarioOptions: _vm.tiposBeneficiarioOptions,
                  areasConocimientoOptions: _vm.areasConocimientoOptions,
                  ejesOptions: _vm.ejesOptions,
                },
                model: {
                  value: _vm.proyecto,
                  callback: function ($$v) {
                    _vm.proyecto = $$v
                  },
                  expression: "proyecto",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Selección de propuestas", lazy: "" } },
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    "content-class": "mt-3",
                    "nav-wrapper-class": "sticky-top sticky-offset",
                    fill: "",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Convocatoria", active: "" } },
                    [
                      _c("seleccion-propuestas-convocatoria", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Recepción", lazy: "" } },
                    [
                      _c("seleccion-propuestas-recepcion", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Revisión", lazy: "" } },
                    [
                      _c("seleccion-propuestas-revision", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Aprobación", lazy: "" } },
                    [
                      _c("seleccion-propuestas-aprobacion", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Cancelación", lazy: "" } },
                    [
                      _c("seleccion-propuestas-cancelacion", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Formalización", lazy: "" } },
                    [
                      _c("seleccion-propuestas-formalizacion", {
                        model: {
                          value: _vm.proyecto,
                          callback: function ($$v) {
                            _vm.proyecto = $$v
                          },
                          expression: "proyecto",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            {
              attrs: {
                title: "Seguimiento técnico y administrativo",
                lazy: "",
              },
            },
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    "content-class": "mt-3",
                    "nav-wrapper-class": "sticky-top sticky-offset",
                    fill: "",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Ministración", active: "" } },
                    [
                      _c("ministraciones", {
                        model: {
                          value: _vm.proyecto.ministraciones,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "ministraciones", $$v)
                          },
                          expression: "proyecto.ministraciones",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Seguimiento", lazy: "" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-inicio-proy-car-id",
                            label: "Fecha de inicio de proyecto según CAR",
                            "label-for": "input-fecha-inicio-proy-car-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-inicio-proy-car-id",
                                  placeholder:
                                    "Fecha de inicio de proyecto según CAR",
                                  state:
                                    _vm.proyecto.fechaInicioProyCar != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaInicioProyCar,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaInicioProyCar",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaInicioProyCar",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-termino-proy-car-id",
                            label: "Fecha de término del Proyecto según el CAR",
                            "label-for": "input-fecha-termino-proy-car-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-termino-proy-car-id",
                                  placeholder:
                                    "Fecha de término del Proyecto según el CAR",
                                  state:
                                    _vm.proyecto.fechaTerminoProyCar != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaTerminoProyCar,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaTerminoProyCar",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaTerminoProyCar",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("eventos", {
                        attrs: { proyecto: _vm.proyecto },
                        model: {
                          value: _vm.proyecto.eventos,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "eventos", $$v)
                          },
                          expression: "proyecto.eventos",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Evaluación de resultados", lazy: "" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-envio-notif-fin-st-id",
                            label:
                              "Fecha de envío de notificación de finalización ST",
                            "label-for": "input-fecha-envio-notif-fin-st-id",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-envio-notif-fin-st-id",
                                  placeholder:
                                    "Fecha de envío de notificación de finalización ST",
                                  state:
                                    _vm.proyecto.fechaEnvioNotifFinSt != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaEnvioNotifFinSt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaEnvioNotifFinSt",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaEnvioNotifFinSt",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-envio-notif-fin-sa-id",
                            label:
                              "Fecha de envío de notificación de finalización SA",
                            "label-for": "input-fecha-envio-notif-fin-sa-id",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-envio-notif-fin-sa-id",
                                  placeholder:
                                    "Fecha de envío de notificación de finalización SA",
                                  state:
                                    _vm.proyecto.fechaEnvioNotifFinSa != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaEnvioNotifFinSa,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaEnvioNotifFinSa",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaEnvioNotifFinSa",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-recep-inf-tecnico-id",
                            label:
                              "Fecha de recepción de informe técnico final",
                            "label-for": "input-fecha-recep-inf-tecnico-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-recep-inf-tecnico-id",
                                  placeholder:
                                    "Fecha de recepción de informe técnico final",
                                  state:
                                    _vm.proyecto.fechaRecepInfTecnico != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaRecepInfTecnico,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaRecepInfTecnico",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaRecepInfTecnico",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("list-edit", {
                        attrs: {
                          title:
                            "Fecha de envío para evaluación del informe técnico final",
                          dataType: "Date",
                          placeholder:
                            "Fecha de envío para evaluación del informe técnico final",
                          tooltip: "true",
                          titleTooltip: "Multiplicidad (muchos)",
                        },
                        model: {
                          value: _vm.proyecto.fechaEnvioEvalInfTecnico,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proyecto,
                              "fechaEnvioEvalInfTecnico",
                              $$v
                            )
                          },
                          expression: "proyecto.fechaEnvioEvalInfTecnico",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-notificacion-inf-tecnico-id",
                            label:
                              "Fecha en que se envió alguna notificación de ajuste al informe técnico final",
                            "label-for":
                              "input-fecha-notificacion-inf-tecnico-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-notificacion-inf-tecnico-id",
                                  placeholder:
                                    "Fecha en que se envió alguna notificación de ajuste al informe técnico final",
                                  state:
                                    _vm.proyecto.fechaNotificacionInfTecnico !=
                                    null,
                                  locale: "es",
                                },
                                model: {
                                  value:
                                    _vm.proyecto.fechaNotificacionInfTecnico,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaNotificacionInfTecnico",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.fechaNotificacionInfTecnico",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("list-edit", {
                        attrs: {
                          title:
                            "Fecha de atención de ajustes a informe técnico final",
                          dataType: "Date",
                          placeholder:
                            "Fecha de atención de ajustes a informe técnico final",
                          tooltip: "true",
                          titleTooltip: "Multiplicidad (muchos)",
                          description: _vm.$t(
                            "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                          ),
                        },
                        model: {
                          value: _vm.proyecto.fechaAtencionAjustesInfTecnico,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proyecto,
                              "fechaAtencionAjustesInfTecnico",
                              $$v
                            )
                          },
                          expression: "proyecto.fechaAtencionAjustesInfTecnico",
                        },
                      }),
                      _vm._v(" "),
                      _c("list-edit", {
                        attrs: {
                          title:
                            "Fecha de recepción del dictamen del informe técnico final",
                          dataType: "Date",
                          placeholder:
                            "Fecha de recepción del dictamen del informe técnico final",
                          tooltip: "true",
                          titleTooltip: "Multiplicidad (muchos)",
                          description: _vm.$t(
                            "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                          ),
                        },
                        model: {
                          value: _vm.proyecto.fechaRecepDictamenInfTecnico,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proyecto,
                              "fechaRecepDictamenInfTecnico",
                              $$v
                            )
                          },
                          expression: "proyecto.fechaRecepDictamenInfTecnico",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fallo-informe-tecnico-id",
                            label: "Fallo de informe técnico final",
                            "label-for": "input-fallo-informe-tecnico-id",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title:
                                              "Catálogo (Aprobado / No aprobado)",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-fallo-informe-tecnico-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.falloInformeTecnico != null &&
                                    _vm.proyecto.falloInformeTecnico.length > 0,
                                  placeholder: "Fallo de informe técnico final",
                                },
                                model: {
                                  value: _vm.proyecto.falloInformeTecnico,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "falloInformeTecnico",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.falloInformeTecnico",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-recepcion-inf-financiero-final-id",
                            label:
                              "Fecha de recepción de informe financiero final",
                            "label-for":
                              "input-fecha-recepcion-inf-financiero-final-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-recepcion-inf-financiero-final-id",
                                  placeholder:
                                    "Fecha de recepción de informe financiero final",
                                  state:
                                    _vm.proyecto
                                      .fechaRecepcionInfFinancieroFinal != null,
                                  locale: "es",
                                },
                                model: {
                                  value:
                                    _vm.proyecto
                                      .fechaRecepcionInfFinancieroFinal,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaRecepcionInfFinancieroFinal",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.fechaRecepcionInfFinancieroFinal",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-envio-notificacion-ajuste-id",
                            label:
                              "Fecha de envió notificación de ajuste al informe financiero final",
                            "label-for":
                              "input-fecha-envio-notificacion-ajuste-id",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-fecha-envio-notificacion-ajuste-id",
                              autocomplete: "off",
                              state:
                                _vm.proyecto.fechaEnvioNotificacionAjuste !=
                                  null &&
                                _vm.proyecto.fechaEnvioNotificacionAjuste
                                  .length > 0,
                              placeholder:
                                "Fecha en que se envió alguna notificación de ajuste al informe financiero final; la ausencia implicaría que no hubo comentarios y/o observaciones para atender",
                            },
                            model: {
                              value: _vm.proyecto.fechaEnvioNotificacionAjuste,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.proyecto,
                                  "fechaEnvioNotificacionAjuste",
                                  $$v
                                )
                              },
                              expression:
                                "proyecto.fechaEnvioNotificacionAjuste",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-atencion-ajustes-id",
                            label:
                              "Fecha de atención de ajustes a informe financiero:",
                            "label-for": "input-fecha-atencion-ajustes-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-fecha-atencion-ajustes-id",
                              autocomplete: "off",
                              state:
                                _vm.proyecto.fechaAtencionAjustes != null &&
                                _vm.proyecto.fechaAtencionAjustes.length > 0,
                              placeholder:
                                "Fecha de atención de ajustes a informe financiero: la ausencia implicaría que no hubo comentarios y/o observaciones para atender",
                            },
                            model: {
                              value: _vm.proyecto.fechaAtencionAjustes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.proyecto,
                                  "fechaAtencionAjustes",
                                  $$v
                                )
                              },
                              expression: "proyecto.fechaAtencionAjustes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-monto-reintegro-id",
                            label: "Monto del reintegro",
                            "label-for": "input-monto-reintegro-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title:
                                              "Cantidad que se está devolviendo",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-monto-reintegro-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.montoReintegro != null &&
                                    _vm.proyecto.montoReintegro.length > 0,
                                  placeholder:
                                    "Monto del reintegro (si aplica)",
                                },
                                model: {
                                  value: _vm.proyecto.montoReintegro,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "montoReintegro",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.montoReintegro",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-solicitud-reintegro-id",
                            label: "Fecha de solicitud de reintegro",
                            "label-for": "input-fecha-solicitud-reintegro-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-solicitud-reintegro-id",
                                  placeholder:
                                    "Fecha de solicitud de reintegro",
                                  state:
                                    _vm.proyecto.fechaSolicitudReintegro !=
                                    null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaSolicitudReintegro,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaSolicitudReintegro",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.fechaSolicitudReintegro",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-reintegro-id",
                            label: "Fecha de reintegro",
                            "label-for": "input-fecha-reintegro-id",
                            description: "Adjuntar documento SPEI",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-reintegro-id",
                                  placeholder: "Fecha de reintegro",
                                  state: _vm.proyecto.fechaReintegro != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaReintegro,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaReintegro",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaReintegro",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-recepcion-dictamen-id",
                            label:
                              "Fecha de recepción del dictamen del informe financiero final",
                            "label-for": "input-fecha-recepcion-dictamen-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-datepicker", {
                                attrs: {
                                  "value-as-date": "",
                                  id: "input-fecha-recepcion-dictamen-id",
                                  placeholder:
                                    "Fecha de recepción del dictamen del informe financiero final",
                                  state:
                                    _vm.proyecto.fechaRecepcionDictamen != null,
                                  locale: "es",
                                },
                                model: {
                                  value: _vm.proyecto.fechaRecepcionDictamen,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "fechaRecepcionDictamen",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.fechaRecepcionDictamen",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fallo-informe-financiero-id",
                            label: "Fallo de informe financiero final",
                            "label-for": "input-fallo-informe-financiero-id",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title:
                                              "Catálogo (Aprobado / No aprobado)",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-fallo-informe-financiero-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.falloInformeFinanciero !=
                                      null &&
                                    _vm.proyecto.falloInformeFinanciero.length >
                                      0,
                                  placeholder:
                                    "Fallo de informe financiero final",
                                },
                                model: {
                                  value: _vm.proyecto.falloInformeFinanciero,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "falloInformeFinanciero",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.falloInformeFinanciero",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Conclusión", lazy: "" } },
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Concluido", active: "" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-tipo-conclusion-id",
                            label: "Tipo Conclusión",
                            "label-for": "input-tipo-conclusion-id",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title:
                                              "Catálogo (Constancia de conclusión técnica y financiera, terminación anticipada, rescisión)",
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-tipo-conclusion-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.tipoConclusion != null &&
                                    _vm.proyecto.tipoConclusion.length > 0,
                                  placeholder:
                                    "Indica si es un acta por terminación anticipada, por rescisión o por conclusión técnica y financiera",
                                },
                                model: {
                                  value: _vm.proyecto.tipoConclusion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "tipoConclusion",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.tipoConclusion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Concluido con constancia", lazy: "" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-dictamen-aprobatorio-inf-financiero-id",
                            label:
                              "Fecha de dictamen aprobatorio de informe financiero",
                            "label-for":
                              "input-fecha-dictamen-aprobatorio-inf-financiero-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-dictamen-aprobatorio-inf-financiero-id",
                                      placeholder:
                                        "Fecha de dictamen aprobatorio de informe financiero",
                                      state:
                                        _vm.proyecto
                                          .fechaDictamenAprobatorioInfFinanciero !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaDictamenAprobatorioInfFinanciero,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaDictamenAprobatorioInfFinanciero",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaDictamenAprobatorioInfFinanciero",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-dictamen-aprobatorio-inf-tecnico-id",
                            label:
                              "Fecha de dictamen aprobatorio de informe técnico",
                            "label-for":
                              "input-fecha-dictamen-aprobatorio-inf-tecnico-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-dictamen-aprobatorio-inf-tecnico-id",
                                      placeholder:
                                        "Fecha de dictamen aprobatorio de informe técnico",
                                      state:
                                        _vm.proyecto
                                          .fechaDictamenAprobatorioInfTecnico !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaDictamenAprobatorioInfTecnico,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaDictamenAprobatorioInfTecnico",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaDictamenAprobatorioInfTecnico",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-constancia-conclusion-id",
                            label:
                              "Fecha de la emisión de la Constancia de conclusión técnica y financiera",
                            "label-for": "input-fecha-constancia-conclusion-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-constancia-conclusion-id",
                                      placeholder:
                                        "Fecha de la emisión de la Constancia de conclusión técnica y financiera",
                                      state:
                                        _vm.proyecto
                                          .fechaConstanciaConclusion != null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto.fechaConstanciaConclusion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaConstanciaConclusion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaConstanciaConclusion",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-envio-acuse-id",
                            label:
                              "Fecha de recepción del acuse del envío de la constancia al beneficiario",
                            "label-for": "input-fecha-envio-acuse-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-envio-acuse-id",
                                      placeholder:
                                        "Fecha de recepción del acuse del envío de la constancia al beneficiario",
                                      state:
                                        _vm.proyecto.fechaEnvioAcuse != null,
                                      locale: "es",
                                    },
                                    model: {
                                      value: _vm.proyecto.fechaEnvioAcuse,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaEnvioAcuse",
                                          $$v
                                        )
                                      },
                                      expression: "proyecto.fechaEnvioAcuse",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-sesion-cta-conclusion-id",
                            label: "Número de la sesión de CTA",
                            "label-for": "input-num-sesion-cta-conclusion-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-sesion-cta-conclusion-id",
                                  state:
                                    _vm.proyecto.numSesionCtaConclusion != null,
                                  placeholder: "Número de la sesión de CTA",
                                },
                                model: {
                                  value: _vm.proyecto.numSesionCtaConclusion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numSesionCtaConclusion",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.numSesionCtaConclusion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-conocimiento-emision-constancia-id",
                            label:
                              "Fecha de toma de conocimiento del CTA sobre la emisión de la constancia",
                            "label-for":
                              "input-fecha-conocimiento-emision-constancia-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-conocimiento-emision-constancia-id",
                                      placeholder:
                                        "Fecha de toma de conocimiento del CTA sobre la emisión de la constancia",
                                      state:
                                        _vm.proyecto
                                          .fechaConocimientoEmisionConstancia !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaConocimientoEmisionConstancia,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaConocimientoEmisionConstancia",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaConocimientoEmisionConstancia",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-acuerdo-cta-conclusion-id",
                            label: "Número de acuerdo de CTA",
                            "label-for": "input-num-acuerdo-cta-conclusion-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaConclusionTecnicaFinanciera"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-acuerdo-cta-conclusion-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.numAcuerdoCtaConclusion !=
                                      null &&
                                    _vm.proyecto.numAcuerdoCtaConclusion
                                      .length > 0,
                                  placeholder: "Número de acuerdo de CTA",
                                },
                                model: {
                                  value: _vm.proyecto.numAcuerdoCtaConclusion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numAcuerdoCtaConclusion",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.numAcuerdoCtaConclusion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Concluido por terminación anticipada",
                        lazy: "",
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-indicar-es-terminacion-ant-id",
                            label:
                              "Indicar si es una conclusión por terminación anticipada",
                            "label-for": "input-indicar-es-terminacion-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-indicar-es-terminacion-ant-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.indicarEsTerminacionAnt !=
                                      null &&
                                    _vm.proyecto.indicarEsTerminacionAnt
                                      .length > 0,
                                  placeholder:
                                    "Indicar si es una conclusión por terminación anticipada",
                                },
                                model: {
                                  value: _vm.proyecto.indicarEsTerminacionAnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "indicarEsTerminacionAnt",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.indicarEsTerminacionAnt",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-recepcion-inf-tecnico-ant-id",
                            label: "Fecha de recepción de informe técnico",
                            "label-for":
                              "input-fecha-recepcion-inf-tecnico-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-recepcion-inf-tecnico-ant-id",
                                      placeholder:
                                        "Fecha de recepción de informe técnico",
                                      state:
                                        _vm.proyecto
                                          .fechaRecepcionInfTecnicoAnt != null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaRecepcionInfTecnicoAnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaRecepcionInfTecnicoAnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaRecepcionInfTecnicoAnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-acuerdo-cta-terminacion-ant-id",
                            label:
                              "Fecha de acuerdo de CTA de la terminación anticipada del apoyo",
                            "label-for":
                              "input-fecha-acuerdo-cta-terminacion-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-acuerdo-cta-terminacion-ant-id",
                                      placeholder:
                                        "Fecha de acuerdo de CTA de la terminación anticipada del apoyo",
                                      state:
                                        _vm.proyecto
                                          .fechaAcuerdoCtaTerminacionAnt !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaAcuerdoCtaTerminacionAnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaAcuerdoCtaTerminacionAnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaAcuerdoCtaTerminacionAnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-opinion-juridica-terminacion-ant-id",
                            label:
                              "Fecha de opinión juridica favorable para la terminación anticipada del proyecto",
                            "label-for":
                              "input-fecha-opinion-juridica-terminacion-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-opinion-juridica-terminacion-ant-id",
                                      placeholder:
                                        "Fecha de opinión juridica favorable para la terminación anticipada del proyecto",
                                      state:
                                        _vm.proyecto
                                          .fechaOpinionJuridicaTerminacionAnt !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaOpinionJuridicaTerminacionAnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaOpinionJuridicaTerminacionAnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaOpinionJuridicaTerminacionAnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-sesion-cta-terminacion-id",
                            label: "Número de sesión del CTA",
                            "label-for": "input-num-sesion-cta-terminacion-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-sesion-cta-terminacion-id",
                                  state:
                                    _vm.proyecto.numSesionCtaTerminacion !=
                                    null,
                                  placeholder: "Número de sesión del CTA",
                                },
                                model: {
                                  value: _vm.proyecto.numSesionCtaTerminacion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numSesionCtaTerminacion",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.numSesionCtaTerminacion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-notific-benef-terminacion-ant-id",
                            label:
                              "Fecha de notificación a Beneficiario de acuerdo de terminación anticipada",
                            "label-for":
                              "input-fecha-notific-benef-terminacion-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-notific-benef-terminacion-ant-id",
                                      placeholder:
                                        "Fecha de notificación a Beneficiario de acuerdo de terminación anticipada",
                                      state:
                                        _vm.proyecto
                                          .fechaNotificBenefTerminacionAnt !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaNotificBenefTerminacionAnt,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaNotificBenefTerminacionAnt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaNotificBenefTerminacionAnt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-acuerdo-cta-terminacion-ant-id",
                            label:
                              "Número de acuerdo de CTA de la terminación anticipada",
                            "label-for":
                              "input-num-acuerdo-cta-terminacion-ant-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaTerminacionAnticipada"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-acuerdo-cta-terminacion-ant-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.numAcuerdoCtaTerminacionAnt !=
                                      null &&
                                    _vm.proyecto.numAcuerdoCtaTerminacionAnt
                                      .length > 0,
                                  placeholder:
                                    "Número de acuerdo de CTA de la terminación anticipada",
                                },
                                model: {
                                  value:
                                    _vm.proyecto.numAcuerdoCtaTerminacionAnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numAcuerdoCtaTerminacionAnt",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "proyecto.numAcuerdoCtaTerminacionAnt",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Concluido por rescisión", lazy: "" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-opinion-juridica-rescision-id",
                            label:
                              "Fecha de opinión juridica favorable para la rescisión del proyecto",
                            "label-for":
                              "input-fecha-opinion-juridica-rescision-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-opinion-juridica-rescision-id",
                                      placeholder:
                                        "Fecha de opinión juridica favorable para la rescisión del proyecto",
                                      state:
                                        _vm.proyecto
                                          .fechaOpinionJuridicaRescision !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaOpinionJuridicaRescision,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaOpinionJuridicaRescision",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaOpinionJuridicaRescision",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-sesion-cta-rescision-id",
                            label: "Número de sesión del CTA",
                            "label-for": "input-num-sesion-cta-rescision-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-sesion-cta-rescision-id",
                                  state:
                                    _vm.proyecto.numSesionCtaRescision != null,
                                  placeholder: "Número de sesión del CTA",
                                },
                                model: {
                                  value: _vm.proyecto.numSesionCtaRescision,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numSesionCtaRescision",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.numSesionCtaRescision",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-acuerdo-cta-rescision-id",
                            label:
                              "Fecha de acuerdo de CTA de la conclusión por rescisión",
                            "label-for": "input-fecha-acuerdo-cta-rescision-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-acuerdo-cta-rescision-id",
                                      placeholder:
                                        "Fecha de acuerdo de CTA de la conclusión por rescisión",
                                      state:
                                        _vm.proyecto.fechaAcuerdoCtaRescision !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto.fechaAcuerdoCtaRescision,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaAcuerdoCtaRescision",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaAcuerdoCtaRescision",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-acuerdo-cta-rescision-id",
                            label: "Número de acuerdo de CTA",
                            "label-for": "input-num-acuerdo-cta-rescision-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-acuerdo-cta-rescision-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.numAcuerdoCtaRescision !=
                                      null &&
                                    _vm.proyecto.numAcuerdoCtaRescision.length >
                                      0,
                                  placeholder: "Número de acuerdo de CTA",
                                },
                                model: {
                                  value: _vm.proyecto.numAcuerdoCtaRescision,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numAcuerdoCtaRescision",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.numAcuerdoCtaRescision",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-num-causa-rescision-id",
                            label:
                              "Numeral de causal de rescisión de acuerdo a Lineamiento 5.2.4",
                            "label-for": "input-num-causa-rescision-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.leftbottom",
                                              modifiers: {
                                                hover: true,
                                                leftbottom: true,
                                              },
                                            },
                                          ],
                                          attrs: {
                                            title: _vm.$t(
                                              "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "info-circle",
                                              variant: "primary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-num-causa-rescision-id",
                                  autocomplete: "off",
                                  state:
                                    _vm.proyecto.numCausaRescision != null &&
                                    _vm.proyecto.numCausaRescision.length > 0,
                                  placeholder:
                                    "Numeral de causal de rescisión de acuerdo a Lineamiento 5.2.4",
                                },
                                model: {
                                  value: _vm.proyecto.numCausaRescision,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.proyecto,
                                      "numCausaRescision",
                                      $$v
                                    )
                                  },
                                  expression: "proyecto.numCausaRescision",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "form-group-fecha-notificacion-acuerdo-conclusion-id",
                            label:
                              "Fecha de notificación a Beneficiario de acuerdo de conclusión por rescisión",
                            "label-for":
                              "input-fecha-notificacion-acuerdo-conclusion-id",
                            description: _vm.$t(
                              "distribucionGwApp.proyectosMsProyecto.adjuntarDocumento"
                            ),
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function () {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.leftbottom",
                                                  modifiers: {
                                                    hover: true,
                                                    leftbottom: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                title: _vm.$t(
                                                  "distribucionGwApp.proyectosMsProyecto.constanciaRescision"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "info-circle",
                                                  variant: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-notificacion-acuerdo-conclusion-id",
                                      placeholder:
                                        "Fecha de notificación a Beneficiario de acuerdo de conclusión por rescisión",
                                      state:
                                        _vm.proyecto
                                          .fechaNotificacionAcuerdoConclusion !=
                                        null,
                                      locale: "es",
                                    },
                                    model: {
                                      value:
                                        _vm.proyecto
                                          .fechaNotificacionAcuerdoConclusion,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "fechaNotificacionAcuerdoConclusion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "proyecto.fechaNotificacionAcuerdoConclusion",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { col: "", cols: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "-modal-proyecto-etapa",
                    label: "Etapa",
                    "label-for": "input-proyecto-etapa",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.etapasOptions, size: "sm" },
                    model: {
                      value: _vm.proyecto.etapa,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "etapa", $$v)
                      },
                      expression: "proyecto.etapa",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { col: "", cols: "3" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "-modal-proyecto-estatus",
                    label: "Estado",
                    "label-for": "input-proyecto-estatus",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.estatusOptions, size: "sm" },
                    model: {
                      value: _vm.proyecto.estatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "estatus", $$v)
                      },
                      expression: "proyecto.estatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { col: "", cols: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "-modal-proyecto-comentario",
                    label: "Comentario",
                    "label-for": "input-proyecto-comentario",
                    size: "sm",
                  },
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "input-proyecto-comentario",
                      placeholder: "Ingrese un comentario",
                      rows: "2",
                      "no-resize": "",
                      "no-auto-shrink": "",
                    },
                    model: {
                      value: _vm.comentario,
                      callback: function ($$v) {
                        _vm.comentario = $$v
                      },
                      expression: "comentario",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.glosarioTerminos",
                          modifiers: { glosarioTerminos: true },
                        },
                      ],
                      attrs: { variant: "outline-primary" },
                    },
                    [_c("span", [_vm._v("Glosario")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [_c("span", [_vm._v("Cancelar")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", disabled: _vm.isSaving },
                      on: {
                        click: function ($event) {
                          return _vm.saveProyecto(_vm.account.login)
                        },
                      },
                    },
                    [
                      _vm.isSaving
                        ? _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "circle-fill",
                                  animation: "throb",
                                },
                              }),
                              _c("em", [_vm._v("Guardando")]),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("Guardar")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "glosarioTerminos",
          attrs: {
            size: "lg",
            scrollable: "",
            centered: "",
            "header-bg-variant": "primary",
            "header-text-variant": "white",
            id: "glosarioTerminos",
            "ok-only": "",
            "ok-title": "Cerrar",
            title: "Glosario de términos",
          },
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("h1", [_vm._v("Glosario")]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Beneficiario(s):")]),
              _vm._v(
                " las instituciones, centros, organismos y empresas públicas, que sistemáticamente realicen\n        actividades de investigación científica, desarrollo tecnológico, innovación o producción de ingeniería básica, incluyendo\n        dependencias y entidades de la administración pública, empresas productivas del Estado, centros de investigación, instituciones de\n        educación superior, laboratorios, entre otros, y las instituciones, centros, organismos o personas físicas de los sectores\n        público, social y y privado en ejercicio del derecho humano a la ciencia, incluyendo ejidos y comunidades agrarias, pueblos y\n        comunidades indígenas, sociedades rurales, sociedades cooperativas, sociedades de solidaridad social, organizaciones de\n        trabajadores, empresas, entre otros, que se encuentren inscritos en el RENIECYT, cuyas Propuestas hayan sido aprobadas por el CTA\n        para recibir algún Apoyo y éste se haya formalizado con la suscripción de un CAR.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("CAR:")]),
              _vm._v(
                " el Convenio de Asignación de Recursos. Instrumento jurídico en el que se establecen los términos y\n        condiciones bajo los cuales el Consejo otorga los recursos para la ejecución de los Proyectos.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("CTA: ")]),
              _vm._v("el Comité Técnico y de Administración del Programa."),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Etapa(s):")]),
              _vm._v(
                " los periodos de ejecución en que se divide una Propuesta o Proyecto, en los que se especifican metas,\n        resultados, productos entregables, beneficios esperados y recursos requeridos.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Evaluadores:")]),
              _vm._v(
                " las personas profesionales, investigadores, científicos, tecnólogos, peritos y, en general, las\n        personas con experiencia y las capacidades necesarias que, en términos de los Lineamientos, el Manual de Procedimientos y demás\n        normativa aplicable, apoyen a los Secretarios Técnicos, al Secretario Administrativo o al CTA en la revisión, valoración,\n        seguimiento o evaluación de Propuestas o Proyectos con opiniones y recomendaciones de carácter técnico y orientativo.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Programa:")]),
              _vm._v(
                ' el Programa Presupuestario F003 "Programas Nacionales Estratégicos de Ciencia, Tecnología y\n        Vinculación con los Sectores Social, Público y Privado".\n      '
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Propuesta(s):")]),
              _vm._v(
                " las actividades en materia de humanidades, ciencias, tecnologías e innovación presentadas a\n        consideración del CTA para recibir Apoyo del Programa.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Proyecto(s):")]),
              _vm._v(
                " la propuesta aprobada por el CTA para recibir Apoyo del Programa."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("RENIECYT:")]),
              _vm._v(
                " el Registro Nacional de Instituciones y Empresas Científicas y Tecnológicas."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Responsable Administrativo:")]),
              _vm._v(
                " la persona designada por el Beneficiario o su representante legal para llevar a cabo\n        la ejecución y seguimiento administrativo y financiero del Proyecto.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Representante Legal:")]),
              _vm._v(
                " la persona física con poder legal o, en su caso, facultades para contraer compromisos a\n        nombre de la instancia proponente y firmar el instrumento jurídico correspondiente.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Responsable Técnico:")]),
              _vm._v(
                " la persona designada por el Beneficiario o su representante legal, adscrita o, en el caso de\n        las y los investigadores del Conahcyt, comisionada a la Institución, para llevar a cabo la ejecución y seguimiento técnico del\n        Proyecto.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Secretaria Administrativa:")]),
              _vm._v(" la Secretaría Administrativa del Programa."),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Secretaría(s) Técnica(s):")]),
              _vm._v(" la(s) Secretaría(s) Técnica(s) del Programa."),
            ]),
            _vm._v(" "),
            _c("h1", [_vm._v("Datos de identificación")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        En este apartado se registran datos tales como el fondo de origen, la partida presupuestaria, el fondo de la convocatoria,\n        modalidad, clave y título del proyecto, entre otros.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("h1", [_vm._v("Selección de propuestas")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        En esta etapa se registran los datos correspondientes a los estados de convocatoria, recepción, revisión, aprobación, cancelación\n        y formalización.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("h1", [_vm._v("Seguimiento técnico y administrativo")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "En esta etapa, se registran los datos correspondientes a los estados de Ministración, seguimiento y evaluación de resultados."
              ),
            ]),
            _vm._v(" "),
            _c("h1", [_vm._v("Conclusión")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "En esta etapa, se especifica el tipo de conclusión del proyecto: Con constancia, por terminación anticipada o por rescisión."
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("hr", { staticClass: "divider" }),
    _vm._v(" "),
    _vm.dashboard
      ? _c(
          "div",
          { staticClass: "user-info_ text" },
          [
            _c(
              "span",
              { domProps: { textContent: _vm._s(_vm.$t("user.tasks")) } },
              [_vm._v("Number of tasks executed")]
            ),
            _vm._v(" "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(_vm._s(_vm.dashboard.tareasTotal)),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.dashboard
      ? _c(
          "div",
          { staticClass: "user-info_ text" },
          [
            _c(
              "span",
              { domProps: { textContent: _vm._s(_vm.$t("user.records")) } },
              [_vm._v("Number of records")]
            ),
            _vm._v(" "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(_vm._s(_vm.dashboard.programasTotal)),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.dashboard
      ? _c(
          "div",
          { staticClass: "user-info_ text" },
          [
            _c(
              "span",
              { domProps: { textContent: _vm._s(_vm.$t("user.rules")) } },
              [_vm._v("Number of rules")]
            ),
            _vm._v(" "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(_vm._s(_vm.dashboard.reglasTotal)),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("hr", { staticClass: "divider" }),
    _vm._v(" "),
    _c("div", { staticClass: "user-info_ footer-" }, [
      _c("span", { domProps: { textContent: _vm._s(_vm.$t("user.email")) } }, [
        _vm._v("Email"),
      ]),
      _vm._v(" "),
      _c("small", { staticClass: "d-block" }, [
        _vm._v(" " + _vm._s(_vm.account.email) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-info_ text" }, [
      _c("small", [_vm._v(" Panel de seguimiento ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
//Constantes generales
export class Constants {
  static MODALIDES_APOYO = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'PIC', text: 'I. Proyectos de investigación científica.' },
    { value: 'PDT', text: 'II. Proyectos de desarrollo tecnológico.' },
    { value: 'PI', text: 'III. Proyectos de innovación.' },
    { value: 'PAUCBS', text: 'IV. Proyectos de acceso universal al conocimiento y sus beneficios sociales.' },
    {
      value: 'OHCTI',
      text: 'V. Las demás actividades que determine el CTA directamente vinculadas con las humanidades, ciencias, tecnologías y la innovación.',
    },
  ];
  static DIRECCIONES_ADJUNTAS = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'CRIP', text: 'Coordinación de Repositorios, Investigación y Prospectiva (CRIP)' },
    { value: 'DADC', text: 'Dirección Adjunta de Desarrollo Científico (DADC)' },
    { value: 'DADTVI', text: 'Dirección Adjunta de Desarrollo Tecnológico, Vinculación e Innovación (DADTVI)' },
    { value: 'DG', text: 'Dirección General' },
    { value: 'UAF', text: 'Unidad de Administración y Finanzas (UAF)' },
    { value: 'UASR', text: 'Unidad de Articulación Sectorial y Regional (UASR)' },
    { value: 'UAJ', text: 'Unidad de Asuntos Jurídicos (UAJ)' },
    { value: 'UPCCI', text: 'Unidad de Planeación, Comunicación y Cooperación Internacional (UPCCI)' },
  ];
  static AREAS_CONOCIMIENTO = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: '1', text: 'Ciencias físico matemáticas y ciencias de la tierra' },
    { value: '2', text: 'Biología y química' },
    { value: '3', text: 'Medicina y ciencias de la salud' },
    { value: '4', text: 'Humanidades y ciencias de la conducta' },
    { value: '5', text: 'Ciencias Sociales' },
    { value: '6', text: 'Ciencias agropecuarias y biotecnología' },
    { value: '7', text: 'Ingeniería y tecnología' },
  ];
  static ENTIDADES_FEDERATIVAS = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'AGS', text: 'AGUASCALIENTES' },
    { value: 'BC', text: 'BAJA CALIFORNIA' },
    { value: 'BCS', text: 'BAJA CALIFORNIA SUR' },
    { value: 'CAMP', text: 'CAMPECHE' },
    { value: 'COAH', text: 'COAHUILA DE ZARAGOZA' },
    { value: 'COLI', text: 'COLIMA' },
    { value: 'CHPS', text: 'CHIAPAS' },
    { value: 'CHIH', text: 'CHIHUAHUA' },
    { value: 'CDMX', text: 'CIUDAD DE MÉXICO' },
    { value: 'DGO', text: 'DURANGO' },
    { value: 'GTO', text: 'GUANAJUATO' },
    { value: 'GRO', text: 'GUERRERO' },
    { value: 'HGO', text: 'HIDALGO' },
    { value: 'JAL', text: 'JALISCO' },
    { value: 'EMEX', text: 'MÉXICO' },
    { value: 'MICH', text: 'MICHOACÁN DE OCAMPO' },
    { value: 'MOR', text: 'MORELOS' },
    { value: 'NAY', text: 'NAYARIT' },
    { value: 'NL', text: 'NUEVO LEÓN' },
    { value: 'OAX', text: 'OAXACA' },
    { value: 'PUE', text: 'PUEBLA' },
    { value: 'QRO', text: 'QUERÉTARO' },
    { value: 'QROO', text: 'QUINTANA ROO' },
    { value: 'SLP', text: 'SAN LUIS POTOSÍ' },
    { value: 'SIN', text: 'SINALOA' },
    { value: 'SON', text: 'SONORA' },
    { value: 'TAB', text: 'TABASCO' },
    { value: 'TAMP', text: 'TAMAULIPAS' },
    { value: 'TLAX', text: 'TLAXCALA' },
    { value: 'VER', text: 'VERACRUZ DE IGNACIO DE LA LLAVE' },
    { value: 'YUC', text: 'YUCATÁN' },
    { value: 'ZAC', text: 'ZACATECAS' },
  ];
  static EJES = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: '1', text: 'Fortalecimiento de las Comunidades de Humanidades, Ciencia, Tecnología e Innovación' },
    { value: '2', text: 'Ciencia Básica y de Frontera' },
    { value: '3', text: 'Programas Nacionales Estratégicos' },
    { value: '4', text: 'Desarrollo Tecnológico e Innovación Abierta' },
    { value: '5', text: 'Acceso Universal al Conocimiento' },
  ];
  static DIRECCIONES = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'CABI', text: 'Coordinación de Apoyos a Becarios e Investigadores' },
    { value: 'CC', text: 'Coordinación de Comunicación' },
    { value: 'DAUC', text: 'Dirección de Acceso Universal al Concocimiento' },
    { value: 'DAIF', text: 'Dirección de Administración e Información de Fondos' },
    { value: 'DAPF', text: 'Dirección de Administración Presupuestal y Financiera' },
    { value: 'DADSIG', text: 'Dirección de Análisis de Datos y Sistemas de Información Geográfica' },
    { value: 'DAE', text: 'Dirección de Análisis Estratégico' },
    { value: 'DAMCAD', text: 'Dirección de Arquitectura y Modelado para Cómputo Aplicado a Datos' },
    { value: 'DACI', text: 'Dirección de Articulación de Centros de Investigación' },
    { value: 'DB', text: 'Dirección de Becas' },
    { value: 'DCF', text: 'Dirección de Ciencia de Frontera' },
    { value: 'DCEN', text: 'Dirección de Consulta y Estudios Normativos' },
    { value: 'DCPEA', text: 'Dirección de Contenidos, Producción Editorial y Audiovisual' },
    { value: 'DCI', text: 'Dirección de Cooperación Internacional' },
    { value: 'DECC', text: 'Dirección de Energías y Cambio Climático' },
    { value: 'DET', text: 'Dirección de Estrategia Tecnológica' },
    { value: 'DEPJ', text: 'Dirección de Estrategias y Procesos Jurídicos' },
    { value: 'DISO', text: 'Dirección de Infraestructura y Soporte Operativo' },
    { value: 'DPE', text: 'Dirección de Planeación y Evaluación' },
    { value: 'DP', text: 'Dirección de Posgrado' },
    { value: 'DPNE', text: 'Dirección de Programas Nacionales Estratégicos' },
    { value: 'DRH', text: 'Dirección de Recursos Humanos' },
    { value: 'DRMSG', text: 'Dirección de Recursos Materiales y Servicios Generales' },
    { value: 'DRHCIC', text: 'Dirección de Redes Horizontales del Conocimiento e Infraestructura Científica' },
    { value: 'DRPD', text: 'Dirección de Repositorios y Procesamiento de Datos' },
    { value: 'DVI', text: 'Dirección de Vinculación e Innovación' },
    { value: 'DVNI', text: 'Dirección de Vinculación nacional e internacional' },
    { value: 'DVAR', text: 'Dirección de Vinculación y Articulación Regional' },
    { value: 'DVCS', text: 'Dirección de Vocaciones Científicas y del SNI' },
    { value: 'DRC', text: 'Dirección Regional Centro' },
    { value: 'DRNESTE', text: 'Dirección Regional Noreste' },
    { value: 'DRNOESTE', text: 'Dirección Regional Noroeste' },
    { value: 'DRO', text: 'Dirección Regional Occidente' },
    { value: 'DRSO', text: 'Dirección Regional Sur-Oriente' },
    { value: 'DRS', text: 'Dirección Regional Sureste' },
    { value: 'DDC', text: 'Dirección de Difusión Científica' },
    { value: 'CRIP', text: 'Coordinación de Repositorios, Investigación y Prospectiva' },
  ];
  static TIPOCAR = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'Digital', text: 'Digital' },
    { value: 'Electronico', text: 'Electrónico' },
  ];
  static FONDOS = ['FOSEC', 'Institucional', 'Programa presupuestal Y EN ESPERA', 'FOMIX'];
  static ETAPAS = ['Selección de propuestas', 'Seguimiento técnico y administrativo', 'Conclusión'];
  static ESTATUS = [
    'Convocatoria',
    'Revisión',
    'Aprobación',
    'Cancelación',
    'Formalización',
    'Ministración',
    'Seguimiento',
    'Evaluación de resultados',
    'Concluido',
    'Concluido con constancia',
    'Concluido por terminación anticipada',
    'Concluido por rescisión',
  ];

  static ESTADOS_SOLICITUD = ['BORRADOR', 'CREADA', 'ENVIADA Y EN ESPERA', 'EN FORMALIZACION', 'FORMALIZADA', 'CANCELADA'];
  static GRUPOS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  static ENTIDADES = [
    'AGS',
    'BC',
    'BCS',
    'CAMP',
    'COAH',
    'COLI',
    'CHPS',
    'CHIH',
    'CDMX',
    'DGO',
    'GTO',
    'GRO',
    'HGO',
    'JAL',
    'EMEX',
    'MICH',
    'MOR',
    'NAY',
    'NL',
    'OAX',
    'PUE',
    'QRO',
    'QROO',
    'SLP',
    'SIN',
    'SON',
    'TAB',
    'TAMP',
    'TLAX',
    'VER',
    'YUC',
    'ZAC',
  ];

  static CAMPOS = [
    'ADMINISTRACION / GESTION EMPRESARIAL',
    'AGRONOMIA',
    'ANTROPOLOGIA Y ARQUEOLOGIA',
    'ARQUITECTURA Y URBANISMO',
    'ARTE Y CULTURA',
    'ASTRONOMIA Y ASTROFISICA',
    'BIOLOGIA',
    'BIOTECNOLOGIA',
    'CIENCIAS AMBIENTALES',
    'CIENCIAS BIOMEDICAS',
    'CIENCIAS DE LA COMUNICACION',
    'CIENCIAS DE LA TIERRA Y DEL ESPACIO',
    'CIENCIAS DEL MAR',
    'CIENCIAS ECONOMICAS',
    'CIENCIAS FISICAS',
    'CIENCIAS JURIDICAS Y DERECHO',
    'CIENCIAS MATEMATICAS',
    'CIENCIAS POLITICAS',
    'DEMOGRAFIA',
    'DIDACTICA Y DOCENCIA',
    'EDUCACION',
    'ENFERMERIA',
    'ESPECIALIDAD MEDICA',
    'FILOSOFIA',
    'GEOGRAFIA',
    'GESTION',
    'HISTORIA',
    'INGENIERIA Y TECNOLOGIA',
    'INVESTIGACION MEDICA',
    'LINGUISTICA, LITERATURA Y FILOLOGIA',
    'ODONTOLOGIA',
    'PESCA',
    'PSICOLOGIA',
    'QUIMICA',
    'SALUD Y PRODUCCION ANIMAL',
    'SOCIOLOGIA',
  ];

  static GRADOS = ['ESP', 'MAE', 'DOC'];
  static ORIENTACIONES = ['INV', 'PRO'];
  static METODOLOGIAS = ['ESC', 'NO ESC', 'ESP', 'MED', 'ESP MED', 'PI']; // MODALIDAD O METODOLOGIAS
  static AREAS = ['1', '2', '3', '4', '5', '6', '7'];
  static TIPO_INSTITUCIONES = ['Pub', 'Priv'];
  static DISCIPLINAS = [
    'ACUACULTURA',
    'ACUPUNTURA HUMANA',
    'ACUSTICA',
    'ADMINISTRACION DE EMPRESAS TURISTICAS',
    'ADMINISTRACION DE INSTITUCIONES EDUCATIVAS',
    'ADMINISTRACION DE LA ATENCION DE ENFERMERIA',
    'ADMINISTRACION EN GESTION Y DESARROLLO DE LA EDUCACION',
    'ADMINISTRACION ESTRATEGICA',
    'ADMINISTRACION FISCAL',
    'ADMINISTRACION INTEGRAL DEL AMBIENTE',
    'ADMINISTRACION PUBLICA',
    'ADMINISTRACION Y ALTA DIRECCION',
    'ADMINISTRACION Y DIRECCION DE EMPRESAS',
    'ADMINISTRACION Y SUPERVISION EDUCATIVA',
    'AGROPLASTICULTURA',
    'ALERGIA E INMUNOLOGIA CLINICA',
    'ALERGIA E INMUNOLOGIA CLINICA PEDIATRICA',
    'ALGEBRA',
    'ANALISIS Y ANALISIS FUNCIONAL',
    'ANATOMIA',
    'ANATOMIA NORMAL Y PATOLOGICA',
    'ANATOMIA PATOLOGICA',
    'ANESTESIOLOGIA',
    'ANESTESIOLOGIA PEDIATRICA',
    'ANGIOLOGIA Y CIRUGIA VASCULAR',
    'ANTROPOLOGIA FISICA',
    'ANTROPOLOGIA SOCIAL',
    'ANTROPOLOGIA SOCIOCULTURAL',
    'AREA / ESTUDIOS / CULTURAL / GENERO / ETNICOS',
    'ARQUEOLOGIA',
    'ARQUITECTURA',
    'ARQUITECTURA, DISENO Y URBANISMO',
    'ARTES ESCENICAS',
    'ARTES PARA EL DISENO',
    'ASTRONOMIA OPTICA',
    'ASUNTOS URBANOS / ESTUDIOS',
    'AUDIOLOGIA, OTONEUROLOGIA Y FONIATRIA',
    'AUTOMATIZACION Y CONTROL',
    'BACTERIOLOGIA',
    'BIBLIOTECOLOGIA Y ESTUDIOS DE LA INFORMACION',
    'BIOECONOMIA PESQUERA Y ACUICOLA',
    'BIOFISICA',
    'BIOFISICA',
    'BIOINFORMATICA',
    'BIOINGENIERIA INGENIERIA BIOMEDICA',
    'BIOLOGIA CELULAR Y HISTOLOGIA',
    'BIOLOGIA DE LA REPRODUCCION HUMANA',
    'BIOLOGIA DE POBLACIONES',
    'BIOLOGIA DEL DESARROLLO / EMBRIOLOGIA',
    'BIOLOGIA ESTRUCTURAL',
    'BIOLOGIA EVOLUTIVA',
    'BIOLOGIA INTEGRATIVA',
    'BIOLOGIA MOLECULAR',
    'BIOMEDICINA MOLECULAR',
    'BIOMETRIA Y BIOESTADISTICA',
    'BIOPROCESOS',
    'BIOQUIMICA',
    'BIOSEGURIDAD',
    'BIOSISTEMATICA Y MANEJO DE RECURSOS NATURALES',
    'BIOTECNOLOGIA MARINA',
    'BIOTECNOLOGIA PRODUCTIVA',
    'BIOTECNOLOGIA VEGETAL',
    'BIOTECNOLOGIA VEGETAL',
    'BOTANICA',
    'BOTANICA / BIOLOGIA VEGETAL',
    'CALIDAD DE LA ATENCION CLINICA',
    'CAMBIO Y DESARROLLO SOCIAL',
    'CAMPOS EMERGENTES',
    'CARACTERISTICAS DE LA POBLACION',
    'CARDIOLOGIA',
    'CARDIOLOGIA PEDIATRICA',
    'CARDIOLOGIA, FISIOLOGIA CARDIOVASCULAR',
    'CIENCIA DE LOS ALIMENTOS',
    'CIENCIA DE MATERIALES',
    'CIENCIA E INGENIERIA DE LA COMPUTACION',
    'CIENCIA POLITICA Y GOBIERNO',
    'CIENCIA Y TECNOLOGIA  DE LOS RECURSOS FORESTALES',
    'CIENCIA Y TECNOLOGIA DE ALIMENTOS',
    'CIENCIA Y TECNOLOGIA DE LOS ALIMENTOS',
    'CIENCIA Y TECNOLOGIA DE MATERIALES',
    'CIENCIA Y TECNOLOGIA DEL AGUA',
    'CIENCIAS COGNITIVAS',
    'CIENCIAS DE LA ATMOSFERA',
    'CIENCIAS DE LA FLORICULTURA',
    'CIENCIAS DE LA INFORMACION',
    'CIENCIAS DE LA NUTRICION',
    'CIENCIAS DEL COMPORTAMIENTO',
    'CIENCIAS DEL HABITAT',
    'CIENCIAS DEL MAR',
    'CIENCIAS DEL SUELO',
    'CIENCIAS DEL SUELO',
    'CIENCIAS EN BIODIVERSIDAD',
    'CIENCIAS EN SALUD DE LOS TRABAJADORES',
    'CIENCIAS EN SALUD PUBLICA',
    'CIENCIAS ESTOMATOLOGICAS',
    'CIENCIAS VETERINARIAS',
    'CIENCIAS Y TECNOLOGIA DE LA MADERA',
    'CIRUGIA CARDIOTORACICA',
    'CIRUGIA CARDIOTORACICA PEDIATRICA',
    'CIRUGIA DE COLUMNA',
    'CIRUGIA GENERAL',
    'CIRUGIA ONCOLOGICA',
    'CIRUGIA ORAL Y MAXILOFACIAL',
    'CIRUGIA PEDIATRICA',
    'CIRUGIA PLASTICA Y RECONSTRUCTIVA',
    'CIRUGIA, TRAUMATOLOGIA, CIRUGIA EXPERIMENTAL',
    'CIUDAD TERRITORIO  Y SUSTENTABILIDAD',
    'CIUDAD Y ESPACIO PUBLICO SUSTENTABLE',
    'CLIMATOLOGIA',
    'COGNICION Y EDUCACION',
    'COLOPROCTOLOGIA',
    'COMERCIALIZACION DE CONOCIMIENTOS INNOVADORES',
    'COMUNICACION CORPORATIVA, DISENO DIGITAL, MEDIOS MASIVOS',
    'COMUNICACION DE CINE, RADIO, TV Y DIGITAL',
    'COMUNICACION, EN GENERAL',
    'COMUNICACIONES SOCIALES',
    'CONSERVACION DE ECOSISTEMAS',
    'CONSERVACION DEL PATRIMONIO ARQUITECTONICO',
    'CONTABILIDAD,  IMPUESTOS Y AUDITORIA',
    'CONTROL Y SISTEMAS DINAMICOS',
    'COOPERACION INTERNACIONAL',
    'COSMOLOGIA',
    'CRIMINOLOGIA',
    'CULTIVOS EN AMBIENTES CONTROLADOS',
    'CURTIDO DE PIELES',
    'DEMOGRAFIA GENERAL',
    'DEMOGRAFIA GEOGRAFICA',
    'DEMOGRAFIA HISTORICA',
    'DERECHO ADMINISTRATIVO',
    'DERECHO COMERCIAL, INDUSTRIAL Y MINERO',
    'DERECHO COMPARADO',
    'DERECHO DEL AMBIENTE',
    'DERECHO ECONOMICO',
    'DERECHO INTERNACIONAL PUBLICO Y PRIVADO',
    'DERECHO LABORAL',
    'DERECHO PENAL',
    'DERECHO PROCESAL',
    'DERECHO Y LEGISLACION NACIONALES',
    'DERECHOS HUMANOS',
    'DERMATOLOGIA',
    'DERMATOLOGIA PEDIATRICA',
    'DERMATOLOGIA Y ENFERMEDADES DE TRANSMISION SEXUAL',
    'DESARROLLO CIENTIFICO Y TECNOLOGICO',
    'DESARROLLO DE LOS RECURSOS HUMANOS',
    'DESARROLLO DE PRODUCTOS BIOTICOS',
    'DESARROLLO MUNICIPAL',
    'DESARROLLO REGIONAL',
    'DESARROLLO REGIONAL SUSTENTABLE',
    'DESARROLLO RURAL REGIONAL',
    'DESARROLLO SUSTENTABLE Y GESTION DE RIESGOS',
    'DESARROLLO, SUSTENTABILIDAD Y TURISMO',
    'DIDACTICA DE LAS LENGUAS',
    'DIDACTICA DE LAS MATEMATICAS',
    'DIRECCION Y GESTION DE PROYECTOS DE INGENIERIA',
    'DISENO AVANZADO',
    'DISENO CURRICULAR',
    'DISENO DE POLITICAS PUBLICAS',
    'DISENO DE SISTEMAS EN CHIP',
    'DISENO INTERACTIVO Y MANUFACTURA',
    'DISENO URBANO',
    'DISENO, PLANIFICACION Y CONSERVACION  DE PAISAJES Y JARDINES',
    'DIVERSIDAD Y EDUCACION INCLUSIVA',
    'DOCENCIA DE IDIOMAS',
    'DOCENCIA DE LAS MATEMATICAS',
    'DOCENCIA PARA LA EDUCACION  MEDIA SUPERIOR',
    'ECOLOGIA',
    'ECOLOGIA FORESTAL',
    'ECOLOGIA MARINA',
    'ECOLOGIA MOLECULAR',
    'ECONOMETRIA',
    'ECONOMIA',
    'ECONOMIA AGRARIA Y SISTEMAS DE PRODUCCION AGRICOLA',
    'ECONOMIA AGRICOLA Y DE LOS RECURSOS NATURALES',
    'ECONOMIA AMBIENTAL Y ECOLOGICA',
    'ECONOMIA DE LOS RECURSOS NATURALES',
    'ECONOMIA INDUSTRIAL',
    'ECONOMIA LABORAL',
    'ECONOMIA MONETARIA',
    'ECONOMIA REGIONAL',
    'ECONOMIA Y GESTION MUNICIPAL',
    'ECONOMIA Y POLITICA PUBLICA',
    'EDUCACION AGRICOLA SUPERIOR',
    'EDUCACION AMBIENTAL',
    'EDUCACION ESPECIAL',
    'EDUCACION ESPECIAL CON ENFOQUE COMUNITARIO',
    'EDUCACION MATEMATICA',
    'EDUCACION PARA LA CIUDADANIA',
    'EDUCACION SUPERIOR',
    'EDUCACION SUPERIOR / EVALUACION E INVESTIGACION',
    'EDUCACION VIRTUAL',
    'ELECTRONICA FISICA',
    'ELECTRONICA NAVAL',
    'ELECTROQUIMICA',
    'EMPRESAS / ECONOMIA GERENCIAL',
    'ENDOCRINOLOGIA',
    'ENDOCRINOLOGIA',
    'ENDOCRINOLOGIA PEDIATRICA',
    'ENDODONCIA',
    'ENERGIA RENOVABLE',
    'ENFERMEDADES DE LA NUTRICION Y ENDOCRINOLOGIA',
    'ENFERMEDADES INFECCIOSAS',
    'ENFERMEDADES INFECCIOSAS, BACTERIOLOGIA CLINICA, VIROLOGIA CLINICA',
    'ENFERMEDADES RESPIRATORIAS, FISIOLOGIA',
    'ENFERMEDADES VETERINARIAS',
    'ENFERMERIA CARDIOVASCULAR',
    'ENFERMERIA CLINICA AVANZADA',
    'ENFERMERIA DE ATENCION EN EL HOGAR',
    'ENFERMERIA DEL ADULTO EN ESTADO CRITICO',
    'ENFERMERIA DEL ANCIANO',
    'ENFERMERIA DEL NEONATO',
    'ENFERMERIA EN LA CULTURA FISICA Y EL DEPORTE',
    'ENFERMERIA EN REHABILITACION',
    'ENFERMERIA EN SALUD FAMILIAR',
    'ENFERMERIA EN SALUD MENTAL',
    'ENFERMERIA EN SALUD PUBLICA',
    'ENFERMERIA INFANTIL',
    'ENFERMERIA NEUROLOGICA',
    'ENFERMERIA ONCOLOGICA',
    'ENFERMERIA PERINATAL',
    'ENSENANZA DE ESTUDIOS  LITERARIOS',
    'ENSENANZA DE IDIOMAS',
    'ENSENANZA DE LA HISTORIA',
    'ENSENANZA DE LAS MATEMATICAS',
    'ENTOMOLOGIA',
    'ENTOMOLOGIA AGRICOLA',
    'EPIDEMIOLOGIA',
    'ESTADISTICA',
    'ESTADISTICAS EDUCATIVAS / METODOS DE INVESTIGACION',
    'ESTETICA',
    'ESTOMATOLOGIA PEDIATRICA',
    'ESTUDIOS CULTURALES',
    'ESTUDIOS DE ARTE',
    'ESTUDIOS DE DEMOGRAFIA / POBLACION',
    'ESTUDIOS DE GENERO',
    'ESTUDIOS DE LA CIUDAD',
    'ESTUDIOS DE LA MUJER',
    'ESTUDIOS DE OPINION',
    'ESTUDIOS HISTORICOS INTERDISCIPLINARIOS',
    'ESTUDIOS PARA LA PAZ Y EL DESARROLLO',
    'ESTUDIOS Y PROCESOS CREATIVOS EN ARTE Y DISENO',
    'ETICA',
    'EVALUACION EDUCATIVA  / MEDICION',
    'FAMILIAS Y PREVENCION DE LA VIOLENCIA',
    'FARMACIA, FARMACOLOGIA CLINICA',
    'FARMACOLOGIA ANIMAL',
    'FARMACOLOGIA HUMANA',
    'FERTILIDAD',
    'FILOLOGIA',
    'FILOSOFIA',
    'FILOSOFIA ANALITICA',
    'FILOSOFIA CONTEMPORANEA APLICADA',
    'FILOSOFIA DE LA CIENCIA',
    'FILOSOFIA DE LA CULTURA',
    'FILOSOFIA DE LA EDUCACION',
    'FINANZAS',
    'FISICA APLICADA',
    'FISICA ATOMICA Y MOLECULAR',
    'FISICA CUANTICA',
    'FISICA DE FLUIDOS',
    'FISICA DE PLASMAS / FUSION FISICA',
    'FISICA ESTADISTICA Y TERMODINAMICA',
    'FISICA NUCLEAR',
    'FISICA TEORICA',
    'FISICOQUIMICA',
    'FISIOLOGIA',
    'FISIOLOGIA BIOFISICA',
    'FISIOLOGIA HUMANA Y ANIMAL',
    'FISIOLOGIA VEGETAL',
    'FISIOLOGIA VEGETAL',
    'FISIOPATOLOGIA, FISIOLOGIA CLINICA GENERAL',
    'FITOMEJORAMIENTO',
    'FITOPATOLOGIA',
    'FITOPATOLOGIA',
    'FITOSANIDAD',
    'FRUTICULTURA Y VITICULTURA',
    'FUNDAMENTOS FILOSOFICOS DE LA EDUCACION',
    'GASTROENTEROLOGIA',
    'GASTROENTEROLOGIA PEDIATRICA',
    'GASTROENTEROLOGIA, FISIOLOGIA DIGESTIVA',
    'GENERO, VIOLENCIA Y POLITICAS PUBLICAS',
    'GENETICA',
    'GENETICA MEDICA',
    'GENETICA VEGETAL',
    'GENETICA VEGETAL',
    'GENETICA Y EVOLUCION',
    'GENOMICA ANIMAL',
    'GENOMICA HUMANA',
    'GEODESIA',
    'GEOFISICA Y SISMOLOGIA',
    'GEOGRAFIA',
    'GEOGRAFIA ECONOMICA',
    'GEOGRAFIA FISICA',
    'GEOGRAFIA HISTORICA',
    'GEOGRAFIA HUMANA',
    'GEOGRAFIA REGIONAL',
    'GEOLOGIA',
    'GEOMETRIA / ANALISIS GEOMETRICO',
    'GEOQUIMICA',
    'GERIATRIA',
    'GESTION AMBIENTAL',
    'GESTION AMBIENTAL PARA LA SUSTENTABILIDAD',
    'GESTION DE INNOVACION DE LAS TECNOLOGIAS DE INFORMACION Y COMUNICACION',
    'GESTION DE LA TECNOLOGIA',
    'GESTION DE LOS SISTEMAS DE INFORMACION / ESTADISTICA EMPRESARIAL',
    'GESTION DE PROYECTOS PARA EL DESARROLLO SOLIDARIO',
    'GESTION DEL APRENDIZAJE',
    'GESTION E INVESTIGACION DE MERCADOS / INDUSTRIAL Y ORGANIZACIONAL)',
    'GESTION INTEGRADA DE CUENCAS',
    'GESTION SUSTENTABLE DEL AGUA',
    'GESTION Y DESARROLLO CULTURAL',
    'GESTION Y POLITICAS DE LA EDUCACION SUPERIOR',
    'GINECOLOGIA ONCOLOGICA',
    'GINECOLOGIA Y OBSTETRICIA',
    'GINECOLOGIA, OBSTETRICIA Y REPRODUCCION',
    'GRUPOS SOCIALES',
    'HEMATOLOGIA',
    'HEMATOLOGIA E INMUNOLOGIA CLINICA',
    'HEMATOLOGIA PEDIATRICA',
    'HIDROCIENCIAS',
    'HIDROLOGIA',
    'HIDROLOGIA Y RECURSOS HIDRICOS',
    'HIDROMETEOROLOGIA',
    'HISTORIA DE AMERICA',
    'HISTORIA DE LA FILOSOFIA',
    'HISTORIA DE MEXICO',
    'HISTORIA DE PAISES',
    'HISTORIA DEL ARTE',
    'HISTORIA MODERNA Y CONTEMPORANEA',
    'HISTORIA UNIVERSAL',
    'HISTORIA Y ESTUDIOS REGIONALES',
    'HISTORIA Y FILOSOFIA DEL DERECHO',
    'HISTORIA, CIENCIA Y TECNOLOGIA Y SOCIEDAD',
    'HISTORIA, EN GENERAL',
    'HISTORIOGRAFIA',
    'HORTICULTURA',
    'IMAGENOLOGIA,DIAGNOSTICA Y TERAPEUTICA',
    'INFECTOLOGIA',
    'INFECTOLOGIA PEDIATRICA',
    'INFECTOMICA Y PATOGENESIS MOLECULAR',
    'INFORMATICA ADMINISTRATIVA',
    'INFORMATICA TEORIA Y PRACTICA',
    'INGENIERIA  DE POLIMEROS Y PLASTICOS',
    'INGENIERIA  INDUSTRIAL Y MANUFACTURA',
    'INGENIERIA ADMINISTRATIVA',
    'INGENIERIA ADMINISTRATIVA',
    'INGENIERIA AEROESPACIAL Y AERONAUTICA',
    'INGENIERIA AERONAUTICA Y ESPACIAL',
    'INGENIERIA AGRICOLA',
    'INGENIERIA AGRICOLA',
    'INGENIERIA AMBIENTAL',
    'INGENIERIA AUTOMOTRIZ',
    'INGENIERIA BIOMEDICA',
    'INGENIERIA BIOQUIMICA',
    'INGENIERIA CERAMICA',
    'INGENIERIA CIVIL',
    'INGENIERIA DE CALIDAD Y PRODUCTIVIDAD',
    'INGENIERIA DE CORROSION',
    'INGENIERIA DE ESTRUCTURAS',
    'INGENIERIA DE INVERNADEROS',
    'INGENIERIA DE PETROLEO',
    'INGENIERIA DE PROCESOS BIOTECNOLOGICOS',
    'INGENIERIA DE SISTEMAS',
    'INGENIERIA DE SISTEMAS ENERGETICOS',
    'INGENIERIA DE SOFTWARE',
    'INGENIERIA DE VIAS TERRESTRES',
    'INGENIERIA ELECTRICA',
    'INGENIERIA ELECTRONICA',
    'INGENIERIA EN COMUNICACIONES',
    'INGENIERIA EN ENERGIA',
    'INGENIERIA EN EXPLORACION Y EXPLOTACION DE RECURSOS NATURALES',
    'INGENIERIA EN MICROELECTRONICA',
    'INGENIERIA EN SEGURIDAD Y TECNOLOGIAS DE LA INFORMACION',
    'INGENIERIA EN TELECOMUNICACIONES',
    'INGENIERIA ENERGETICA',
    'INGENIERIA FISICA',
    'INGENIERIA FORESTAL',
    'INGENIERIA FORESTAL',
    'INGENIERIA GENETICA',
    'INGENIERIA INDUSTRIAL',
    'INGENIERIA INFORMATICA',
    'INGENIERIA MARITIMA',
    'INGENIERIA MECANICA',
    'INGENIERIA MECATRONICA',
    'INGENIERIA METALURGIA',
    'INGENIERIA NUCLEAR',
    'INGENIERIA QUIMICA',
    'INGENIERIA TERMICA',
    'INGENIERIA Y ADMINISTRACION DE LA CONSTRUCCION',
    'INMUNOLOGIA',
    'INMUNOLOGIA',
    'INSTITUCIONES POLITICAS',
    'INSTRUCCION Y DISENO EN MEDIOS',
    'INSTRUMENTACION',
    'INTELIGENCIA ARTIFICIAL',
    'INVESTIGACION CLINICA',
    'INVESTIGACION DE OPERACIONES',
    'INVESTIGACION EN COMUNICACION',
    'INVESTIGACION MEDICA, LINEA TERMINAL BIOMEDICINA',
    'INVESTIGACION Y DESARROLLO EN CIENCIAS EDUCATIVAS',
    'JUSTICIA Y CORRECCIONES PENAL',
    'LETRAS',
    'LETRAS MODERNAS',
    'LIMNOLOGIA',
    'LINGUISTIC INDOAMERICANA',
    'LINGUISTICA',
    'LINGUISTICA APLICADA',
    'LINGUISTICA HISPANICA',
    'LINGUISTICA INDOAMERICANA',
    'LITERATURA',
    'LITERATURA HISPANICA',
    'LITERATURA HISPANOAMERICANA',
    'LITERATURA MEXICANA',
    'LITERATURA MEXICANA CONTEMPORANEA',
    'LITERATURA MEXICANA DEL SIGLO XX',
    'LITERATURA UNIVERSAL',
    'LOGICA',
    'LOGICA Y FUNDAMENTOS DE LAS MATEMATICAS',
    'LOGISTICA Y DIRECCION DE LA CADENA DE SUMINISTRO',
    'MANEJO AGROECOLOGICO DE PLAGAS Y ENFERMEDADES',
    'MANEJO COSTERO',
    'MANEJO DE RECURSOS MARINOS',
    'MANEJO DE VIDA SILVESTRE',
    'MANEJO SUSTENTABLE DE AGROECOSISTEMAS',
    'MANUFACTURA AVANZADA',
    'MATEMATICAS APLICADAS',
    'MEDICINA DE REHABILITACION',
    'MEDICINA DEL ENFERMO EN ESTADO CRITICO',
    'MEDICINA DEL ENFERMO PEDIATRICO EN ESTADO CRITICO',
    'MEDICINA FAMILIAR',
    'MEDICINA FISICA Y REHABILITACION, KINESIOLOGIA',
    'MEDICINA FORENSE',
    'MEDICINA INTERNA',
    'MEDICINA MATERNO FETAL',
    'MEDICINA NUCLEAR',
    'MEDICINA NUCLEAR, RADIOTERAPIA',
    'MEDICINA PALIATIVA Y DEL DOLOR',
    'MEDICINA VETERINARIA',
    'MEDIO INTERPLANETARIO',
    'MEDIOS DE COMUNICACION / CIENCIAS DE LA INFORMACION',
    'MERCADOTECNIA',
    'METAFISICA',
    'METEOROLOGIA',
    'METODOS NUMERICOS',
    'MICROBIOLOGIA',
    'MINERALOGIA Y PETROLOGIA',
    'MINERIA Y INGENIERIA DE MINAS',
    'MITIGACION Y ADAPTACION AL CAMBIO CLIMATICO',
    'MORFOLOGIA',
    'MORTALIDAD',
    'MUSICA',
    'NANOCIENCIAS',
    'NANOCIENCIAS Y NANOTECNOLOGIAS',
    'NEFROLOGIA',
    'NEFROLOGIA PEDIATRICA',
    'NEFROLOGIA, FISIOLOGIA RENAL',
    'NEGOCIOS INTERNACIONALES / COMERCIO',
    'NEONATOLOGIA',
    'NEUMOLOGIA',
    'NEUMOLOGIA PEDIATRICA',
    'NEUROANESTESIOLOGIA',
    'NEUROBIOLOGIA',
    'NEUROCIENCIAS',
    'NEUROCIRUGIA',
    'NEUROCIRUGIA PEDIATRICA',
    'NEUROFISIOLOGIA CLINICA',
    'NEUROLOGIA',
    'NEUROLOGIA PEDIATRICA',
    'NEUROLOGIA Y NEUROCIRUGIA',
    'NEUROPSICOLOGIA',
    'NEURORADIOLOGIA',
    'NUTRICION ANIMAL',
    'NUTRICION BASICA',
    'NUTRICION HUMANA',
    'NUTRICION VEGETAL, FERTILIZACION DE CULTIVOS',
    'NUTRIOLOGIA CLINICA',
    'OBESIDAD Y COMORBILIDADES',
    'OCEANOGRAFIA COSTERA',
    'OCEANOGRAFIA FISICA',
    'OCEANOGRAFIA QUIMICA',
    'ODONTOPEDIATRIA',
    'OFTALMOLOGIA',
    'OFTALMOLOGIA',
    'OFTALMOLOGIA NEUROLOGICA',
    'ONCOLOGIA',
    'ONCOLOGIA PEDIATRICA',
    'ONCOLOGIA, QUIMIOTERAPIA, CIRUGIA ONCOLOGIA',
    'OPINION PUBLICA',
    'OPTICA / FOTONICA',
    'OPTIMIZACION',
    'OPTOMECATRONICA',
    'ORGANIZACION JURIDICA',
    'ORTODONCIA',
    'ORTOPEDIA DENTOMAXILOFACIAL',
    'ORTOPEDIA Y TRAUMATOLOGIA',
    'OTORRINOLARINGOLOGIA',
    'OTORRINOLARINGOLOGIA PEDIATRICA',
    'OTORRINOLARINGOLOGIA Y CIRUGIA DE CABEZA Y CUELLO',
    'OTRAS ESPECIALIDADES POLITICAS',
    'PALEONTOLOGIA',
    'PARASITOLOGIA',
    'PATOLOGIA CLINICA',
    'PATOLOGIA HUMANA Y ANIMAL',
    'PATOLOGIA PEDIATRICA',
    'PATOLOGIA Y MEDICINA BUCAL',
    'PEDAGOGIA',
    'PEDIATRIA',
    'PERIODONCIA',
    'PESCA Y PISICICULTURA',
    'PLANEACION Y POLITICAS METROPOLITANAS',
    'PLANIFICACION Y DESARROLLO URBANO',
    'POLITICA FISCAL Y HACIENDA PUBLICA NACIONALES',
    'PROBLEMAS INTERNACIONALES',
    'PROBLEMAS SOCIALES',
    'PRODUCCION ANIMAL',
    'PRODUCCION ARTISTICA',
    'PRODUCCION Y TECNOLOGIA DE SEMILLAS',
    'PROPAGACION DE PLANTAS',
    'PROSTODONCIA',
    'PROTESIS BUCAL',
    'PROTESIS MAXILOFACIAL',
    'PROYECTOS Y EDIFICACION SUSTENTABLES',
    'PSICOLOGIA',
    'PSICOLOGIA CLINICA Y DE LA SALUD',
    'PSICOLOGIA DE LA EDUCACION',
    'PSICOLOGIA DE LA EDUCACION Y COGNITIVA',
    'PSICOLOGIA DE LA PERSONALIDAD',
    'PSICOLOGIA DEL DEPORTE',
    'PSICOLOGIA ESCOLAR',
    'PSICOLOGIA EXPERIMENTAL',
    'PSICOLOGIA LABORAL Y ORGANIZACIONAL',
    'PSICOLOGIA SOCIAL',
    'PSICOLOGIA SOCIAL DE GRUPOS E INSTITUCIONES',
    'PSICOMETRIA',
    'PSIQUIATRIA',
    'PSIQUIATRIA INFANTIL Y ADOLESCENCIA',
    'QUIMICA ANALITICA',
    'QUIMICA DE POLIMEROS',
    'QUIMICA DE RECURSOS NATURALES',
    'QUIMICA DE SUELOS / MICROBIOLOGIA',
    'QUIMICA DEL AMBIENTE',
    'QUIMICA FISICA',
    'QUIMICA GENERAL,',
    'QUIMICA INORGANICA',
    'QUIMICA NUCLEAR',
    'QUIMICA ORGANICA',
    'QUIMICA TEORICA',
    'RADIOASTRONOMIA',
    'RADIOLOGIA E IMAGEN',
    'RADIOLOGIA Y DIAGNOSTICO POR IMAGENES',
    'RADIOONCOLOGIA',
    'RECURSOS ACUATICOS',
    'RECURSOS GENETICOS Y PRODUCTIVIDAD',
    'RECURSOS NATURALES Y MEDIO AMBIENTE EN ZONAS ARIDAS',
    'RELACIONES INTERNACIONALES',
    'REPRODUCCION ANIMAL',
    'RESTAURACION DE SITIOS Y MONUMENTOS',
    'RESTAURACION ECOLOGICA',
    'REUMATOLOGIA',
    'REUMATOLOGIA PEDIATRICA',
    'REUMATOLOGIA, INFLAMACION, ENFERMEDADES AUTOINMUNES',
    'ROBOTICA',
    'SALUD AMBIENTAL',
    'SALUD PUBLICA',
    'SEGURIDAD ALIMENTARIA Y NUTRICIONAL',
    'SEGURIDAD HIDRICA',
    'SEMICONDUCTORES',
    'SERVICIOS FINANCIEROS',
    'SILVICULTURA Y MANEJO DE BOSQUES',
    'SIMULACION Y OPTIMIZACION',
    'SISTEMAS DINAMICOS',
    'SISTEMAS EMBEBIDOS',
    'SISTEMAS INTELIGENTES MULTIMEDIA',
    'SISTEMAS NO LINEALES',
    'SISTEMAS POLITICOS',
    'SISTEMAS Y AMBIENTES EDUCATIVOS',
    'SOCIOLOGIA CULTURAL',
    'SOCIOLOGIA DE LOS ASENTAMIENTOS HUMANOS',
    'SOCIOLOGIA DEL TRABAJO',
    'SOCIOLOGIA EXPERIMENTAL',
    'SOCIOLOGIA POLITICA',
    'SOCIOLOGIA POLITICA',
    'SOCIOLOGIA RURAL',
    'TECNOLOGIA DE LA MADERA',
    'TECNOLOGIA DE LA SOLDADURA INDUSTRIAL',
    'TECNOLOGIA DEL TRANSPORTE',
    'TECNOLOGIA EDUCATIVA',
    'TECNOLOGIAS PARA EL APRENDIZAJE',
    'TEORIA DE LA ADMINISTRACION',
    'TEORIA DE LA COMUNICACION',
    'TEORIA DE LOS NUMEROS',
    'TEORIA DEL CONOCIMIENTO',
    'TEORIA POLITICA',
    'TERAPIA ENDOVASCULAR',
    'TERMOFLUIDOS',
    'TOPOLOGIA',
    'TOXICOLOGIA',
    'TRADUCCION E INTERPRETACION',
    'TRAUMATOLOGIA Y ORTOPEDIA',
    'URBANISMO / CIUDAD, COMUNIDAD Y ORDENACION DEL TERRITORIO',
    'URGENCIAS MEDICAS',
    'URGENCIAS PEDIATRICAS',
    'UROLOGIA',
    'UROLOGIA GINECOLOGICA',
    'UROLOGIA, TRANSPLANTE RENAL, FISIOLOGIA UROGENITAL',
    'VALUACION INMOBILIARIA',
    'VIROLOGIA',
    'ZOOLOGIA',
    'ZOOTECNIA Y CIENCIAS PECUARIAS',
  ];
  static TIPOS_BENEFICIARIOS = [
    { value: null, text: 'seleccione una opción', disabled: true },
    { value: 'EST', text: 'Estatal' },
    { value: 'FED', text: 'Federal' },
    { value: 'MUN', text: 'Municipal' },
    { value: 'EMP', text: 'Empresa' },
    { value: 'IPNL', text: 'Institución Privada no lucrativa' },
    { value: 'IE', text: 'Institución de Educación' },
    { value: 'CI', text: 'Centro de Investigación' },
  ];
  static TIPO_EVENTOS = [
    { value: null, text: 'Seleccione tipo de evento', disabled: true },
    { value: 'PRO', text: 'Prórroga' },
    { value: 'RUB', text: 'Cambio de rubro' },
    { value: 'INV', text: 'Agregar investigadores' },
  ];
}

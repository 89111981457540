var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.editMode || _vm.createMode
            ? _c(
                "b-card",
                { attrs: { "border-variant": "danger" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "12" } }, [
                        _vm.createMode
                          ? _c("header", { staticClass: "bx-header-title" }, [
                              _c("h3", [
                                _c(
                                  "span",
                                  { attrs: { id: "proyecto-create-heading" } },
                                  [_vm._v("Registrar evento")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "header-title-line" }),
                            ])
                          : _c("header", { staticClass: "bx-header-title" }, [
                              _c("h3", [
                                _c(
                                  "span",
                                  { attrs: { id: "proyecto-update-heading" } },
                                  [_vm._v("Actualizar evento")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "header-title-line" }),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-numero-evento",
                                label: "Número de evento",
                                "label-for": "input-numero-evento",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      size: "sm",
                                      id: "input-numero-evento",
                                      state: _vm.evento.numeroEvento != null,
                                      placeholder: "Ingrese número de evento",
                                    },
                                    model: {
                                      value: _vm.evento.numeroEvento,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evento,
                                          "numeroEvento",
                                          $$v
                                        )
                                      },
                                      expression: "evento.numeroEvento",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-tipo-evento",
                                label: "Tipo de evento",
                                "label-for": "input-tipo-evento",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      size: "sm",
                                      id: "input-tipo-evento",
                                      state: _vm.evento.tipoEvento != null,
                                      placeholder: "Seleccione tipo de evento",
                                      options: _vm.tipoEventosOptions,
                                    },
                                    model: {
                                      value: _vm.evento.tipoEvento,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.evento, "tipoEvento", $$v)
                                      },
                                      expression: "evento.tipoEvento",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-detalle-evento",
                                label: "Detalle de evento",
                                size: "sm",
                                "label-for": "input-detalle-evento",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "input-detalle-evento",
                                      state: _vm.evento.detalleEvento != null,
                                      placeholder: "Agregar detalle de evento",
                                      rows: "4",
                                      "no-resize": "",
                                      "no-auto-shrink": "",
                                    },
                                    model: {
                                      value: _vm.evento.detalleEvento,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evento,
                                          "detalleEvento",
                                          $$v
                                        )
                                      },
                                      expression: "evento.detalleEvento",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "form-group-fecha-registo",
                                label: "Fecha de registro",
                                "label-for": "input-fecha-registro",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      "value-as-date": "",
                                      id: "input-fecha-registro",
                                      placeholder: "Ingrese fecha de registro",
                                      state: _vm.evento.fechaRegistro != null,
                                      locale: "es",
                                    },
                                    model: {
                                      value: _vm.evento.fechaRegistro,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.evento,
                                          "fechaRegistro",
                                          $$v
                                        )
                                      },
                                      expression: "evento.fechaRegistro",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancel()
                                },
                              },
                            },
                            [_c("span", [_vm._v("Cancelar")])]
                          ),
                          _vm._v(" "),
                          _vm.createMode
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.add },
                                },
                                [_c("span", [_vm._v("Guardar")])]
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.update },
                                },
                                [_c("span", [_vm._v("Actualizar")])]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          !_vm.editMode && !_vm.createMode
            ? _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-12 text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right ml-2 text-right",
                              attrs: { variant: "primary" },
                              on: { click: _vm.prepareAdd },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "plus" } }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Registrar evento")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.cargaManualDocumento",
                                  modifiers: { cargaManualDocumento: true },
                                },
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              staticClass: "float-right ml-2 text-right",
                              attrs: { variant: "primary" },
                              on: { click: _vm.showCargaManualDocumento },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "file-earmark-arrow-up" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Adjuntar documento")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-table", {
                        staticClass: "text-center",
                        attrs: {
                          id: "table-eventos-id",
                          "head-variant": "",
                          "sticky-header": "",
                          striped: "",
                          responsive: "",
                          emptyText: "No tienes registros",
                          items: _vm.items,
                          fields: _vm.fields,
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "head(numeroEvento)",
                              fn: function () {
                                return [_c("div", [_vm._v("Número de evento")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(tipoEvento)",
                              fn: function () {
                                return [_c("div", [_vm._v("Tipo de evento")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(detalleEvento)",
                              fn: function () {
                                return [
                                  _c("div", [_vm._v("Detalle de evento")]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(fechaRegistro)",
                              fn: function () {
                                return [
                                  _c("div", [_vm._v("Fecha de registro")]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "head(acciones)",
                              fn: function () {
                                return [_c("div", [_vm._v(" ")])]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(numeroEvento)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(_vm._s(row.item.numeroEvento)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(tipoEvento)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      row.item.tipoEvento == "PRO"
                                        ? _c("small", [_vm._v("Prórroga")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.item.tipoEvento == "RUB"
                                        ? _c("small", [
                                            _vm._v("Cambio de rubro"),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.item.tipoEvento == "INV"
                                        ? _c("small", [
                                            _vm._v("Agregar investigadores"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(detalleEvento)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(_vm._s(row.item.detalleEvento)),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(fechaRegistro)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "m-1 text-center rounded",
                                      attrs: { badge: "BV" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              row.item.fechaRegistro
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(acciones)",
                              fn: function (row) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.prepareEdit(row.index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "pencil",
                                          "aria-hidden": "true",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(row.index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "trash-fill",
                                          "aria-hidden": "true",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          55582942
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "cargaManualDocumento",
          attrs: {
            size: "lg",
            scrollable: "",
            centered: "",
            "header-bg-variant": "primary",
            "header-text-variant": "white",
            id: "cargaManualDocumento",
          },
          on: { shown: _vm.modalLoaded },
        },
        [
          _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
            _c("span", {
              attrs: { id: "carga-manual-documento-pago-heading" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.proyectosMsCargaManualDocumento")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("carga-manual-documento", {
                ref: "componentCargaManualDocumento",
                attrs: { etapas: _vm.etapas },
                model: {
                  value: _vm.cargaManualDocumento,
                  callback: function ($$v) {
                    _vm.cargaManualDocumento = $$v
                  },
                  expression: "cargaManualDocumento",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.hideCargaManualDocumento()
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "x-circle" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Cancelar")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: _vm.uploadingState,
                    disabled:
                      _vm.$v.cargaManualDocumento.$invalid || _vm.isSavingFile,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.uploadDocumento()
                    },
                  },
                },
                [
                  _vm.isSavingFile
                    ? _c("b-icon", {
                        attrs: {
                          animation: "cylon-vertical",
                          icon: "cloud-upload-fill",
                        },
                      })
                    : _c("b-icon", { attrs: { icon: "cloud-upload-fill" } }),
                  _vm._v(" "),
                  _vm.isSavingFile
                    ? _c("span", [_c("em", [_vm._v("Cargando Archivo")])])
                    : _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cargaManualDocumento.cargar")
                            ),
                          },
                        },
                        [_vm._v("Subir")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
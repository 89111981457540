var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "b-form-group",
          {
            attrs: {
              id: "-modal-proyecto-etapa",
              label: "Etapa",
              "label-for": "input-etapa",
            },
          },
          [
            _c("b-form-select", {
              attrs: {
                options: _vm.etapas,
                "value-field": "id",
                "text-field": "nombre",
                size: "sm",
                state: _vm.$v.documento.etapa.$dirty
                  ? !_vm.$v.documento.etapa.$error
                  : null,
                validationsCommons: {
                  requiredValue: !_vm.$v.documento.etapa.required,
                  requiredMessage: _vm.$t(
                    "cargaManualDocumento.errors.etapaRequerido"
                  ),
                },
              },
              on: { change: _vm.seleccionEtapa },
              scopedSlots: _vm._u([
                {
                  key: "first",
                  fn: function () {
                    return [
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "", disabled: "" } },
                        [_vm._v("-- Selecciona una opción --")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.$v.documento.etapa.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.$v.documento.etapa, "$model", $$v)
                },
                expression: "$v.documento.etapa.$model",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "b-form-group",
          {
            attrs: {
              id: "-modal-proyecto-tipo-documento",
              label: "Tipo de documento",
              "label-for": "input-tipo-documento",
            },
          },
          [
            _c("b-form-select", {
              attrs: {
                options: _vm.tipoDocumentos,
                "value-field": "id",
                "text-field": "nombre",
                size: "sm",
                state: _vm.$v.documento.tipoDocumento.$dirty
                  ? !_vm.$v.documento.tipoDocumento.$error
                  : null,
                validationsCommons: {
                  requiredValue: !_vm.$v.documento.tipoDocumento.required,
                  requiredMessage: _vm.$t(
                    "cargaManualDocumento.errors.tipoDocumentoRequerido"
                  ),
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "first",
                  fn: function () {
                    return [
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "null", disabled: "" } },
                        [_vm._v("-- Selecciona una opción --")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.$v.documento.tipoDocumento.$model,
                callback: function ($$v) {
                  _vm.$set(_vm.$v.documento.tipoDocumento, "$model", $$v)
                },
                expression: "$v.documento.tipoDocumento.$model",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        {
          staticClass: "form-control-label",
          attrs: { for: "label-archivo" },
          domProps: {
            textContent: _vm._s(_vm.$t("cargaManualDocumento.archivo")),
          },
        },
        [_vm._v("Archivo")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("b-form-file", {
            ref: "fileInput",
            attrs: {
              id: "model-cargaManual-documento-archivo",
              readonly: false,
              required: true,
              placeholder: "Selecciona o arrastra un documento",
              "drop-placeholder": "Suelte el archivo aquí...",
              state: _vm.$v.documento.archivo.$dirty
                ? !_vm.$v.documento.archivo.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.documento.archivo.required,
                requiredMessage: _vm.$t(
                  "cargaManualDocumento.errors.archivoRequerido"
                ),
              },
            },
            on: { change: _vm.fileChanged },
            model: {
              value: _vm.$v.documento.archivo.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.documento.archivo, "$model", $$v)
              },
              expression: "$v.documento.archivo.$model",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export interface IFiltro {
  cvu?: string | null;
  nombre?: string | null;
  programa?: string | null;
  metodologias?: [] | null;
  grados?: [] | null;
  orientaciones?: [] | null;
  niveles?: [] | null;
  areas?: [] | null;
  campos?: [] | null;
  disciplinas?: [] | null;
  entidades?: [] | null;
  tipoInstituciones?: [] | null;
  estadoSolicitudes?: [] | null;
  dictamenes?: string | null;
  acreditadoSnp?: boolean | null;
  tiempoDedicacion?: string | null;
  periodicidad?: string | null;
  numeroBecasHistorico?: number | null;
  grupo?: string | null;
}

export class Filtro implements IFiltro {
  constructor(
    public cvu?: string | null,
    public nombre?: string | null,
    public programa?: string | null,
    public metodologias?: [] | null,
    public grados?: [] | null,
    public orientaciones?: [] | null,
    public niveles?: [] | null,
    public areas?: [] | null,
    public campos?: [] | null,
    public disciplinas?: [] | null,
    public entidades?: [] | null,
    public tipoInstituciones?: [] | null,
    public estadoSolicitudes?: [] | null,
    public dictamenes?: string | null,
    public acreditadoSnp?: boolean | null,
    public tiempoDedicacion?: string | null,
    public periodicidad?: string | null,
    public numeroBecasHistorico?: number | null,
    public grupo?: string | null
  ) {
    this.metodologias = this.metodologias ? this.metodologias : [];
    this.grados = this.grados ? this.grados : [];
    this.orientaciones = this.orientaciones ? this.orientaciones : [];
    this.niveles = this.niveles ? this.niveles : [];
    this.areas = this.areas ? this.areas : [];
    this.campos = this.campos ? this.campos : [];
    this.disciplinas = this.disciplinas ? this.disciplinas : [];
    this.entidades = this.entidades ? this.entidades : [];
    this.tipoInstituciones = this.tipoInstituciones ? this.tipoInstituciones : [];
    this.estadoSolicitudes = this.estadoSolicitudes ? this.estadoSolicitudes : [];
  }
}

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Constants } from '@/shared/util/constants';
import { ProyectoFiltro } from '@/shared/model/proyectosMs/proyecto-filtro.model';

@Component
export default class ProgramaFilterComponent extends Vue {
  @Prop({ required: true })
  public value!: ProyectoFiltro;

  public areas = ['DAMCAD', 'DAI'];
  public fondos = Constants.FONDOS;
  public estatus = Constants.ESTATUS;
  public etapas = Constants.ETAPAS;
  public entidadesFederativasOptions = Constants.ENTIDADES_FEDERATIVAS;

  get filtro(): ProyectoFiltro {
    return this.value;
  }

  set filtro(newValue: ProyectoFiltro) {
    this.$emit('input', newValue);
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-car-id",
                label: "Número de CAR",
                "label-for": "input-num-car-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-num-car-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.numCar != null &&
                    _vm.proyecto.numCar.length > 0,
                  placeholder: "Número de CAR",
                },
                model: {
                  value: _vm.proyecto.numCar,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "numCar", $$v)
                  },
                  expression: "proyecto.numCar",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-tipo-car-id",
                label: "Tipo CAR",
                "label-for": "input-tipo-car-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.tipoCarOptions,
                  state:
                    _vm.proyecto.tipoCar != null &&
                    _vm.proyecto.tipoCar.length > 0,
                  placeholder: "Tipo de CAR (físico-electrónico)",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.tipoCar,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "tipoCar", $$v)
                  },
                  expression: "proyecto.tipoCar",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-asignacion-car-id",
                label: "Fecha de asignación de número de CAR",
                "label-for": "input-fecha-asignacion-car-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t(
                                      "distribucionGwApp.proyectosMsProyecto.adjuntarDocumentoAcuerdo"
                                    ),
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-asignacion-car-id",
                          placeholder: "Fecha de asignación de número de CAR",
                          state: _vm.proyecto.fechaAsignacionCar != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaAsignacionCar,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "fechaAsignacionCar", $$v)
                          },
                          expression: "proyecto.fechaAsignacionCar",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-liberacion-id",
                label: "Fecha liberación CAR",
                "label-for": "input-fecha-liberacion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t(
                                      "distribucionGwApp.proyectosMsProyecto.adjuntarDocumentoAcuerdo"
                                    ),
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-liberacion-id",
                          placeholder: "Fecha liberación CAR",
                          state: _vm.proyecto.fechaLiberacion != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaLiberacion,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "fechaLiberacion", $$v)
                          },
                          expression: "proyecto.fechaLiberacion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-vobo-auj-id",
                label: "Fecha de vo.bo. de la UAJ",
                "label-for": "input-fecha-vobo-auj-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t(
                                      "distribucionGwApp.proyectosMsProyecto.adjuntarDocumentoAcuerdo"
                                    ),
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-vobo-auj-id",
                          placeholder: "Fecha de vo.bo. de la UAJ",
                          state: _vm.proyecto.fechaVoboAuj != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaVoboAuj,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "fechaVoboAuj", $$v)
                          },
                          expression: "proyecto.fechaVoboAuj",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-vobo-solicitante-id",
                label: "Fecha de vo.bo. del proponente",
                "label-for": "input-fecha-vobo-solicitante-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t(
                                      "distribucionGwApp.proyectosMsProyecto.adjuntarDocumentoAcuerdo"
                                    ),
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-vobo-solicitante-id",
                          placeholder: "Fecha de vo.bo. del proponente",
                          state: _vm.proyecto.fechaVoboSolicitante != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaVoboSolicitante,
                          callback: function ($$v) {
                            _vm.$set(_vm.proyecto, "fechaVoboSolicitante", $$v)
                          },
                          expression: "proyecto.fechaVoboSolicitante",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-firma-rep-legal-id",
                label: "Fecha de firma del Representante Legal",
                "label-for": "input-fecha-firma-rep-legal-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-firma-rep-legal-id",
                      placeholder: "Fecha de firma del Representante Legal",
                      state: _vm.proyecto.fechaFirmaRepLegal != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaFirmaRepLegal,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaFirmaRepLegal", $$v)
                      },
                      expression: "proyecto.fechaFirmaRepLegal",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-firma-resp-administrativo-id",
                label: "Fecha de firma del Responsable Administrativo",
                "label-for": "input-fecha-firma-resp-administrativo-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-firma-resp-administrativo-id",
                      placeholder:
                        "Fecha de firma del Responsable Administrativo",
                      state: _vm.proyecto.fechaFirmaRespAdministrativo != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaFirmaRespAdministrativo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "fechaFirmaRespAdministrativo",
                          $$v
                        )
                      },
                      expression: "proyecto.fechaFirmaRespAdministrativo",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-firma-resp-tecnico-id",
                label: "Fecha de firma del Responsable Técnico",
                "label-for": "input-fecha-firma-resp-tecnico-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-firma-resp-tecnico-id",
                      placeholder: "Fecha de firma del Responsable Técnico",
                      state: _vm.proyecto.fechaFirmaRespTecnico != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaFirmaRespTecnico,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaFirmaRespTecnico", $$v)
                      },
                      expression: "proyecto.fechaFirmaRespTecnico",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-frrma-sec-administrativo-id",
                label: "Fecha de firma de Secretario Administrativo",
                "label-for": "input-fecha-frrma-sec-administrativo-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-frrma-sec-administrativo-id",
                      placeholder:
                        "Fecha de firma de Secretario Administrativo",
                      state: _vm.proyecto.fechaFrrmaSecAdministrativo != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaFrrmaSecAdministrativo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "fechaFrrmaSecAdministrativo",
                          $$v
                        )
                      },
                      expression: "proyecto.fechaFrrmaSecAdministrativo",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-firma-sec-tecnico-id",
                label: "Fecha firma Secretario Técnico",
                "label-for": "input-fecha-firma-sec-tecnico-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-firma-sec-tecnico-id",
                      placeholder: "Fecha firma Secretario Técnico",
                      state: _vm.proyecto.fechaFirmaSecTecnico != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaFirmaSecTecnico,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaFirmaSecTecnico", $$v)
                      },
                      expression: "proyecto.fechaFirmaSecTecnico",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-1", attrs: { "no-body": "" } },
    [
      _c("b-card-body", [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-fondo" },
            },
            [
              _vm._v(
                "Fondo o Programa presupuestario de origen de los proyectos"
              ),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.fondo,
                expression: "filtro.fondo",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "fondo",
              id: "criterio-fondo",
              "data-cy": "fondo",
            },
            domProps: { value: _vm.filtro.fondo },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "fondo", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-clave" },
            },
            [_vm._v("Clave del proyecto")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.clave,
                expression: "filtro.clave",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "clave",
              id: "criterio-clave",
              "data-cy": "clave",
            },
            domProps: { value: _vm.filtro.clave },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "clave", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-titulo" },
            },
            [_vm._v("Título de proyecto")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.titulo,
                expression: "filtro.titulo",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "titulo",
              id: "criterio-titulo",
              "data-cy": "titulo",
            },
            domProps: { value: _vm.filtro.titulo },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "titulo", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-dirAdjuntaUnidad" },
            },
            [_vm._v("Dirección adjunta - Unidad")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.dirAdjuntaUnidad,
                expression: "filtro.dirAdjuntaUnidad",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "dirAdjuntaUnidad",
              id: "criterio-dirAdjuntaUnidad",
              "data-cy": "dirAdjuntaUnidad",
            },
            domProps: { value: _vm.filtro.dirAdjuntaUnidad },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "dirAdjuntaUnidad", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-areaRespConvocatoria" },
            },
            [_vm._v("Dirección de Área responsable de convocatoria")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.areaRespConvocatoria,
                expression: "filtro.areaRespConvocatoria",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "areaRespConvocatoria",
              id: "criterio-areaRespConvocatoria",
              "data-cy": "areaRespConvocatoria",
            },
            domProps: { value: _vm.filtro.areaRespConvocatoria },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.filtro,
                  "areaRespConvocatoria",
                  $event.target.value
                )
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-estatus" },
              },
              [_vm._v("Entidad federativa del beneficiario")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.entidadesFederativasOptions },
              model: {
                value: _vm.filtro.entFederativaBeneficiario,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "entFederativaBeneficiario", $$v)
                },
                expression: "filtro.entFederativaBeneficiario",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-ejeRector" },
            },
            [_vm._v("Eje programático")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.ejeRector,
                expression: "filtro.ejeRector",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "ejeRector",
              id: "criterio-ejeRector",
              "data-cy": "ejeRector",
            },
            domProps: { value: _vm.filtro.ejeRector },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "ejeRector", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-convocatoria" },
            },
            [_vm._v("Nombre de la convocatoria")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.convocatoria,
                expression: "filtro.convocatoria",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "nombre",
              id: "criterio-convocatoria",
              "data-cy": "convocatoria",
            },
            domProps: { value: _vm.filtro.convocatoria },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "convocatoria", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-anno" },
            },
            [_vm._v("Año")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.anno,
                expression: "filtro.anno",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "number",
              min: "1900",
              name: "anno",
              id: "criterio-anno",
              "data-cy": "anno",
            },
            domProps: { value: _vm.filtro.anno },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "anno", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-estatus" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.proyectosMsProyecto.etapa")
                  ),
                },
              },
              [_vm._v("Estados")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.etapas },
              model: {
                value: _vm.filtro.etapas,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "etapas", $$v)
                },
                expression: "filtro.etapas",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-estatus" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.proyectosMsProyecto.estatus")
                  ),
                },
              },
              [_vm._v("Estados")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.estatus },
              model: {
                value: _vm.filtro.estatus,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "estatus", $$v)
                },
                expression: "filtro.estatus",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
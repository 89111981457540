import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { mapGetters } from 'vuex';
import { CargaManualDocumento } from '@/shared/model/proyectosMs/documento-carga-manual.model';

import AlertService from '@/shared/alert/alert.service';
import { required } from 'vuelidate/lib/validators';
import { IEtapa } from '@/shared/model/proyectosMs/etapa.model';

const validations: any = {
  documento: {
    tipoDocumento: {
      required,
    },
    etapa: {
      required,
    },
    archivo: {
      required,
    },
  },
};
@Component({
  mixins: [Vue2Filters.mixin],
  computed: {
    ...mapGetters(['account']),
  },
  validations,
})
export default class CargaManualDocumentoComponent extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  public currentLanguage = 'es';

  @Prop({ required: true })
  public value!: CargaManualDocumento;

  @Prop({ required: true })
  public etapas!: IEtapa[];

  public tipoDocumentos = [];

  get documento(): CargaManualDocumento {
    return this.value;
  }

  set documento(documento: CargaManualDocumento) {
    this.$emit('input', documento);
  }

  public seleccionEtapa(etapaId) {
    const itemEtapa = this.etapas.find(item => item.id === etapaId);
    this.tipoDocumentos = itemEtapa.tipoDocumentos;
    this.documento.tipoDocumento = undefined;
  }

  public clearFile(): void {
    this.documento.archivo = undefined;
    this.$refs.fileInput = null;
  }

  public resetFile(): void {
    this.$refs.fileInput = null;
    this.$v.$reset();
  }

  public fileChanged(event): void {
    const file = event.target.files[0];
    let isError = false;
    let message = '';
    if (!file) {
      this.documento.archivo = undefined;
      return;
    }

    if (file.size <= 0) {
      isError = true;
      message = this.$t('cargaManualDocumento.errors.vacio').toString();
    } else {
      this.documento.archivo = file;
    }
    if (isError) {
      this.clearFile();
      this.alertService().showError(this, message);
    }
  }
}

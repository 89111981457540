export interface IEvento {
  numeroEvento?: string | null;
  tipoEvento?: string | null;
  detalleEvento?: string | null;
  fechaRegistro?: Date | null;
}

export class Evento implements IEvento {
  constructor(
    public numeroEvento?: string | null,
    public tipoEvento?: string | null,
    public detalleEvento?: string | null,
    public fechaRegistro?: Date | null
  ) {}
}

import { mapGetters } from 'vuex';
import { Component, Vue, Inject, Watch } from 'vue-property-decorator';
import TareaService from '@/entities/distribucionMs/tarea/tarea.service';
import AlertService from '@/shared/alert/alert.service';
import TranslationService from '@/locale/translation.service';
@Component({
  computed: {
    ...mapGetters(['account', 'totalItemProyectoFiltering']),
  },
})
export default class DataUserComponent extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('tareaService') private tareaService: () => TareaService;
  @Inject('alertService') private alertService: () => AlertService;

  public dashboard = {};
  public proyectosQueryCount = 0;

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  @Watch('authenticated')
  nameChanged(isAuthenticated: boolean) {
    if (isAuthenticated) {
      // this.retrieveAllTareas();
    }
  }

  @Watch('totalItemProyectoFiltering')
  totalItemProyectoChanged(totalItemProyecto: number) {
    this.proyectosQueryCount = totalItemProyecto;
  }
  public retrieveAllTareas(): void {
    this.tareaService()
      .getDashboard()
      .then(
        res => {
          this.dashboard = res;
          localStorage.setItem('dashboard', JSON.stringify(res));
          this.dashboard = JSON.parse(localStorage.getItem('dashboard'));
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }
}

import BootstrapVue, {
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BProgress,
  BProgressBar,
  BPagination,
  BButton,
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BCollapse,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BAlert,
  BModal,
  VBModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormTags,
  BFormTag,
  BFormSelect,
  BTable,
  BTableSimple,
  BTr,
  BTd,
  BThead,
  BTh,
  BTbody,
  BSpinner,
  BCard,
  BFormSelectOption,
  VBToggle,
  BSidebar,
  BIcon,
  VBHover,
  BootstrapVueIcons,
  BTooltip,
  PopoverPlugin,
  BRow,
  BCol,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue';

import BEditableTable from 'bootstrap-vue-editable-table';

export function initBootstrapVue(vue) {
  vue.component('b-badge', BBadge);
  vue.component('b-dropdown', BDropdown);
  vue.component('b-dropdown-item', BDropdownItem);
  vue.component('b-link', BLink);
  vue.component('b-alert', BAlert);
  vue.component('b-modal', BModal);
  vue.component('b-button', BButton);
  vue.component('b-navbar', BNavbar);
  vue.component('b-navbar-nav', BNavbarNav);
  vue.component('b-navbar-brand', BNavbarBrand);
  vue.component('b-navbar-toggle', BNavbarToggle);
  vue.component('b-pagination', BPagination);
  vue.component('b-progress', BProgress);
  vue.component('b-progress-bar', BProgressBar);
  vue.component('b-form', BForm);
  vue.component('b-form-input', BFormInput);
  vue.component('b-form-textarea', BFormTextarea);
  vue.component('b-form-group', BFormGroup);
  vue.component('b-form-checkbox', BFormCheckbox);
  vue.component('b-collapse', BCollapse);
  vue.component('b-nav-item', BNavItem);
  vue.component('b-nav-item-dropdown', BNavItemDropdown);
  vue.component('b-modal', BModal);
  vue.directive('b-modal', VBModal);
  vue.component('b-form-datepicker', BFormDatepicker);
  vue.component('b-input-group', BInputGroup);
  vue.component('b-input-group-prepend', BInputGroupPrepend);
  vue.component('b-form-tags', BFormTags);
  vue.component('b-form-tag', BFormTag);
  vue.component('b-form-select', BFormSelect);
  vue.component('b-table', BTable);
  vue.component('b-table-simple', BTableSimple);
  vue.component('b-tr', BTr);
  vue.component('b-td', BTd);
  vue.component('b-th', BThead);
  vue.component('b-th', BTh);
  vue.component('b-tbody', BTbody);
  vue.component('b-spinner', BSpinner);
  vue.component('b-card', BCard);
  vue.component('b-form-select-option', BFormSelectOption);
  vue.directive('b-toggle', VBToggle);
  vue.component('b-sidebar', BSidebar);
  vue.component('b-icon', BIcon);
  vue.component('b-row', BRow);
  vue.component('b-col', BCol);
  vue.component('b-editable-table', BEditableTable);
  vue.directive('b-hover', VBHover);
  vue.directive('b-overlay', BOverlay);
  vue.use(BootstrapVue);
  vue.use(BootstrapVueIcons);
  vue.component('b-tooltip', BTooltip);
  vue.use(PopoverPlugin);
}

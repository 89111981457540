import { IMinistracion } from '@/shared/model/proyectosMs/ministracion.model';
import { IComentarioPanel } from '@/shared/model/proyectosMs/comentario-panel.model';
import { IEvento } from './evento.model';

export interface IProyecto {
  id?: string;
  cveFondoOrigen?: string | null;
  fondoOrigen?: string | null;
  partidaPresupuestal?: string | null;
  fondoConvocatoria?: string | null;
  modalidadApoyo?: string | null;
  objetivoConvocatoria?: string | null;
  numSolicitud?: string | null;
  cveProyecto?: string | null;
  tituloProyecto?: string | null;
  objetivoProgramaDondeIncide?: string | null;
  objetivoProyecto?: string | null;
  dirAdjuntaUnidad?: string | null;
  dirAreaRespConvocatoria?: string | null;
  nombreBeneficiario?: string | null;
  nombreRespAdministrativo?: string | null;
  nombreRespTecnico?: string | null;
  nombreRespLegal?: string | null;
  entFederativaBeneficiario?: string | null;
  entFederativaProyoecto?: string | null;
  rfc?: string | null;
  modalidad?: string | null;
  tipoBeneficiario?: string | null;
  areaConocimiento?: string | null;
  ejeRector?: string | null;
  descProyecto?: string | null;
  institucionesParticipantes?: string | null;
  secretariaTecnicaResp?: string | null;
  cveConvocatoria?: string | null;
  nombreConvocatoria?: string | null;
  fechaAprobacionJuridica?: Date | null;
  fechaAprobacionCta?: Date | null;
  fechaPublicacion?: Date | null;
  numSesionCtaConvocatoria?: string | null;
  fechaApertura?: Date | null;
  fechaCierreConvocatoria?: Date | null;
  reniecytSolicitud?: string | null;
  cvuSolicitante?: string | null;
  nombreSolicitante?: string | null;
  fechaCartaAutorizacion?: Date | null;
  fechaEntregaSolicitud?: Date | null;
  fechaRevision?: Date | null;
  fechaEnvioNotificacion?: Date | null;
  fechaResolucion?: Date | null;
  nombreEvaluador?: string | null;
  fechaNotificacionEvaluador?: Date | null;
  fechaEnvioComentarios?: Date | null;
  fechaAtencionComentarios?: Date | null;
  fechaRevisionFinanciera?: Date | null;
  fechaAtencionAjustesFinancieros?: Date | null;
  dictamenEvaluacion?: string | null;
  fechaDictamenEvaluacion?: Date | null;
  fechaRegistroEvaluacion?: Date | null;
  dictamenFinal?: string | null;
  fechaEmisionDictamen?: Date | null;
  fechaLiberacionActa?: Date | null;
  fechaEnvioDocumentos?: Date | null;
  montoTotalSolicitado?: number | null;
  montoTotalPresentado?: number | null;
  montoTotalAprueba?: number | null;
  montoApoyoDesglosado?: number | null;
  anoFiscalReportado?: number | null;
  montoAutorizadoAnoFiscal?: number | null;
  duracionProyecto?: string | null;
  tipoProyecto?: string | null;
  numEtapas?: number | null;
  numAcuerdoAprobacion?: string | null;
  numSesionCtaAprobacion?: string | null;
  fechaAcuerdoAprobacion?: Date | null;
  fechaPublicacionResultados?: Date | null;
  fechaSancionCar?: Date | null;
  numAcuerdoCtaCantelacion?: string | null;
  fechaAcuerdoCtaCancelacion?: Date | null;
  fechaNotificacionBeneficiarioCan?: Date | null;
  fechaAsignacionCar?: Date | null;
  numCar?: string | null;
  fechaLiberacion?: Date | null;
  tipoCar?: string | null;
  fechaVoboAuj?: Date | null;
  fechaVoboSolicitante?: Date | null;
  fechaFirmaRepLegal?: Date | null;
  fechaFirmaRespAdministrativo?: Date | null;
  fechaFirmaRespTecnico?: Date | null;
  fechaFrrmaSecAdministrativo?: Date | null;
  fechaFirmaSecTecnico?: Date | null;
  fechaInicioProyCar?: Date | null;
  fechaTerminoProyCar?: Date | null;
  fechaEnvioNotifFinSt?: Date | null;
  fechaEnvioNotifFinSa?: Date | null;
  fechaRecepInfTecnico?: Date | null;
  fechaEnvioEvalInfTecnico?: Date[] | null;
  fechaNotificacionInfTecnico?: Date | null;
  fechaAtencionAjustesInfTecnico?: Date[] | null;
  fechaRecepDictamenInfTecnico?: Date[] | null;
  falloInformeTecnico?: string | null;
  fechaRecepcionInfFinancieroFinal?: Date | null;
  fechaEnvioNotificacionAjuste?: string | null;
  fechaAtencionAjustes?: string | null;
  montoReintegro?: string | null;
  fechaSolicitudReintegro?: Date | null;
  fechaReintegro?: Date | null;
  fechaRecepcionDictamen?: Date | null;
  falloInformeFinanciero?: string | null;
  tipoConclusion?: string | null;
  fechaDictamenAprobatorioInfFinanciero?: Date | null;
  fechaDictamenAprobatorioInfTecnico?: Date | null;
  fechaConstanciaConclusion?: Date | null;
  fechaEnvioAcuse?: Date | null;
  numSesionCtaConclusion?: string | null;
  fechaConocimientoEmisionConstancia?: Date | null;
  numAcuerdoCtaConclusion?: string | null;
  indicarEsTerminacionAnt?: string | null;
  fechaRecepcionInfTecnicoAnt?: Date | null;
  fechaAcuerdoCtaTerminacionAnt?: Date | null;
  fechaOpinionJuridicaTerminacionAnt?: Date | null;
  numSesionCtaTerminacion?: string | null;
  fechaNotificBenefTerminacionAnt?: Date | null;
  numAcuerdoCtaTerminacionAnt?: string | null;
  fechaOpinionJuridicaRescision?: Date | null;
  numSesionCtaRescision?: string | null;
  fechaAcuerdoCtaRescision?: Date | null;
  numAcuerdoCtaRescision?: string | null;
  numCausaRescision?: string | null;
  fechaNotificacionAcuerdoConclusion?: Date | null;
  etapa?: string | null;
  estatus?: string | null;
  ministraciones?: IMinistracion[] | null;
  comentarios?: IComentarioPanel[] | null;
  eventos?: IEvento[] | null;
}

export class Proyecto implements IProyecto {
  constructor(
    public id?: string,
    public cveFondoOrigen?: string | null,
    public fondoOrigen?: string | null,
    public partidaPresupuestal?: string | null,
    public fondoConvocatoria?: string | null,
    public modalidadApoyo?: string | null,
    public objetivoConvocatoria?: string | null,
    public numSolicitud?: string | null,
    public cveProyecto?: string | null,
    public tituloProyecto?: string | null,
    public objetivoProgramaDondeIncide?: string | null,
    public objetivoProyecto?: string | null,
    public dirAdjuntaUnidad?: string | null,
    public dirAreaRespConvocatoria?: string | null,
    public nombreBeneficiario?: string | null,
    public nombreRespAdministrativo?: string | null,
    public nombreRespTecnico?: string | null,
    public nombreRespLegal?: string | null,
    public entFederativaBeneficiario?: string | null,
    public entFederativaProyoecto?: string | null,
    public rfc?: string | null,
    public modalidad?: string | null,
    public tipoBeneficiario?: string | null,
    public areaConocimiento?: string | null,
    public ejeRector?: string | null,
    public descProyecto?: string | null,
    public institucionesParticipantes?: string | null,
    public secretariaTecnicaResp?: string | null,
    public cveConvocatoria?: string | null,
    public nombreConvocatoria?: string | null,
    public fechaAprobacionJuridica?: Date | null,
    public fechaAprobacionCta?: Date | null,
    public fechaPublicacion?: Date | null,
    public numSesionCtaConvocatoria?: string | null,
    public fechaApertura?: Date | null,
    public fechaCierreConvocatoria?: Date | null,
    public reniecytSolicitud?: string | null,
    public cvuSolicitante?: string | null,
    public nombreSolicitante?: string | null,
    public fechaCartaAutorizacion?: Date | null,
    public fechaEntregaSolicitud?: Date | null,
    public fechaRevision?: Date | null,
    public fechaEnvioNotificacion?: Date | null,
    public fechaResolucion?: Date | null,
    public nombreEvaluador?: string | null,
    public fechaNotificacionEvaluador?: Date | null,
    public fechaEnvioComentarios?: Date | null,
    public fechaAtencionComentarios?: Date | null,
    public fechaRevisionFinanciera?: Date | null,
    public fechaAtencionAjustesFinancieros?: Date | null,
    public dictamenEvaluacion?: string | null,
    public fechaDictamenEvaluacion?: Date | null,
    public fechaRegistroEvaluacion?: Date | null,
    public dictamenFinal?: string | null,
    public fechaEmisionDictamen?: Date | null,
    public fechaLiberacionActa?: Date | null,
    public fechaEnvioDocumentos?: Date | null,
    public montoTotalSolicitado?: number | null,
    public montoTotalPresentado?: number | null,
    public montoTotalAprueba?: number | null,
    public montoApoyoDesglosado?: number | null,
    public anoFiscalReportado?: number | null,
    public montoAutorizadoAnoFiscal?: number | null,
    public duracionProyecto?: string | null,
    public tipoProyecto?: string | null,
    public numEtapas?: number | null,
    public numAcuerdoAprobacion?: string | null,
    public numSesionCtaAprobacion?: string | null,
    public fechaAcuerdoAprobacion?: Date | null,
    public fechaPublicacionResultados?: Date | null,
    public fechaSancionCar?: Date | null,
    public numAcuerdoCtaCantelacion?: string | null,
    public fechaAcuerdoCtaCancelacion?: Date | null,
    public fechaNotificacionBeneficiarioCan?: Date | null,
    public fechaAsignacionCar?: Date | null,
    public numCar?: string | null,
    public fechaLiberacion?: Date | null,
    public tipoCar?: string | null,
    public fechaVoboAuj?: Date | null,
    public fechaVoboSolicitante?: Date | null,
    public fechaFirmaRepLegal?: Date | null,
    public fechaFirmaRespAdministrativo?: Date | null,
    public fechaFirmaRespTecnico?: Date | null,
    public fechaFrrmaSecAdministrativo?: Date | null,
    public fechaFirmaSecTecnico?: Date | null,
    public fechaInicioProyCar?: Date | null,
    public fechaTerminoProyCar?: Date | null,
    public fechaEnvioNotifFinSt?: Date | null,
    public fechaEnvioNotifFinSa?: Date | null,
    public fechaRecepInfTecnico?: Date | null,
    public fechaEnvioEvalInfTecnico?: Date[] | null,
    public fechaNotificacionInfTecnico?: Date | null,
    public fechaAtencionAjustesInfTecnico?: Date[] | null,
    public fechaRecepDictamenInfTecnico?: Date[] | null,
    public falloInformeTecnico?: string | null,
    public fechaRecepcionInfFinancieroFinal?: Date | null,
    public fechaEnvioNotificacionAjuste?: string | null,
    public fechaAtencionAjustes?: string | null,
    public montoReintegro?: string | null,
    public fechaSolicitudReintegro?: Date | null,
    public fechaReintegro?: Date | null,
    public fechaRecepcionDictamen?: Date | null,
    public falloInformeFinanciero?: string | null,
    public tipoConclusion?: string | null,
    public fechaDictamenAprobatorioInfFinanciero?: Date | null,
    public fechaDictamenAprobatorioInfTecnico?: Date | null,
    public fechaConstanciaConclusion?: Date | null,
    public fechaEnvioAcuse?: Date | null,
    public numSesionCtaConclusion?: string | null,
    public fechaConocimientoEmisionConstancia?: Date | null,
    public numAcuerdoCtaConclusion?: string | null,
    public indicarEsTerminacionAnt?: string | null,
    public fechaRecepcionInfTecnicoAnt?: Date | null,
    public fechaAcuerdoCtaTerminacionAnt?: Date | null,
    public fechaOpinionJuridicaTerminacionAnt?: Date | null,
    public numSesionCtaTerminacion?: string | null,
    public fechaNotificBenefTerminacionAnt?: Date | null,
    public numAcuerdoCtaTerminacionAnt?: string | null,
    public fechaOpinionJuridicaRescision?: Date | null,
    public numSesionCtaRescision?: string | null,
    public fechaAcuerdoCtaRescision?: Date | null,
    public numAcuerdoCtaRescision?: string | null,
    public numCausaRescision?: string | null,
    public fechaNotificacionAcuerdoConclusion?: Date | null,
    public etapa?: string | null,
    public estatus?: string | null,
    public ministraciones?: IMinistracion[] | null,
    public comentarios?: IComentarioPanel[] | null,
    public eventos?: IEvento[] | null
  ) {
    this.fechaEnvioEvalInfTecnico = this.fechaEnvioEvalInfTecnico ? this.fechaEnvioEvalInfTecnico : [];
    this.fechaRecepDictamenInfTecnico = this.fechaRecepDictamenInfTecnico ? this.fechaRecepDictamenInfTecnico : [];
    this.fechaAtencionAjustesInfTecnico = this.fechaAtencionAjustesInfTecnico ? this.fechaAtencionAjustesInfTecnico : [];
    this.ministraciones = this.ministraciones ? this.ministraciones : [];
    this.comentarios = this.comentarios ? this.comentarios : [];
    this.eventos = this.eventos ? this.eventos : [];
  }
}

import { render, staticRenderFns } from "./filter.vue?vue&type=template&id=768cc1f5&"
import script from "./filter.component.ts?vue&type=script&lang=ts&"
export * from "./filter.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/0/gateway/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('768cc1f5')) {
      api.createRecord('768cc1f5', component.options)
    } else {
      api.reload('768cc1f5', component.options)
    }
    module.hot.accept("./filter.vue?vue&type=template&id=768cc1f5&", function () {
      api.rerender('768cc1f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/programa-filter/filter.vue"
export default component.exports
import { Prop, Vue } from 'vue-property-decorator';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import Component from 'vue-class-component';

@Component
export default class DatosIdentificacionComponent extends Vue {
  @Prop({ required: true })
  public value!: Proyecto;
  @Prop({ required: true })
  public direccionesOptions;
  @Prop({ required: true })
  public modalidadesApoyoOptions;
  @Prop({ required: true })
  public direccionesAdjuntasOptions;
  @Prop({ required: true })
  public entidadesFederativasOptions;
  @Prop({ required: true })
  public tiposBeneficiarioOptions;
  @Prop({ required: true })
  public areasConocimientoOptions;
  @Prop({ required: true })
  public ejesOptions;

  get proyecto(): Proyecto {
    return this.value;
  }

  set proyecto(proyecto: Proyecto) {
    this.$emit('input', proyecto);
  }
}

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Constants } from '@/shared/util/constants';
import { Filtro } from '@/shared/model/distribucionMs/filtro.model';

@Component
export default class ProgramaFilterComponent extends Vue {
  @Prop({ required: true })
  public value!: Filtro;

  public grados = Constants.GRADOS;
  public orientaciones = Constants.ORIENTACIONES;
  public metodologias = Constants.METODOLOGIAS;
  public areas = Constants.AREAS;
  public tipoInstituciones = Constants.TIPO_INSTITUCIONES;
  public campos = Constants.CAMPOS;
  public disciplinas = Constants.DISCIPLINAS;
  public grupos = Constants.GRUPOS;
  public entidades = Constants.ENTIDADES;
  public estadoSolicitudes = Constants.ESTADOS_SOLICITUD;

  get filtro(): Filtro {
    return this.value;
  }

  set filtro(newValue: Filtro) {
    this.$emit('input', newValue);
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-1", attrs: { "no-body": "" } },
    [
      _c("b-card-body", [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-nombre" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("distribucionGwApp.distribucionMsCriterio.nombre")
                ),
              },
            },
            [_vm._v("Nombre")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.nombre,
                expression: "filtro.nombre",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "nombre",
              id: "criterio-nombre",
              "data-cy": "nombre",
            },
            domProps: { value: _vm.filtro.nombre },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "nombre", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "criterio-cvu" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("distribucionGwApp.distribucionMsCriterio.cvu")
                ),
              },
            },
            [_vm._v("Cvu")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filtro.cvu,
                expression: "filtro.cvu",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "cvu",
              id: "criterio-cvu",
              "data-cy": "cvu",
            },
            domProps: { value: _vm.filtro.cvu },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.filtro, "cvu", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-estados-solicitudes" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "distribucionGwApp.distribucionMsCriterio.estadoSolicitudes"
                    )
                  ),
                },
              },
              [_vm._v("Estados de la solicitud")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.estadoSolicitudes },
              model: {
                value: _vm.filtro.estadoSolicitudes,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "estadoSolicitudes", $$v)
                },
                expression: "filtro.estadoSolicitudes",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-metodologias" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "distribucionGwApp.distribucionMsCriterio.metodologias"
                    )
                  ),
                },
              },
              [_vm._v("Metodologias")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.metodologias },
              model: {
                value: _vm.filtro.metodologias,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "metodologias", $$v)
                },
                expression: "filtro.metodologias",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-grados", variant: "primary" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.distribucionMsCriterio.grados")
                  ),
                },
              },
              [_vm._v("Grados")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.grados },
              model: {
                value: _vm.filtro.grados,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "grados", $$v)
                },
                expression: "filtro.grados",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-orientaciones" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "distribucionGwApp.distribucionMsCriterio.orientaciones"
                    )
                  ),
                },
              },
              [_vm._v("Orientaciones")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.orientaciones },
              model: {
                value: _vm.filtro.orientaciones,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "orientaciones", $$v)
                },
                expression: "filtro.orientaciones",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-areas" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.distribucionMsCriterio.areas")
                  ),
                },
              },
              [_vm._v("Areas")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.areas },
              model: {
                value: _vm.filtro.areas,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "areas", $$v)
                },
                expression: "filtro.areas",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-campo" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.distribucionMsCriterio.campos")
                  ),
                },
              },
              [_vm._v("Campo")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.campos },
              model: {
                value: _vm.filtro.campos,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "campos", $$v)
                },
                expression: "filtro.campos",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-disciplina" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "distribucionGwApp.distribucionMsCriterio.disciplinas"
                    )
                  ),
                },
              },
              [_vm._v("Disciplinas")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.disciplinas },
              model: {
                value: _vm.filtro.disciplinas,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "disciplinas", $$v)
                },
                expression: "filtro.disciplinas",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-entidades" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("distribucionGwApp.distribucionMsCriterio.entidades")
                  ),
                },
              },
              [_vm._v("Entidades")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.entidades },
              model: {
                value: _vm.filtro.entidades,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "entidades", $$v)
                },
                expression: "filtro.entidades",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "form-control-label",
                attrs: { for: "criterio-tipoInstituciones" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "distribucionGwApp.distribucionMsCriterio.tipoInstituciones"
                    )
                  ),
                },
              },
              [_vm._v("Tipo Instituciones")]
            ),
            _vm._v(" "),
            _c("input-tags", {
              attrs: { options: _vm.tipoInstituciones },
              model: {
                value: _vm.filtro.tipoInstituciones,
                callback: function ($$v) {
                  _vm.$set(_vm.filtro, "tipoInstituciones", $$v)
                },
                expression: "filtro.tipoInstituciones",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputSelectManyComponent extends Vue {
  @Prop({ required: true })
  public value!: [string];

  @Prop({ required: true })
  public options!: [string];

  get tags(): [string] {
    return this.value;
  }

  set tags(newValue: [string]) {
    this.$emit('input', newValue);
  }
}

import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import { mapGetters } from 'vuex';
import AccountService from '@/account/account.service';

@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class CommentsComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  @Prop({ required: true })
  public value!: any;

  public mounted(): void {}

  set comments(comments: any) {
    this.$emit('input', comments);
  }

  get comments(): any {
    return this.value;
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.comments, function (comment, index) {
      return _c(
        "b-card",
        { key: index, attrs: { align: "left" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "1" } },
                [
                  _c("b-avatar", {
                    attrs: {
                      size: "lg",
                      rounded: "sm",
                      variant:
                        comment.usuario == _vm.account.login
                          ? "primary"
                          : "light",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "b-card-body",
                    [
                      _c("b-card-title", { attrs: { "title-tag": "div" } }, [
                        _c("div", { staticClass: "comment-header" }, [
                          _c("h4", [_vm._v(_vm._s(comment.usuario))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [
                              comment.usuario == _vm.account.login
                                ? _c("em", [_c("b-badge", [_vm._v("tú")])], 1)
                                : _vm._e(),
                              _vm._v(" "),
                              _c("em", { staticStyle: { color: "#a0a0a0" } }, [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      _vm._f("timeElapsed")(
                                        comment.fechaMovimientoBitacora
                                      )
                                    ) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("b-card-text", [
                        _vm._v(
                          "\n            " +
                            _vm._s(comment.bitacoraMovimiento) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
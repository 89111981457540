import ECharts from 'vue-echarts';
import { use } from 'echarts/core';

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart } from 'echarts/charts';

import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';

use([CanvasRenderer, BarChart, PieChart, GridComponent, TooltipComponent, TitleComponent, LegendComponent]);

export function initChartsVue(vue) {
  vue.component('v-chart', ECharts);
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-cve-convocatoria-id",
                label: "Clave de la convocatoria",
                "label-for": "input-cve-convocatoria-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-cve-convocatoria-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.cveConvocatoria != null &&
                    _vm.proyecto.cveConvocatoria.length > 0,
                  placeholder: "Clave de convocatoria",
                },
                model: {
                  value: _vm.proyecto.cveConvocatoria,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "cveConvocatoria", $$v)
                  },
                  expression: "proyecto.cveConvocatoria",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-convocatoria-id",
                label: "Nombre de la convocatoria",
                "label-for": "input-nombre-convocatoria-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-nombre-convocatoria-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.nombreConvocatoria != null &&
                    _vm.proyecto.nombreConvocatoria.length > 0,
                  placeholder: "Nombre de convocatoria",
                },
                model: {
                  value: _vm.proyecto.nombreConvocatoria,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "nombreConvocatoria", $$v)
                  },
                  expression: "proyecto.nombreConvocatoria",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-aprobacion-juridica-id",
                label: "Fecha de aprobación jurídica de la convocatoria",
                "label-for": "input-fecha-aprobacion-juridica-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-aprobacion-juridica-id",
                      placeholder:
                        "Fecha de aprobación jurídica de la convocatoria",
                      state: _vm.proyecto.fechaAprobacionJuridica != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaAprobacionJuridica,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaAprobacionJuridica", $$v)
                      },
                      expression: "proyecto.fechaAprobacionJuridica",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-aprobacion-cta-id",
                label: "Fecha de aprobación de la convocatoria por CTA",
                "label-for": "input-fecha-aprobacion-cta-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-aprobacion-cta-id",
                      placeholder:
                        "Fecha de aprobación de la convocatoria por CTA",
                      state: _vm.proyecto.fechaAprobacionCta != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaAprobacionCta,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaAprobacionCta", $$v)
                      },
                      expression: "proyecto.fechaAprobacionCta",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-publicacion-id",
                label: "Fecha de publicación de la convocatoria",
                "label-for": "input-fecha-publicacion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-publicacion-id",
                      placeholder: "Fecha de publicación de la convocatoria",
                      state: _vm.proyecto.fechaPublicacion != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaPublicacion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaPublicacion", $$v)
                      },
                      expression: "proyecto.fechaPublicacion",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-sesion-cta-convocatoria-id",
                label: "Número de sesión de CTA",
                "label-for": "input-num-sesion-cta-convocatoria-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-num-sesion-cta-convocatoria-id",
                  state: _vm.proyecto.numSesionCtaConvocatoria != null,
                  placeholder: "Número de sesión de CTA",
                },
                model: {
                  value: _vm.proyecto.numSesionCtaConvocatoria,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "numSesionCtaConvocatoria", $$v)
                  },
                  expression: "proyecto.numSesionCtaConvocatoria",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-apertura-id",
                label: "Fecha de apertura recepción de solicitudes",
                "label-for": "input-fecha-apertura-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-apertura-id",
                      placeholder: "Fecha de apertura recepción de solicitudes",
                      state: _vm.proyecto.fechaApertura != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaApertura,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaApertura", $$v)
                      },
                      expression: "proyecto.fechaApertura",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-cierre-convocatoria-id",
                label: "Fecha de cierre de recepción de solicitudes",
                "label-for": "input-fecha-cierre-convocatoria-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-cierre-convocatoria-id",
                      placeholder:
                        "Fecha de cierre de recepción de solicitudes",
                      state: _vm.proyecto.fechaCierreConvocatoria != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaCierreConvocatoria,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaCierreConvocatoria", $$v)
                      },
                      expression: "proyecto.fechaCierreConvocatoria",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-cve-fondo-origen-id",
                label: "Clave fondo origen",
                "label-for": "input-cve-fondo-origen-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-cve-fondo-origen-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.cveFondoOrigen != null &&
                    _vm.proyecto.cveFondoOrigen.length > 0,
                  placeholder: "Clave del fondo de origen de los proyectos",
                },
                model: {
                  value: _vm.proyecto.cveFondoOrigen,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "cveFondoOrigen", $$v)
                  },
                  expression: "proyecto.cveFondoOrigen",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fondo-origen-id",
                label:
                  "Fondo o Programa presupuestario de origen de los proyectos",
                "label-for": "input-fondo-origen-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title: "Descripción del programa presupuestal",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-fondo-origen-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.fondoOrigen != null &&
                        _vm.proyecto.fondoOrigen.length > 0,
                      placeholder:
                        "Fondo o Programa presupuestario de origen de los proyectos",
                    },
                    model: {
                      value: _vm.proyecto.fondoOrigen,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fondoOrigen", $$v)
                      },
                      expression: "proyecto.fondoOrigen",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-partida-presupuestal-id",
                label: "Partida presupuestaria",
                "label-for": "input-partida-presupuestal-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-partida-presupuestal-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.partidaPresupuestal != null &&
                    _vm.proyecto.partidaPresupuestal.length > 0,
                  placeholder: "Partida presupuestaria",
                },
                model: {
                  value: _vm.proyecto.partidaPresupuestal,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "partidaPresupuestal", $$v)
                  },
                  expression: "proyecto.partidaPresupuestal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fondo-convocatoria-id",
                label: "Fondo convocatoria",
                "label-for": "input-fondo-convocatoria-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: { title: "Cuando aplique" },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-fondo-convocatoria-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.fondoConvocatoria != null &&
                        _vm.proyecto.fondoConvocatoria.length > 0,
                      placeholder:
                        "Fondo del que provienen los recursos de la convocatoria",
                    },
                    model: {
                      value: _vm.proyecto.fondoConvocatoria,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fondoConvocatoria", $$v)
                      },
                      expression: "proyecto.fondoConvocatoria",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-modalidad-apoyo-id",
                label: "Modalidad del apoyo",
                "label-for": "input-modalidad-apoyo-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.modalidadesApoyoOptions,
                  state:
                    _vm.proyecto.modalidadApoyo != null &&
                    _vm.proyecto.modalidadApoyo.length > 0,
                  placeholder: "Modalidad del apoyo (usar catálogo)",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.modalidadApoyo,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "modalidadApoyo", $$v)
                  },
                  expression: "proyecto.modalidadApoyo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-objetivo-convocatoria-id",
                label: "Objetivo de la convocatoria",
                "label-for": "input-objetivo-convocatoria-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-objetivo-convocatoria-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.objetivoConvocatoria != null &&
                    _vm.proyecto.objetivoConvocatoria.length > 0,
                  placeholder: "Objetivo de la convocatoria",
                },
                model: {
                  value: _vm.proyecto.objetivoConvocatoria,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "objetivoConvocatoria", $$v)
                  },
                  expression: "proyecto.objetivoConvocatoria",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-num-solicitud-id",
                label: "Número de solicitud",
                "label-for": "input-num-solicitud-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-num-solicitud-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.numSolicitud != null &&
                    _vm.proyecto.numSolicitud.length > 0,
                  placeholder:
                    "El número que se le asigna a la solicitud al ingresar al sistema en su caso",
                },
                model: {
                  value: _vm.proyecto.numSolicitud,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "numSolicitud", $$v)
                  },
                  expression: "proyecto.numSolicitud",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-cve-proyecto-id",
                label: "Clave del proyecto",
                "label-for": "input-cve-proyecto-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-cve-proyecto-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.cveProyecto != null &&
                    _vm.proyecto.cveProyecto.length > 0,
                  placeholder: "Clave del proyecto",
                },
                model: {
                  value: _vm.proyecto.cveProyecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "cveProyecto", $$v)
                  },
                  expression: "proyecto.cveProyecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-titulo-proyecto-id",
                label: "Título del proyecto",
                "label-for": "input-titulo-proyecto-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-titulo-proyecto-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.tituloProyecto != null &&
                    _vm.proyecto.tituloProyecto.length > 0,
                  placeholder: "Título del proyecto",
                },
                model: {
                  value: _vm.proyecto.tituloProyecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "tituloProyecto", $$v)
                  },
                  expression: "proyecto.tituloProyecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-objetivo-programa-donde-incide-id",
                label: "Objetivo del programa en el que incida la propuesta",
                "label-for": "input-objetivo-programa-donde-incide-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-objetivo-programa-donde-incide-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.objetivoProgramaDondeIncide != null &&
                    _vm.proyecto.objetivoProgramaDondeIncide.length > 0,
                  placeholder:
                    "Objetivo del programa de acuerdo al numeral 1.3 de lineamientos",
                },
                model: {
                  value: _vm.proyecto.objetivoProgramaDondeIncide,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "objetivoProgramaDondeIncide", $$v)
                  },
                  expression: "proyecto.objetivoProgramaDondeIncide",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-objetivo-proyecto-id",
                label: "Objetivo del proyecto",
                "label-for": "input-objetivo-proyecto-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-objetivo-proyecto-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.objetivoProyecto != null &&
                    _vm.proyecto.objetivoProyecto.length > 0,
                  placeholder: "Se muestra el objetivo original del proyecto",
                },
                model: {
                  value: _vm.proyecto.objetivoProyecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "objetivoProyecto", $$v)
                  },
                  expression: "proyecto.objetivoProyecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-dir-adjunta-unidad-id",
                label: "Dirección adjunta o unidad",
                "label-for": "input-dir-adjunta-unidad-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.direccionesAdjuntasOptions,
                  state:
                    _vm.proyecto.dirAdjuntaUnidad != null &&
                    _vm.proyecto.dirAdjuntaUnidad.length > 0,
                  placeholder: "Direccion adjunta o unidad",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.dirAdjuntaUnidad,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "dirAdjuntaUnidad", $$v)
                  },
                  expression: "proyecto.dirAdjuntaUnidad",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-dir-area-resp-convocatoria-id",
                label: "Dirección o área responsable de la convocatoria",
                "label-for": "input-dir-area-resp-convocatoria-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.direccionesOptions,
                  state:
                    _vm.proyecto.dirAreaRespConvocatoria != null &&
                    _vm.proyecto.dirAreaRespConvocatoria.length > 0,
                  placeholder:
                    "Dirección o área responsable de la convocatoria",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.dirAreaRespConvocatoria,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "dirAreaRespConvocatoria", $$v)
                  },
                  expression: "proyecto.dirAreaRespConvocatoria",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-beneficiario-id",
                label: "Nombre del Beneficiario",
                "label-for": "input-nombre-beneficiario-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-nombre-beneficiario-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.nombreBeneficiario != null &&
                    _vm.proyecto.nombreBeneficiario.length > 0,
                  placeholder: "Nombre del Beneficiario",
                },
                model: {
                  value: _vm.proyecto.nombreBeneficiario,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "nombreBeneficiario", $$v)
                  },
                  expression: "proyecto.nombreBeneficiario",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-resp-administrativo-id",
                label: "Nombre de Responsable Administrativo",
                "label-for": "input-nombre-resp-administrativo-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-nombre-resp-administrativo-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.nombreRespAdministrativo != null &&
                    _vm.proyecto.nombreRespAdministrativo.length > 0,
                  placeholder: "Nombre de Responsable Administrativo",
                },
                model: {
                  value: _vm.proyecto.nombreRespAdministrativo,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "nombreRespAdministrativo", $$v)
                  },
                  expression: "proyecto.nombreRespAdministrativo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-resp-tecnico-id",
                label: "Nombre de Responsable Técnico",
                "label-for": "input-nombre-resp-tecnico-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-nombre-resp-tecnico-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.nombreRespTecnico != null &&
                    _vm.proyecto.nombreRespTecnico.length > 0,
                  placeholder: "Nombre de Responsable Técnico",
                },
                model: {
                  value: _vm.proyecto.nombreRespTecnico,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "nombreRespTecnico", $$v)
                  },
                  expression: "proyecto.nombreRespTecnico",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-resp-legal-id",
                label: "Nombre de Representante Legal",
                "label-for": "input-nombre-resp-legal-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-nombre-resp-legal-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.nombreRespLegal != null &&
                    _vm.proyecto.nombreRespLegal.length > 0,
                  placeholder: "Nombre de Representante Legal",
                },
                model: {
                  value: _vm.proyecto.nombreRespLegal,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "nombreRespLegal", $$v)
                  },
                  expression: "proyecto.nombreRespLegal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-ent-federativa-beneficiario-id",
                label: "Entidad Federativa de residencia del beneficiario",
                "label-for": "input-ent-federativa-beneficiario-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.entidadesFederativasOptions,
                  state:
                    _vm.proyecto.entFederativaBeneficiario != null &&
                    _vm.proyecto.entFederativaBeneficiario.length > 0,
                  placeholder:
                    "Entidad Federativa de residencia del beneficiario",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.entFederativaBeneficiario,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "entFederativaBeneficiario", $$v)
                  },
                  expression: "proyecto.entFederativaBeneficiario",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-ent-federativa-proyoecto-id",
                label: "Entidad Federativa de desarrollo de proyecto",
                "label-for": "input-ent-federativa-proyoecto-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.entidadesFederativasOptions,
                  state:
                    _vm.proyecto.entFederativaProyoecto != null &&
                    _vm.proyecto.entFederativaProyoecto.length > 0,
                  placeholder: "Entidad Federativa de desarrollo de proyecto",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.entFederativaProyoecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "entFederativaProyoecto", $$v)
                  },
                  expression: "proyecto.entFederativaProyoecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-rfc-id",
                label: "RFC",
                "label-for": "input-rfc-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-rfc-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.rfc != null && _vm.proyecto.rfc.length > 0,
                  placeholder: "RFC",
                },
                model: {
                  value: _vm.proyecto.rfc,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "rfc", $$v)
                  },
                  expression: "proyecto.rfc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-modalidad-id",
                label: "Modalidad",
                "label-for": "input-modalidad-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-modalidad-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.modalidad != null &&
                    _vm.proyecto.modalidad.length > 0,
                  placeholder: "Modalidad",
                },
                model: {
                  value: _vm.proyecto.modalidad,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "modalidad", $$v)
                  },
                  expression: "proyecto.modalidad",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-tipo-beneficiario-id",
                label: "Tipo de beneficiario",
                "label-for": "input-tipo-beneficiario-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.tiposBeneficiarioOptions,
                  state:
                    _vm.proyecto.tipoBeneficiario != null &&
                    _vm.proyecto.tipoBeneficiario.length > 0,
                  placeholder: "Tipo de Beneficiario según Reniecyt",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.tipoBeneficiario,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "tipoBeneficiario", $$v)
                  },
                  expression: "proyecto.tipoBeneficiario",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-area-conocimiento-id",
                label: "Área de conocimiento",
                "label-for": "input-area-conocimiento-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.areasConocimientoOptions,
                  state:
                    _vm.proyecto.areaConocimiento != null &&
                    _vm.proyecto.areaConocimiento.length > 0,
                  placeholder: "Área de conocimiento",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.areaConocimiento,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "areaConocimiento", $$v)
                  },
                  expression: "proyecto.areaConocimiento",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-eje-rector-id",
                label: "Eje rector programático Conahcyt",
                "label-for": "input-eje-rector-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.ejesOptions,
                  state:
                    _vm.proyecto.ejeRector != null &&
                    _vm.proyecto.ejeRector.length > 0,
                  placeholder: "Eje rector programático conahcyt",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.ejeRector,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "ejeRector", $$v)
                  },
                  expression: "proyecto.ejeRector",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-desc-proyecto-id",
                label: "Descripción de proyecto",
                "label-for": "input-desc-proyecto-id",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-desc-proyecto-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.descProyecto != null &&
                    _vm.proyecto.descProyecto.length > 0,
                  placeholder: "Descripción de proyecto",
                },
                model: {
                  value: _vm.proyecto.descProyecto,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "descProyecto", $$v)
                  },
                  expression: "proyecto.descProyecto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-instituciones-participantes-id",
                label: "Instituciones participantes",
                "label-for": "input-instituciones-participantes-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title:
                                  "Capturar las instituciones participantes separadas por comas",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-instituciones-participantes-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.institucionesParticipantes != null &&
                        _vm.proyecto.institucionesParticipantes.length > 0,
                      placeholder: "Instituciones participantes",
                    },
                    model: {
                      value: _vm.proyecto.institucionesParticipantes,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "institucionesParticipantes",
                          $$v
                        )
                      },
                      expression: "proyecto.institucionesParticipantes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-secretaria-tecnica-resp-id",
                label:
                  "Secretaría técnica responsable (Direcciones de área Conahcyt)",
                "label-for": "input-secretaria-tecnica-resp-id",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  options: _vm.direccionesOptions,
                  state:
                    _vm.proyecto.secretariaTecnicaResp != null &&
                    _vm.proyecto.secretariaTecnicaResp.length > 0,
                  placeholder:
                    "Secretaría técnica responsable (Direcciones de área Conahcyt)",
                  size: "sm",
                },
                model: {
                  value: _vm.proyecto.secretariaTecnicaResp,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "secretariaTecnicaResp", $$v)
                  },
                  expression: "proyecto.secretariaTecnicaResp",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-envio-notificacion-id",
                label: "Fecha en que se envió alguna notificación",
                "label-for": "input-fecha-envio-notificacion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-envio-notificacion-id",
                      placeholder: "Fecha en que se envió alguna notificación",
                      state: _vm.proyecto.fechaEnvioNotificacion != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaEnvioNotificacion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaEnvioNotificacion", $$v)
                      },
                      expression: "proyecto.fechaEnvioNotificacion",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-resolucion-id",
                label: "Fecha en que se resolvió",
                "label-for": "input-fecha-resolucion-id",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-resolucion-id",
                      placeholder: "Fecha en que se resolvió",
                      state: _vm.proyecto.fechaResolucion != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaResolucion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaResolucion", $$v)
                      },
                      expression: "proyecto.fechaResolucion",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-nombre-evaluador-id",
                label:
                  "Nombre de la persona que realiza la evaluación técnica a las solicitudes (al menos dos)",
                "label-for": "input-nombre-evaluador-id",
              },
            },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "b-input-group-text",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.leftbottom",
                                  modifiers: { hover: true, leftbottom: true },
                                },
                              ],
                              attrs: {
                                title:
                                  "Capturar los nombres separados por comas",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    attrs: {
                      id: "input-nombre-evaluador-id",
                      autocomplete: "off",
                      state:
                        _vm.proyecto.nombreEvaluador != null &&
                        _vm.proyecto.nombreEvaluador.length > 0,
                      placeholder:
                        "Nombre de la persona que realiza la evaluación técnica a las solicitudes (al menos dos)",
                    },
                    model: {
                      value: _vm.proyecto.nombreEvaluador,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "nombreEvaluador", $$v)
                      },
                      expression: "proyecto.nombreEvaluador",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-notificacion-evaluador-id",
                label: "Fecha en que se notifica a la persona evaluadora",
                "label-for": "input-fecha-notificacion-evaluador-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-notificacion-evaluador-id",
                      placeholder:
                        "Fecha en que se notifica a la persona evaluadora",
                      state: _vm.proyecto.fechaNotificacionEvaluador != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaNotificacionEvaluador,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "fechaNotificacionEvaluador",
                          $$v
                        )
                      },
                      expression: "proyecto.fechaNotificacionEvaluador",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-envio-comentarios-id",
                label: "Fecha de envío de comentarios a solicitante",
                "label-for": "input-fecha-envio-comentarios-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-envio-comentarios-id",
                      placeholder:
                        "Fecha de envío de comentarios a solicitante",
                      state: _vm.proyecto.fechaEnvioComentarios != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaEnvioComentarios,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaEnvioComentarios", $$v)
                      },
                      expression: "proyecto.fechaEnvioComentarios",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "4" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-atencion-comentarios-id",
                label: "Fecha de atención de comentarios",
                "label-for": "input-fecha-atencion-comentarios-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-atencion-comentarios-id",
                      placeholder: "Fecha de atención de comentarios",
                      state: _vm.proyecto.fechaAtencionComentarios != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaAtencionComentarios,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaAtencionComentarios", $$v)
                      },
                      expression: "proyecto.fechaAtencionComentarios",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-revision-financiera-id",
                label: "Fecha de revisión financiera",
                "label-for": "input-fecha-revision-financiera-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-revision-financiera-id",
                      placeholder: "Fecha de revisión financiera",
                      state: _vm.proyecto.fechaRevisionFinanciera != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaRevisionFinanciera,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaRevisionFinanciera", $$v)
                      },
                      expression: "proyecto.fechaRevisionFinanciera",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-atencion-ajustes-financieros-id",
                label: "Fecha de atención de ajustes financieros",
                "label-for": "input-fecha-atencion-ajustes-financieros-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-atencion-ajustes-financieros-id",
                      placeholder: "Fecha de atención de ajustes financieros",
                      state:
                        _vm.proyecto.fechaAtencionAjustesFinancieros != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaAtencionAjustesFinancieros,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.proyecto,
                          "fechaAtencionAjustesFinancieros",
                          $$v
                        )
                      },
                      expression: "proyecto.fechaAtencionAjustesFinancieros",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-dictamen-evaluacion-id",
                label: "Dictamen de la evaluación",
                "label-for": "input-dictamen-evaluacion-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaDicatamenAdjuntarDocumento"
                ),
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "input-dictamen-evaluacion-id",
                  autocomplete: "off",
                  state:
                    _vm.proyecto.dictamenEvaluacion != null &&
                    _vm.proyecto.dictamenEvaluacion.length > 0,
                  placeholder: "Dictamen de la evaluación",
                },
                model: {
                  value: _vm.proyecto.dictamenEvaluacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.proyecto, "dictamenEvaluacion", $$v)
                  },
                  expression: "proyecto.dictamenEvaluacion",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-dictamen-evaluacion-id",
                label: "Fecha del dictamen de evaluación",
                "label-for": "input-fecha-dictamen-evaluacion-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaDicatamenAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "b-input-group-text",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.leftbottom",
                                      modifiers: {
                                        hover: true,
                                        leftbottom: true,
                                      },
                                    },
                                  ],
                                  attrs: { title: "Fecha de dictamen" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "info-circle",
                                      variant: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c("b-form-datepicker", {
                        attrs: {
                          "value-as-date": "",
                          id: "input-fecha-dictamen-evaluacion-id",
                          placeholder: "Fecha del dictamen de evaluación",
                          state: _vm.proyecto.fechaDictamenEvaluacion != null,
                          locale: "es",
                        },
                        model: {
                          value: _vm.proyecto.fechaDictamenEvaluacion,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proyecto,
                              "fechaDictamenEvaluacion",
                              $$v
                            )
                          },
                          expression: "proyecto.fechaDictamenEvaluacion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "form-group-fecha-registro-evaluacion-id",
                label: "Fecha en que se registra la evaluación",
                "label-for": "input-fecha-registro-evaluacion-id",
                description: _vm.$t(
                  "distribucionGwApp.proyectosMsProyecto.fechaDicatamenAdjuntarDocumento"
                ),
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-datepicker", {
                    attrs: {
                      "value-as-date": "",
                      id: "input-fecha-registro-evaluacion-id",
                      placeholder: "Fecha en que se registra la evaluación",
                      state: _vm.proyecto.fechaRegistroEvaluacion != null,
                      locale: "es",
                    },
                    model: {
                      value: _vm.proyecto.fechaRegistroEvaluacion,
                      callback: function ($$v) {
                        _vm.$set(_vm.proyecto, "fechaRegistroEvaluacion", $$v)
                      },
                      expression: "proyecto.fechaRegistroEvaluacion",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
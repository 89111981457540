// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import * as chartsVueConfig from './shared/config/config-charts-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import TareaService from './entities/distribucionMs/tarea/tarea.service';
import ProgramaFilterComponent from './components/programa-filter/filter.vue';
import ProyectoFilterComponent from './components/proyecto-filter/filter.vue';
import ProyectoEditComponent from './components/proyecto-edit/proyecto-edit.vue';
import CommentsComponent from './components/comments/comments.vue';
import ListEditComponent from './components/list-edit/list-edit.vue';
import MinistracionComponent from './components/ministraciones/ministraciones.vue';
import CargaManualDocumentoComponent from './components/carga-manual-documento/carga-manual.vue';
import EventoComponent from './components/eventos/evento.vue';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';

import GatewayService from '@/admin/gateway/gateway.service';

import UserOAuth2Service from '@/entities/user/user.oauth2.service';
import TagsComponent from './core/tags/tags.vue';
import InputTagsComponent from './core/input-tags/input-tags.vue';
import InputSelectManyComponent from './core/input-select-many/input-select-many.vue';
import draggable from 'vuedraggable';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
chartsVueConfig.initChartsVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('tags', TagsComponent);
Vue.component('input-tags', InputTagsComponent);
Vue.component('input-select-many', InputSelectManyComponent);
Vue.component('draggable', draggable);
Vue.component('programa-filter', ProgramaFilterComponent);
Vue.component('proyecto-filter', ProyectoFilterComponent);
Vue.component('proyecto-edit', ProyectoEditComponent);
Vue.component('comments', CommentsComponent);
Vue.component('list-edit', ListEditComponent);
Vue.component('ministraciones', MinistracionComponent);
Vue.component('carga-manual-documento', CargaManualDocumentoComponent);
Vue.component('eventos', EventoComponent);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router);

/* tslint:disable */
axios
  .get('sentry/config')
  .then(res => {
    if (res.data && res.data.dsn) {
      let environment = 'local';
      let tracesSampleRate: 0.25;

      if (res.data.environment) {
        environment = res.data['environment'];
      }

      if (res.data.tracesSampleRate) {
        tracesSampleRate = res.data['tracesSampleRate'];
      }

      try {
        Sentry.init({
          Vue,
          dsn: res.data['dsn'],
          beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
              let feedBackConfig;

              if (store.getters.account && store.getters.account.firstName) {
                const username = store.getters.account.lastName
                  ? `${store.getters.account.firstName} ${store.getters.account.lastName}`
                  : store.getters.account.firstName;

                feedBackConfig = { eventId: event.event_id, user: { name: username, email: store.getters.account.email } };
              } else {
                feedBackConfig = { eventId: event.event_id };
              }

              Sentry.showReportDialog(feedBackConfig);
            }
            return event;
          },
          integrations: [
            new Integrations.BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
              tracingOrigins: ['localhost', '172.26.13.59', 'qa.cienciadedatos.conacyt.mx', /^\//],
            }),
          ],
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: tracesSampleRate,
          environment: environment,
          release: VERSION,
        });
      } catch (e) {
        console.error('Error al activar la configuración de sentry');
        console.error(e);
      }
    } else {
      console.log('Sentry sin activar');
    }
  })
  .catch(err => {
    console.error('Error al realizar la configuración de sentry');
    console.error(err);
  });
/* tslint:enable */

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,

    gatewayService: () => new GatewayService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    userOAuth2Service: () => new UserOAuth2Service(),
    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    tareaService: () => new TareaService(),
  },
  i18n,
  store,
});

import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { Proyecto } from '@/shared/model/proyectosMs/proyecto.model';
import { mapGetters } from 'vuex';
import AccountService from '@/account/account.service';
import dayjs from 'dayjs';
import { DATE_TIME_LONG_FORMAT } from '@/shared/date/filters';

@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class ListEditComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  @Prop({ required: true })
  public value!: any;

  @Prop({ required: true })
  public dataType!: string;

  @Prop({ required: true })
  public placeholder!: string;

  @Prop({ required: true })
  public title!: string;

  @Prop({ required: false, default: false })
  public tooltip!: boolean;

  @Prop({ required: false })
  public titleTooltip!: string;

  @Prop({ required: false })
  public description!: string;

  public mounted(): void {
    this.init();
  }

  public newValue = '';

  public init(): void {}

  public rowUpdate: any = {};

  set list(list: any) {
    this.$emit('input', list);
  }

  get list(): any {
    return this.value;
  }

  public agregar() {
    let value = null;
    if (this.dataType === 'number') {
      value = parseFloat(this.newValue);
    } else if (this.dataType === 'Date') {
      value = this.newValue;
    } else {
      value = this.newValue;
    }
    this.list.unshift(value);
    this.newValue = '';
  }

  public eliminar(item, index) {
    this.list.splice(index, 1);
  }

  public convertDateTimeFromServer(date: Date): string {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).format(DATE_TIME_LONG_FORMAT);
    }
    return null;
  }

  public updateInstantField(field, event) {
    if (event.target.value) {
      this[field] = dayjs(event.target.value, DATE_TIME_LONG_FORMAT);
    } else {
      this[field] = null;
    }
  }
}

import { Filtro } from '@/shared/model/distribucionMs/filtro.model';
import { ProyectoFiltro } from '@/shared/model/proyectosMs/proyecto-filtro.model';
import { Module } from 'vuex';

export const filtroStore: Module<any, any> = {
  state: {
    currentFiltro: new Filtro(),
    isFiltering: false,
    currentProyectoFiltro: new ProyectoFiltro(),
    isProyectoFiltering: false,
    totalItemProyectoFiltering: 0,
  },
  getters: {
    currentFiltro: state => state.currentFiltro,
    isFiltering: state => state.isFiltering,
    currentProyectoFiltro: state => state.currentProyectoFiltro,
    isProyectoFiltering: state => state.isProyectoFiltering,
    totalItemProyectoFiltering: state => state.totalItemProyectoFiltering,
  },
  mutations: {
    currentFiltro(state, newFiltro) {
      state.currentFiltro = newFiltro;
    },
    filtering(state, isFiltering) {
      state.isFiltering = isFiltering;
    },
    currentProyectoFiltro(state, newFiltro) {
      state.currentProyectoFiltro = newFiltro;
    },
    proyectoFiltering(state, isProyectoFiltering) {
      state.isProyectoFiltering = isProyectoFiltering;
    },
    totalItemProyectoFiltering(state, totalItemProyectoFiltering) {
      state.totalItemProyectoFiltering = totalItemProyectoFiltering;
    },
  },
  actions: {
    applyFilter(context, filtro) {
      context.commit('currentFiltro', filtro);
      context.commit('filtering', true);
    },
    removeFilter(context) {
      context.commit('currentFiltro', new Filtro());
      context.commit('filtering', false);
    },
    applyProyectoFilter(context, filtro) {
      context.commit('currentProyectoFiltro', filtro);
      context.commit('proyectoFiltering', true);
    },
    removeProyectoFilter(context) {
      context.commit('currentProyectoFiltro', new ProyectoFiltro());
      context.commit('proyectoFiltering', false);
    },
    applyCountProyectos(context, records) {
      context.commit('totalItemProyectoFiltering', records);
    },
  },
};

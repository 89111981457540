import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputTagsComponent extends Vue {
  @Prop({ required: true })
  public value!: [string];

  @Prop({ required: true })
  public options!: [string];

  get tags(): [string] {
    return this.value;
  }

  set tags(newValue: [string]) {
    this.$emit('input', newValue);
  }

  get availableOptions(): any {
    if (this.value) {
      return this.options.filter(opt => this.value.indexOf(opt) === -1);
    } else {
      return [];
    }
  }
}
